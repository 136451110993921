function BoatLocalIcon({ props, className, color = "white" }) {
  return (
    <svg
      width="52"
      height="56"
      viewBox="0 0 52 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      className={className}
    >
      <path
        d="M26.1597 7.16494V1"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M50.8195 35.9347C47.0692 49.0352 29.7046 53.6075 26.6221 54.3268C26.3187 54.404 26.0008 54.404 25.6974 54.3268C22.6149 53.6075 5.25034 49.0352 1.5 35.9347V27.1497C1.49996 26.717 1.63652 26.2953 1.89021 25.9447C2.14389 25.5942 2.50176 25.3327 2.9128 25.1974L25.5176 17.6454C25.936 17.5166 26.3835 17.5166 26.8019 17.6454L49.4067 25.1974C49.8178 25.3327 50.1756 25.5942 50.4293 25.9447C50.683 26.2953 50.8195 26.717 50.8195 27.1497V35.9347Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M26.6276 47.6652C24.1888 47.1036 10.4501 43.5341 7.48291 33.3066"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.48291 9.98242V23.1357L26.0293 16.5548L44.5757 23.1357V9.98242C44.5757 8.32557 43.2326 6.98242 41.5757 6.98242H10.4829C8.82606 6.98242 7.48291 8.32557 7.48291 9.98242Z"
        fill="#59AAD6"
        stroke={color}
        strokeWidth="2"
      />
      <path
        d="M26.1597 37.99V17.543"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M31.4136 36.8963L31.4136 28.5205"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.269 17.7513V11.7686"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M49.3618 20.1449V9.37598"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default BoatLocalIcon;
