import { Link } from "react-router-dom";
import Slider from "react-slick";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { getBlogs } from "../../api";

const AchievementContent = () => {
  const data = [
    {
      title: "ROUTE DES PÊCHES",
      description:
        "Construction d'environ 32,77 kilomètres d'infrastructures routières, 03 passages à niveau, des parkings, des fossés végétalisés et des pistes cyclables par l’Etat du Bénin.",
      image: "/new/achievement-1.png",
      to: "/culture/actualite",
    },
    {
      title: "MARCHÉ DE GROS",
      description:
        "Construction d'environ 32,77 kilomètres d'infrastructures routières, 03 passages à niveau, des parkings, des fossés végétalisés et des pistes cyclables par l’Etat du Bénin.",
      image: "/new/achievement-2.png",
      to: "/culture/actualite",
    },
    {
      title: "PAPC BÉNIN",
      description:
        "Construction d'environ 32,77 kilomètres d'infrastructures routières, 03 passages à niveau, des parkings, des fossés végétalisés et des pistes cyclables par l’Etat du Bénin.",
      image: "/new/achievement-3.png",
      to: "/culture/actualite",
    },
  ];

  const [allBlogs, setAllBlogs] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  const {
    isPending: isPendingBlogs,
    error: errorBlogs,
    data: dataBlogs,
    isSuccess: isSuccessBlogs,
  } = useQuery({
    enabled: true,
    queryKey: ["getLatestBlogs", page],
    queryFn: () =>
      getBlogs({
        page,
        limit: 3,
        excludeCategories: ["savoir-faire", "actualites", "pays"],
      }),
  });

  const settings = {
    className:
      "h-[370vh] md:h-[70vh] w-[calc(100vw-2*theme('spacing.10'))] md:w-[calc(100vw-2*theme('spacing.40'))] bg-transparent",
    dots: false,
    useTransform: true,
    infinite: true,
    currentSlide: 0,
    initialSlide: 0,
    autoplay: true,
    autoplaySpeed: 3000,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipeToSlide: true,
    adaptiveHeight: true,
    arrows: false,
    beforeChange: useCallback(
      (current, next) => {
        if (allBlogs.find((item) => item.page === next + 1) === undefined) {
          setPage(next + 1);
        }
      },
      [allBlogs],
    ),
  };

  const generateSlidesArray = useMemo(() => {
    return [...Array(totalPages).keys()].map((i) => i + 1);
  }, [totalPages]);

  const getBlogsOnPage = useCallback(
    (page) => {
      const pageBlogs = allBlogs.find((item) => item.page === page);
      return pageBlogs !== undefined ? pageBlogs.blogs : [];
    },
    [allBlogs],
  );

  useEffect(() => {
    if (isSuccessBlogs) {
      setTotalPages(dataBlogs.totalPages);

      if (allBlogs.find((item) => item.page === page) === undefined) {
        if (dataBlogs.page === page)
          setAllBlogs((prevState) => {
            return [...prevState, { page, blogs: dataBlogs.blogs }];
          });
      }
    }
  }, [allBlogs, dataBlogs, isSuccessBlogs, page]);

  return (
    <section className="h-[210vh] md:h-[90vh] w-full flex flex-col relative font-urbanist">
      <div className="h-2/3 bg-ghostwhite-200"></div>
      <div className="h-1/3 bg-white"></div>

      <div className="flex flex-col absolute justify-center items-center w-full">
        <h3 className="m-0 text-center text-3xl md:text-4xl mt-16 mb-20">
          Nos accomplissements
        </h3>
        <div className="px-10 md:px-40 slider-container bg-transparent">
          <Slider {...settings}>
            {generateSlidesArray.map((item) => (
              <div
                key={`latest-blog-home-slide-${item}`}
                className="bg-transparent p-10"
              >
                {isPendingBlogs && item === page ? (
                  <div className="bg-red-800 h-[50vh]" />
                ) : (
                  <div className="flex flex-col md:flex-row justify-center items-center gap-16">
                    {getBlogsOnPage(item).map((blog, index) => (
                      <div
                        key={`latest-blogs-home-${item}-${index}`}
                        className="h-[50vh] w-[70vw] md:w-[23vw] shadow-xl bg-white flex flex-col rounded-md hover:bg-gray-50"
                      >
                        <div
                          className="h-[45%] rounded-t-md bg-cover"
                          style={{
                            backgroundImage: `url("${blog.image}")`,
                          }}
                        ></div>
                        <div className="h-[55%] rounded-b-xl">
                          <div className="flex flex-col  px-8 justify-around h-full">
                            <h3 className="m-0 uppercase line-clamp-1">
                              {blog.title}
                            </h3>
                            <span className=" text-xs text-gray-cloud leading-relaxed line-clamp-4">
                              {blog.description}
                            </span>
                            <Link
                              to={`/blog/${blog.slug}`}
                              className="no-underline text-blue-700 text-sm"
                            >
                              Voir plus
                            </Link>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            ))}
          </Slider>
        </div>
        {/*<div className="flex flex-col md:flex-row justify-center items-center gap-16">
          {data.map((item, index) => (
            <div
              key={index}
              className="h-[50vh] w-[70vw] md:w-[23vw] shadow-xl bg-white flex flex-col rounded-md hover:bg-gray-50"
            >
              <div
                className="h-[45%] rounded-t-md bg-cover"
                style={{
                  backgroundImage: `url("${item.image}")`,
                }}
              ></div>
              <div className="h-[55%] rounded-b-xl">
                <div className="flex flex-col  px-8 justify-around h-full">
                  <h3 className="m-0 uppercase">{item.title}</h3>
                  <span className=" text-xs text-gray-cloud leading-relaxed">
                    {item.description}
                  </span>
                  <Link
                    to={item.to}
                    className="no-underline text-blue-700 text-sm"
                  >
                    Voir plus
                  </Link>
                </div>
              </div>
            </div>
          ))}
        </div>*/}
      </div>
    </section>
  );

  /*  return (
      <section className="self-stretch flex flex-row items-start justify-center box-border max-w-full shrink-0 text-left  text-main-text font-urbanist">
        <div className="w-[1196px] rounded-lg grid flex-row items-start justify-start gap-[43px] max-w-full grid-cols-[repeat(3,_minmax(277px,_1fr))] z-[1] lg:justify-center lg:grid-cols-[repeat(2,_minmax(277px,_481px))] mq825:gap-[21px] mq825:grid-cols-[minmax(277px,_1fr)]">
          <PrivateEvents
            privateEventsFrame="/rectangle1@2x.png"
            transportDeMarchandises="Transport de marchandises"
            propGap="34px"
            propWidth="185px"
            onPrivateEventsContainerClick={onPrivateEventsContainerClick}
          />
          <PrivateEvents
            privateEventsFrame="/rectangle-1@2x.png"
            transportDeMarchandises="Réaménagement du tonçons Cotonou-Calavi"
            propGap="34px"
            propWidth="283px"
            onPrivateEventsContainerClick={onPrivateEventsContainer1Click}
          />
          <PrivateEvents
            privateEventsFrame="/rectangle-2@2x.png"
            transportDeMarchandises="Opérations de transit local"
            propGap="13px"
            propWidth="185px"
            onPrivateEventsContainerClick={onPrivateEventsContainer2Click}
          />
        </div>
      </section>
    );*/
};

export default AchievementContent;
