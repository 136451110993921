import { useNavigate } from "react-router-dom";
import clsx from "clsx";

const PartnersComponent = () => {
  const navigate = useNavigate();

  const data1 = [
    {
      icon: "/new/partner-1.png",
      iconSize: "h-24 w-24 md:h-36 md:w-36",
      position: "",
      size: "w-full h-full",
    },
  ];

  const data2 = [
    {
      icon: "/new/partner-2.png",
      iconSize: "h-16 w-16 md:h-20 md:w-20",
      position: "",
      size: "w-full h-full",
    },
    {
      icon: "/new/partner-3.png",
      iconSize: "h-16 w-16 md:h-20 md:w-20",
      position: "col-start-1",
      size: "w-full h-full",
    },
    {
      icon: "/new/partner-4.png",
      iconSize: "h-16 w-16 md:h-20 md:w-20",
      position: "",
      size: "w-full h-full",
    },
  ];

  const data3 = [
    {
      icon: "/new/partner-5.png",
      iconSize: "h-12 w-12 md:h-16 md:w-16",
      position: "col-start-2",
      size: "w-full h-1/2",
    },
  ];

  const data4 = [
    {
      icon: "/new/partner-6.png",
      iconSize: "h-16 w-12 md:h-20 md:w-16",
      position: "",
      size: "w-full h-full",
    },
    {
      icon: "/new/partner-7.png",
      iconSize: "h-12 w-16 md:h-16 md:w-20",
      position: "",
      size: "w-full h-full",
    },
    {
      icon: "/new/partner-8.png",
      iconSize: "h-16 w-20 md:h-20 md:w-24",
      position: "",
      scale: "scale-75",
      size: "w-full h-full",
    },
  ];

  return (
    <section className="w-full max-w-full bg-seashell-200 font-inter">
      <div className="flex flex-col md:flex-row p-7 md:p-28 items-center">
        <div className="w-full md:w-3/5 flex flex-col ">
          <h1 className="m-0 text-4xl font-semibold md:max-w-xs">
            Nos partenaires du quotidien
          </h1>
          <div className="mt-8 flex flex-col max-w-md">
            <p className="text-base">
              {
                "Nos services reposent sur une expertise pertinente et des partenariats solides qui accélèrent et facilitent vos opérations."
              }
            </p>
          </div>
          <button
            onClick={() => {
              navigate("/partenaires");
            }}
            className="mt-10 w-fit text-base text-main-color bg-white py-3 px-8 rounded-md cursor-pointer"
          >
            Tout voir
          </button>
        </div>
        <div className="mt-16 md:mt-0 w-full md:w-2/5 flex flex-col self-center">
          <div className="h-[40vh] w-[40vh] md:h-[50vh] md:w-[50vh]">
            <div className="w-full h-full grid grid-cols-2 gap-2 justify-items-center">
              {data1.map((dt1, index) => (
                <div
                  key={`dt1-${index}`}
                  className={clsx(
                    dt1.size,
                    dt1.position,
                    "bg-white rounded-xl flex justify-center",
                  )}
                >
                  <img
                    className={clsx(dt1.iconSize, "my-auto")}
                    loading="eager"
                    alt=""
                    src={dt1.icon}
                  />
                </div>
              ))}

              <div className="w-full h-full grid grid-cols-2 gap-2 ">
                {data2.map((dt2, index) => (
                  <div
                    key={`dt2-${index}`}
                    className={clsx(
                      dt2.size,
                      dt2.position,
                      "bg-white rounded-xl flex  justify-center",
                    )}
                  >
                    <img
                      className={clsx(dt2.iconSize, "my-auto")}
                      loading="eager"
                      alt=""
                      src={dt2.icon}
                    />
                  </div>
                ))}
              </div>
              <div className="w-full h-full grid grid-cols-2 gap-2 ">
                {data3.map((dt3, index) => (
                  <div
                    key={`dt3-${index}`}
                    className={clsx(
                      dt3.size,
                      dt3.position,
                      "bg-white rounded-xl flex  justify-center",
                    )}
                  >
                    <img
                      className={clsx(dt3.iconSize, "my-auto")}
                      loading="eager"
                      alt=""
                      src={dt3.icon}
                    />
                  </div>
                ))}
              </div>
              <div className="w-full  grid grid-cols-2 gap-2">
                {data4.map((dt4, index) => (
                  <div
                    key={`dt3-${index}`}
                    className={clsx(
                      dt4.size,
                      dt4.position,
                      "bg-white rounded-xl flex  justify-center",
                    )}
                  >
                    <img
                      className={clsx(dt4.iconSize, dt4.scale, "my-auto")}
                      loading="eager"
                      alt=""
                      src={dt4.icon}
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PartnersComponent;
