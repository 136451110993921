import React, { useState } from "react";
import clsx from "clsx";

const VisionComponent = () => {
  const visionImages = [
    "/new/vision-1.png",
    "/new/vision-2.png",
    "/new/vision-3.png",
  ];

  // Vision Images animation
  const [hoveredImageIndex, setHoveredImageIndex] = useState(1); // Initialize to 1 (no image hovered)

  const handleImageMouseEnter = (index) => {
    setHoveredImageIndex(index + 1); // Add 1 to match the desired values (1, 2, 3)
  };

  const handleImageMouseLeave = () => {
    setHoveredImageIndex(1); // Reset to 1 when no image is hovered
  };

  return (
    <section className="flex flex-col md:flex-row bg-seashell-200 p-8 md:p-20 items-center md:items-start justify-between">
      <div className=" md:w-[40%] flex flex-col md:pl-20 md:pr-10">
        <h3 className="text-4xl">Notre vision</h3>
        <p className="mt-16 md:mt-32">
          {
            "STRACA BENIN se positionne comme une entreprise responsable et engagée, spécialisée dans le transport et la distribution garantissant à ses partenaires-clients, des solutions innovante et performante dans un environnement de qualité et de sécurité."
          }
        </p>
      </div>
      <div className="mt-10 md:mt-0 md:w-[60%] flex flex-col">
        <div className=" w-full  flex flex-row justify-start items-center gap-3 duration-5000">
          {visionImages.map((image, index) => (
            <img
              key={`image-vision-${index}`}
              src={image}
              alt={`first-${index}`}
              className={`${
                hoveredImageIndex === index + 1 ? "w-[50%]" : "w-[20%]"
              } object-cover h-[45vh] rounded-lg transition-all duration-5000 ease-in-out shadow-xl`}
              onMouseEnter={() => handleImageMouseEnter(index)}
              onMouseLeave={handleImageMouseLeave}
            />
          ))}
        </div>

        <div className="mt-10 w-full  flex flex-row justify-start items-center gap-3 duration-5000">
          {visionImages.map((image, index) => (
            <div
              key={`div-vision-${index}`}
              className={clsx(
                hoveredImageIndex === index + 1 ? "w-9" : "w-4",
                "h-2 w-10 bg-main-orange rounded-3xl transition-all duration-5000 ease-in-out",
              )}
            ></div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default VisionComponent;
