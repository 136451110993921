import React from "react";

export const DotsIndicator = ({ slides, currentIndex }) => {
  return (
    <div className="flex items-center justify-center gap-2">
      {slides.map((_, i) => (
        <div
          key={i}
          className={`
              duration-1000 w-3 h-3 bg-white rounded-full
              ${currentIndex !== i && "bg-opacity-50"}
            `}
        />
      ))}
    </div>
  );
};
