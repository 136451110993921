import React, { createContext, useContext, useState } from "react";

const SelectedJobContext = createContext();

export const useSelectedJobs = () => useContext(SelectedJobContext);

export const SelectedJobsProvider = ({ children }) => {
  const [selectedJobs, setSelectedJobs] = useState([]);

  const toggleJobSelection = (jobId) => {
    setSelectedJobs((prevSelectedJobs) => {
      if (prevSelectedJobs.includes(jobId)) {
        return prevSelectedJobs.filter((id) => id !== jobId);
      } else {
        return [...prevSelectedJobs, jobId];
      }
    });
  };

  return (
    <SelectedJobContext.Provider value={{ selectedJobs, toggleJobSelection }}>
      {children}
    </SelectedJobContext.Provider>
  );
};
