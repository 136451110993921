import { Header } from "./Header";

import React from "react";

const HeroSection = ({ url = "/component-28@2x.png", children }) => {
  return (
    <section className=" w-full  flex flex-col relative">
      <div
        className="relative h-full w-full bg-cover "
        style={{
          backgroundImage: `url(${url})`,
        }}
      >
        <div className="flex flex-col px-5 md:px-24 py-5 md:py-10 ">
          <Header />

          {children}
        </div>
      </div>
    </section>
  );
};

export default HeroSection;
