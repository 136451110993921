export default function BoxesLocalIcon({ props, className, color = "white" }) {
  return (
    <svg
      width="43"
      height="39"
      viewBox="0 0 43 39"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      className={className}
    >
      <path
        d="M2 32.3709V21.328L11.661 17.1885L21.322 21.3311V32.3709L11.661 36.5074L2 32.3709Z"
        stroke={color}
        strokeWidth="3.05052"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2 21.3065L11.661 25.4461L21.322 21.3065M11.661 6.22168L21.3189 10.3643L30.9799 6.22473"
        stroke={color}
        strokeWidth="3.05052"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.3164 21.3066L30.9774 25.4462L40.6384 21.3066"
        stroke={color}
        strokeWidth="3.05052"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.6641 25.4524V36.5105M30.9891 25.4524V36.5105M21.322 10.3645V21.4196M11.6702 17.1824V6.13956L21.3281 2L30.9891 6.13956V17.1824L21.3281 21.322L11.6702 17.1824ZM21.322 32.371V21.3281L30.983 17.1885L40.644 21.3311V32.371L30.983 36.5075L21.322 32.371Z"
        stroke={color}
        strokeWidth="3.05052"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
