const ClientsOrders = () => {
  return (
    <div className="bg-white w-full min-h-screen flex flex-col text-black">
      <span>Test</span>
      <span>OK</span>
    </div>
    /*<div className="w-full relative bg-white overflow-hidden flex flex-row items-start justify-start gap-[31px] tracking-[normal] text-left text-7xl text-black font-poppins mq750:gap-[15px] mq1275:flex-wrap">
      <div className="h-[1198px] w-[329px] bg-ghostwhite-100 flex flex-col items-center justify-start pt-9 pb-[106px] pr-[51px] pl-7 box-border gap-[36px] max-w-full mq750:pt-5 mq750:pb-[45px] mq750:box-border mq450:gap-[18px] mq450:pr-5 mq450:box-border mq1100:pt-[23px] mq1100:pb-[69px] mq1100:box-border">
        <div className="w-[329px] h-[1198px] relative bg-ghostwhite-100 hidden max-w-full" />
        <div className="h-[67px] flex flex-row items-start justify-start py-0 pr-5 pl-[38px] box-border">
          <h2 className="m-0 h-[39px] relative text-inherit tracking-[0.01em] font-semibold font-inherit inline-block z-[1] mq450:text-2xl">
            Espace client
          </h2>
        </div>
        <div className="self-stretch rounded-8xs bg-main-color flex flex-row items-start justify-start py-[11px] px-2.5 gap-[14px] z-[1]">
          <div className="h-[46px] w-[250px] relative rounded-8xs bg-main-color hidden" />
          <img
            className="h-6 w-6 relative overflow-hidden shrink-0 min-h-[24px] z-[1]"
            alt=""
            src="/icon--24--outline--keysquare.svg"
          />
          <input
            className="w-[calc(100%_-_44px)] [border:none] [outline:none] font-medium font-poppins text-sm bg-[transparent] h-[21px] flex-1 relative tracking-[-0.01em] text-white text-left inline-block min-w-[115px] z-[1]"
            placeholder="Historique des commandes"
            type="text"
          />
        </div>
        <div className="self-stretch flex-1 flex flex-col items-start justify-start py-0 pr-[9px] pl-2 gap-[26px] text-sm text-lightslategray-200">
          <div
            role={"presentation"}
            className="flex flex-row items-end justify-start py-0 pr-px pl-0 gap-[13px] cursor-pointer z-[1]"
            onClick={onListMenuContainerClick}
          >
            <img
              className="h-6 w-6 relative overflow-hidden shrink-0 min-h-[24px]"
              loading="eager"
              alt=""
              src="/walletmoney-2.svg"
            />
            <div className="flex flex-row items-center justify-start py-0 pr-px pl-0 gap-[28px]">
              <div className="h-[21px] relative tracking-[-0.01em] font-medium inline-block">
                Sercice commerciale
              </div>
              <img
                className="h-4 w-4 relative overflow-hidden shrink-0"
                loading="eager"
                alt=""
                src="/chevronright-2.svg"
              />
            </div>
          </div>
          <div className="self-stretch flex flex-row items-end justify-start gap-[14px]">
            <img
              className="h-6 w-6 relative overflow-hidden shrink-0 z-[1]"
              loading="eager"
              alt=""
              src="/usersquare-1.svg"
            />
            <div className="flex-1 flex flex-col items-start justify-start">
              <div className="relative tracking-[-0.01em] font-medium z-[1]">
                Paramètre
              </div>
            </div>
            <div className="flex flex-col items-start justify-start pt-0 pb-1 pr-px pl-0">
              <div className="w-[17px] h-[30px] relative">
                <img
                  className="absolute top-[14px] left-[-0.2px] w-4 h-4 overflow-hidden z-[1]"
                  alt=""
                  src="/chevronright-2-1.svg"
                />
                <input
                  className="m-0 absolute top-[0px] left-[1px] w-4 h-4 z-[2]"
                  type="checkbox"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="self-stretch flex flex-row items-start justify-start py-0 px-2.5 text-base text-main-text-color font-inter">
          <div className="flex flex-row items-center justify-start gap-[16px]">
            <img
              className="h-6 w-6 relative overflow-hidden shrink-0 z-[1]"
              loading="eager"
              alt=""
              src="/iconslogout.svg"
            />
            <div className="relative font-medium z-[1]">Déconnexion</div>
          </div>
        </div>
      </div>
      <div className="h-12 w-1 relative bg-gainsboro-100 hidden" />
      <section className="w-[985px] flex flex-col items-start justify-start pt-[87px] px-0 pb-0 box-border max-w-full text-left text-5xl text-black font-poppins mq750:pt-[37px] mq750:box-border mq1275:pt-[57px] mq1275:box-border">
        <div className="self-stretch flex flex-col items-end justify-start gap-[22px] max-w-full">
          <div className="self-stretch flex flex-row items-start justify-between gap-[20px] max-w-full mq750:flex-wrap">
            <div className="w-[209px] flex flex-col items-end justify-end pt-[110px] px-0 pb-0 box-border relative min-w-[209px] mq750:flex-1">
              <h2 className="m-0 h-9 relative text-inherit font-medium font-inherit inline-block mq450:text-lgi">
                Bonjour Client X
              </h2>
              <img
                role={"presentation"}
                className="w-[34px] h-[34px] absolute my-0 mx-[!important] top-[0px] left-[0px] overflow-hidden shrink-0 cursor-pointer"
                loading="eager"
                alt=""
                src="/icroundarrowback.svg"
                onClick={onIcroundArrowBackIconClick}
              />
            </div>
            <div className="w-[550px] rounded-11xl bg-ghostwhite-100 shadow-[0px_10px_60px_rgba(226,_236,_249,_0.5)] flex flex-row items-start justify-start pt-[31px] pb-[33px] pr-7 pl-[50px] box-border gap-[20px] min-w-[550px] max-w-full text-sm text-darkgray-200 mq750:flex-1 mq750:pl-[25px] mq750:box-border mq750:min-w-full mq450:flex-wrap">
              <img
                className="h-[151px] w-[550px] relative rounded-11xl hidden max-w-full"
                alt=""
                src="/rectangle-15.svg"
              />
              <img
                className="h-[84px] w-[84px] relative z-[1]"
                loading="eager"
                alt=""
                src="/group-10.svg"
              />
              <div className="flex-1 flex flex-row items-start justify-start py-0 pr-[15px] pl-0 box-border gap-[10px] min-w-[121px]">
                <div className="flex-1 flex flex-col items-start justify-start gap-[5px]">
                  <div className="h-[21px] relative tracking-[-0.01em] inline-block z-[1]">
                    Commande mensuelle
                  </div>
                  <div className="relative text-13xl tracking-[-0.01em] leading-[100%] font-semibold text-darkslategray-600 z-[1] mq750:text-7xl mq750:leading-[26px] mq450:text-lgi mq450:leading-[19px]">
                    5
                  </div>
                  <div className="flex flex-row items-end justify-start gap-[3px] text-xs text-forestgreen">
                    <img
                      className="h-5 w-5 relative overflow-hidden shrink-0 min-h-[20px] z-[1]"
                      loading="eager"
                      alt=""
                      src="/arrowup-1.svg"
                    />
                    <div className="relative tracking-[-0.01em] z-[1]">
                      <b>16%</b>
                      <span className="text-darkslategray-900"> Ce mois</span>
                    </div>
                  </div>
                </div>
                <div className="h-[88px] w-px relative box-border z-[1] border-r-[1px] border-solid border-whitesmoke-300" />
              </div>
              <div className="h-[87px] flex flex-row items-start justify-start gap-[14px]">
                <div className="flex flex-col items-start justify-start gap-[4px]">
                  <div className="h-[21px] relative tracking-[-0.01em] inline-block z-[1]">
                    Commande annuelle
                  </div>
                  <div className="relative text-13xl tracking-[-0.01em] leading-[100%] font-semibold text-darkslategray-600 z-[1] mq750:text-7xl mq750:leading-[26px] mq450:text-lgi mq450:leading-[19px]">
                    93
                  </div>
                </div>
                <div className="self-stretch w-px relative box-border z-[1] border-r-[1px] border-solid border-whitesmoke-300" />
              </div>
            </div>
          </div>
          <form className="m-0 self-stretch rounded-11xl bg-ghostwhite-100 shadow-[0px_10px_60px_rgba(226,_236,_249,_0.5)] flex flex-col items-center justify-start pt-[31px] px-0 pb-[41px] box-border gap-[8px] max-w-full mq450:pb-5 mq450:box-border mq1100:pt-5 mq1100:pb-[27px] mq1100:box-border">
            <img
              className="self-stretch relative rounded-11xl max-w-full overflow-hidden h-[851px] shrink-0 hidden"
              alt=""
              src="/rectangle-30.svg"
            />
            <div className="self-stretch h-[98px] flex flex-row items-start justify-start py-0 pr-px pl-[38px] box-border max-w-full">
              <div className="h-[79px] flex-1 relative max-w-full mq750:h-auto mq750:min-h-[79]">
                <h3 className="m-0 absolute top-[0.4px] left-[0px] text-3xl tracking-[-0.01em] font-semibold font-poppins text-black text-left inline-block w-[265px] h-[34.6px] z-[1] mq450:text-lg">
                  Toutes vos commandes
                </h3>
                <div className="absolute top-[24px] left-[358px] w-[571px] flex flex-row items-center justify-start gap-[14px] max-w-full mq750:flex-wrap">
                  <div className="flex-1 rounded-3xs flex flex-row items-center justify-center min-w-[285px] max-w-full z-[3]">
                    <div className="flex-1 rounded-3xs bg-white box-border flex flex-row items-center justify-start pt-[15px] px-5 pb-4 gap-[20px] max-w-full border-[1px] border-solid border-neutral-400">
                      <div className="h-6 w-6 flex flex-row items-center justify-center">
                        <img
                          className="h-[19px] w-[19px] relative"
                          alt=""
                          src="/union.svg"
                        />
                      </div>
                      <input
                        className="w-[71px] [border:none] [outline:none] font-inter text-sm bg-[transparent] h-[17px] relative text-neutral-50 text-left inline-block"
                        placeholder="Recherche"
                        type="text"
                      />
                    </div>
                  </div>
                  <div className="w-[118px] rounded bg-white box-border flex flex-row items-center justify-start z-[3] border-[1px] border-solid border-grey-grey-90">
                    <div className="flex-1 flex flex-row items-center justify-start py-3.5 px-4">
                      <div className="w-[57px] relative text-base tracking-[0.01em] leading-[20px] font-roboto text-grey-grey-45 text-left inline-block pr-5">
                        Filtre
                      </div>
                    </div>
                    <img
                      className="h-6 w-6 relative overflow-hidden shrink-0 z-[1] ml-[-24px]"
                      alt=""
                      src="/caret-down.svg"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-row items-start justify-start py-0 pr-[52px] pl-5 box-border min-h-[29px] max-w-full shrink-0 mq1100:pr-[26px] mq1100:box-border">
              <div className="flex flex-row items-start justify-start gap-[66px] max-w-full mq450:gap-[16px] mq1100:flex-wrap mq1100:gap-[33px]">
                <div className="flex flex-col items-start justify-start py-0 pr-[31px] pl-0">
                  <div className="relative text-sm tracking-[-0.01em] font-medium font-poppins text-silver-400 text-left z-[1]">
                    Opérations
                  </div>
                </div>
                <div className="h-[22px] relative text-sm tracking-[-0.01em] font-medium font-poppins text-silver-400 text-left inline-block z-[1]">
                  Détails
                </div>
                <div className="flex flex-row items-start justify-start gap-[27px] max-w-full mq750:flex-wrap">
                  <div className="h-[22px] relative text-sm tracking-[-0.01em] font-medium font-poppins text-silver-400 text-left inline-block z-[1]">
                    Personne à contacter
                  </div>
                  <div className="flex flex-col items-start justify-start py-0 pr-[22px] pl-0">
                    <div className="relative text-sm tracking-[-0.01em] font-medium font-poppins text-silver-400 text-left z-[1]">
                      Début de l’opération
                    </div>
                  </div>
                  <div className="flex flex-col items-start justify-start py-0 pr-[22px] pl-0">
                    <div className="relative text-sm tracking-[-0.01em] font-medium font-poppins text-silver-400 text-left z-[1]">
                      Fin de l’opération
                    </div>
                  </div>
                  <div className="h-[22px] relative text-sm tracking-[-0.01em] font-medium font-poppins text-silver-400 text-center inline-block z-[1]">
                    Status
                  </div>
                </div>
              </div>
            </div>
            <div className="self-stretch h-3 flex flex-row items-start justify-start pt-0 px-0 pb-3 box-border max-w-full">
              <div
                role={"presentation"}
                className="h-px flex-1 relative box-border max-w-full cursor-pointer z-[1] border-t-[1px] border-solid border-whitesmoke-400"
                onClick={onLineClick}
              />
            </div>
            <div className="w-[932px] flex flex-row items-start justify-start py-0 pr-[26px] pl-5 box-border min-h-[44px] max-w-full shrink-0">
              <div
                role={"presentation"}
                className="flex-1 flex flex-row items-start justify-start [row-gap:20px] max-w-full cursor-pointer z-[1] mq750:flex-wrap"
                onClick={onGroupContainerClick}
              >
                <div className="w-[172px] flex flex-col items-start justify-start pt-[5px] px-0 pb-0 box-border">
                  <div className="w-[90px] relative text-sm tracking-[-0.01em] font-medium font-poppins text-darkslategray-900 text-left inline-block">
                    Location 100 MAN
                  </div>
                </div>
                <div className="w-[131px] flex flex-col items-start justify-start pt-[5px] px-0 pb-0 box-border">
                  <div className="w-16 h-[22px] relative text-sm tracking-[-0.01em] font-medium font-poppins text-darkslategray-900 text-center inline-block shrink-0">
                    ...
                  </div>
                </div>
                <div className="h-[41px] w-[156px] flex flex-col items-start justify-start pt-[5px] px-0 pb-0 box-border">
                  <div className="w-[190px] relative text-sm tracking-[-0.01em] font-medium font-poppins text-darkslategray-900 text-left inline-block h-[37px] shrink-0">
                    Bob BOKO bob@straca.com
                  </div>
                </div>
                <div className="flex-1 flex flex-col items-start justify-start pt-[5px] px-0 pb-0 box-border min-w-[135px]">
                  <div className="w-[146px] h-[22px] relative text-sm tracking-[-0.01em] font-medium font-poppins text-darkslategray-900 text-center inline-block shrink-0">
                    10 MAI 2023
                  </div>
                </div>
                <div className="w-[140px] flex flex-col items-start justify-start pt-[5px] px-0 pb-0 box-border">
                  <div className="w-[93px] h-[22px] relative text-sm tracking-[-0.01em] font-medium font-poppins text-darkslategray-900 text-left inline-block shrink-0">
                    Juin 2028
                  </div>
                </div>
                <button className="cursor-pointer py-1 pr-[5px] pl-[9px] bg-mediumaquamarine-200 w-20 rounded box-border flex flex-row items-center justify-center whitespace-nowrap border-[1px] border-solid border-mediumseagreen hover:bg-seagreen-200 hover:box-border hover:border-[1px] hover:border-solid hover:border-mediumaquamarine-100">
                  <div className="h-[21px] relative text-sm tracking-[-0.01em] font-medium font-poppins text-seagreen-100 text-left inline-block">
                    En cours
                  </div>
                </button>
              </div>
            </div>
            <div className="w-[928px] h-[13px] flex flex-row items-start justify-start pt-0 px-5 pb-[13px] box-border max-w-full">
              <div
                role={"presentation"}
                className="h-px flex-1 relative box-border max-w-full cursor-pointer z-[1] border-t-[1px] border-solid border-whitesmoke-400"
                onClick={onLine2Click}
              />
            </div>
            <div className="w-[930px] flex flex-row items-start justify-start pt-0 px-5 pb-[13px] box-border max-w-full shrink-0">
              <div
                role={"presentation"}
                className="h-[51.6px] flex-1 relative max-w-full cursor-pointer z-[1]"
                onClick={onGroupContainer2Click}
              >
                <div className="absolute top-[4px] left-[0px] text-sm tracking-[-0.01em] font-medium font-poppins text-darkslategray-900 text-left inline-block w-[129px] h-[22px]">
                  Achat 2000 T gravier
                </div>
                <div className="absolute top-[4.1px] left-[172px] text-sm tracking-[-0.01em] font-medium font-poppins text-darkslategray-900 text-center inline-block w-16 h-[22px]">
                  ...
                </div>
                <div className="absolute top-[4.9px] left-[303px] text-sm tracking-[-0.01em] font-medium font-poppins text-darkslategray-900 text-left inline-block w-[141px] h-[46.9px]">
                  Floyd ADEOTI floyd@straca.com
                </div>
                <div className="absolute top-[4.4px] left-[494px] text-sm tracking-[-0.01em] font-medium font-poppins text-darkslategray-900 text-left inline-block w-32 h-[22px]">
                  25 AOUT 2022
                </div>
                <div className="absolute top-[4px] left-[666px] text-sm tracking-[-0.01em] font-medium font-poppins text-darkslategray-900 text-left inline-block w-[121px] h-[22px]">
                  08 JANVIER 2023
                </div>
                <div className="absolute top-[0px] left-[806px] rounded bg-lemonchiffon box-border w-[84px] flex flex-row items-center justify-center py-1 pr-[23px] pl-[22px] border-[1px] border-solid border-gold">
                  <div className="h-[21px] relative text-sm tracking-[-0.01em] font-medium font-poppins text-goldenrod text-left inline-block">
                    Livrer
                  </div>
                </div>
                <div
                  role={"presentation"}
                  className="absolute top-[51.6px] left-[2px] box-border w-[889px] h-px cursor-pointer z-[2] border-t-[1px] border-solid border-whitesmoke-400"
                  onClick={onLine3Click}
                />
              </div>
            </div>
            <div
              role={"presentation"}
              className="flex flex-row items-start justify-start py-0 px-5 box-border gap-[50px] max-w-full shrink-0 cursor-pointer z-[1] mq450:gap-[25px] mq1100:flex-wrap"
              onClick={onGroupContainer3Click}
            >
              <div className="w-[122px] flex flex-col items-start justify-start pt-1 px-0 pb-0 box-border">
                <div className="w-[114px] h-[22px] relative text-sm tracking-[-0.01em] font-medium font-poppins text-darkslategray-900 text-left inline-block shrink-0">
                  Huile HAFA
                </div>
              </div>
              <div className="w-[81px] flex flex-col items-start justify-start pt-[5px] px-0 pb-0 box-border">
                <div className="w-16 h-[22px] relative text-sm tracking-[-0.01em] font-medium font-poppins text-darkslategray-900 text-center inline-block shrink-0">
                  ...
                </div>
              </div>
              <div className="h-11 w-[141px] flex flex-col items-start justify-start pt-1 px-0 pb-0 box-border">
                <div className="self-stretch flex-1 relative text-sm tracking-[-0.01em] font-medium font-poppins text-darkslategray-900 text-left">
                  Ronald BIGNON ronald@straca.com
                </div>
              </div>
              <div className="flex flex-row items-center justify-start py-0 pr-px pl-0 box-border gap-[33px] max-w-full mq450:flex-wrap mq450:gap-[16px]">
                <div className="relative text-sm tracking-[-0.01em] font-medium font-poppins text-darkslategray-900 text-left">
                  23 septembre 2022
                </div>
                <div className="flex flex-col items-start justify-start pt-[3px] px-0 pb-0">
                  <div className="h-[22px] relative text-sm tracking-[-0.01em] font-medium font-poppins text-darkslategray-900 text-left inline-block shrink-0">
                    25 AOUT 2022
                  </div>
                </div>
                <div className="w-[84px] rounded bg-lemonchiffon box-border flex flex-row items-center justify-center py-1 pr-[23px] pl-[22px] border-[1px] border-solid border-gold">
                  <div className="h-[21px] relative text-sm tracking-[-0.01em] font-medium font-poppins text-goldenrod text-left inline-block">
                    Livrer
                  </div>
                </div>
              </div>
            </div>
            <div className="w-[928px] h-[13px] flex flex-row items-start justify-start pt-0 px-5 pb-[13px] box-border max-w-full">
              <div
                role={"presentation"}
                className="h-px flex-1 relative box-border max-w-full cursor-pointer z-[1] border-t-[1px] border-solid border-whitesmoke-400"
                onClick={onLine4Click}
              />
            </div>
            <div className="w-[932px] flex flex-row items-start justify-start py-0 pr-[26px] pl-5 box-border min-h-[44px] max-w-full shrink-0">
              <div
                role={"presentation"}
                className="flex-1 flex flex-row items-end justify-start gap-[12px] max-w-full cursor-pointer z-[1] mq750:flex-wrap"
                onClick={onGroupContainer4Click}
              >
                <div className="w-40 flex flex-col items-start justify-start">
                  <div className="w-[118px] relative text-sm tracking-[-0.01em] font-medium font-poppins text-darkslategray-900 text-left inline-block">
                    Location Volvo 240
                  </div>
                </div>
                <div className="w-[119px] flex flex-col items-start justify-start">
                  <div className="w-16 h-[22px] relative text-sm tracking-[-0.01em] font-medium font-poppins text-darkslategray-900 text-center inline-block shrink-0">
                    ...
                  </div>
                </div>
                <div className="flex-1 flex flex-row items-start justify-center gap-[31px] min-w-[228px] max-w-full mq450:flex-wrap mq450:gap-[15px]">
                  <div className="h-[22.1px] flex-1 relative text-sm tracking-[-0.01em] font-medium font-poppins text-darkslategray-900 text-left inline-block min-w-[104px]">
                    Marvin AKO marvin@straca.com
                  </div>
                  <div className="h-[22.1px] flex-1 relative text-sm tracking-[-0.01em] font-medium font-poppins text-darkslategray-900 text-left inline-block min-w-[104px]">
                    10 MAI 2023
                  </div>
                </div>
                <div className="w-[220px] flex flex-row items-end justify-between gap-[20px]">
                  <div className="h-[22px] relative text-sm tracking-[-0.01em] font-medium font-poppins text-darkslategray-900 text-left inline-block">
                    11 MARS 2025
                  </div>
                  <div className="w-20 rounded bg-mediumaquamarine-200 box-border flex flex-row items-center justify-center py-1 pr-[5px] pl-[9px] whitespace-nowrap border-[1px] border-solid border-mediumseagreen">
                    <div className="h-[21px] relative text-sm tracking-[-0.01em] font-medium font-poppins text-seagreen-100 text-left inline-block">
                      En cours
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="w-[928px] h-[13px] flex flex-row items-start justify-start pt-0 px-5 pb-[13px] box-border max-w-full">
              <div
                role={"presentation"}
                className="h-px flex-1 relative box-border max-w-full cursor-pointer z-[1] border-t-[1px] border-solid border-whitesmoke-400"
                onClick={onLine5Click}
              />
            </div>
            <div className="w-[932px] flex flex-row items-start justify-start py-0 pr-[26px] pl-5 box-border min-h-[43px] max-w-full shrink-0">
              <div
                role={"presentation"}
                className="flex-1 flex flex-row items-center justify-start gap-[25px] max-w-full cursor-pointer z-[1] mq750:flex-wrap"
                onClick={onGroupContainer5Click}
              >
                <div className="w-[147px] flex flex-col items-start justify-start">
                  <div className="relative text-sm tracking-[-0.01em] font-medium font-poppins text-darkslategray-900 text-left">
                    Achat pneu
                  </div>
                </div>
                <div className="w-[106px] flex flex-col items-start justify-start">
                  <div className="w-16 h-[22px] relative text-sm tracking-[-0.01em] font-medium font-poppins text-darkslategray-900 text-center inline-block shrink-0">
                    ...
                  </div>
                </div>
                <div className="flex-1 flex flex-col items-start justify-start py-0 pr-[19px] pl-0 box-border min-w-[108px]">
                  <div className="self-stretch h-[22px] relative text-sm tracking-[-0.01em] font-medium font-poppins text-darkslategray-900 text-left inline-block shrink-0">
                    Anna ABALO anna@gmail.com
                  </div>
                </div>
                <div className="w-[147px] relative text-sm tracking-[-0.01em] font-medium font-poppins text-darkslategray-900 text-left inline-block shrink-0">
                  11 juillet 2023
                </div>
                <div className="flex flex-col items-start justify-start py-0 pr-[22px] pl-0">
                  <div className="h-[22px] relative text-sm tracking-[-0.01em] font-medium font-poppins text-darkslategray-900 text-left inline-block shrink-0">
                    23 Avril 2025
                  </div>
                </div>
                <button className="cursor-pointer py-1 pr-[5px] pl-[9px] bg-mediumaquamarine-200 w-20 rounded box-border flex flex-row items-center justify-center whitespace-nowrap border-[1px] border-solid border-mediumseagreen hover:bg-seagreen-200 hover:box-border hover:border-[1px] hover:border-solid hover:border-mediumaquamarine-100">
                  <div className="h-[21px] relative text-sm tracking-[-0.01em] font-medium font-poppins text-seagreen-100 text-left inline-block">
                    En cours
                  </div>
                </button>
              </div>
            </div>
            <div className="w-[928px] h-3.5 flex flex-row items-start justify-start pt-0 px-5 pb-3.5 box-border max-w-full">
              <div
                role={"presentation"}
                className="h-px flex-1 relative box-border max-w-full cursor-pointer z-[1] border-t-[1px] border-solid border-whitesmoke-400"
                onClick={onLine6Click}
              />
            </div>
            <div className="w-[930px] flex flex-row items-start justify-start pt-0 px-5 pb-[13px] box-border max-w-full shrink-0">
              <div
                role={"presentation"}
                className="flex-1 flex flex-row items-center justify-start gap-[3px] max-w-full cursor-pointer z-[1] mq750:flex-wrap"
                onClick={onGroupContainer6Click}
              >
                <div className="w-[169px] flex flex-col items-start justify-start">
                  <div className="w-[111px] h-[22px] relative text-sm tracking-[-0.01em] font-medium font-poppins text-darkslategray-900 text-left inline-block shrink-0">
                    Location 5 caterpillar
                  </div>
                </div>
                <div className="w-32 flex flex-col items-start justify-start">
                  <div className="w-16 h-[22px] relative text-sm tracking-[-0.01em] font-medium font-poppins text-darkslategray-900 text-center inline-block shrink-0">
                    ...
                  </div>
                </div>
                <div className="flex-1 flex flex-row items-start justify-center gap-[22px] min-w-[234px] max-w-full mq450:flex-wrap">
                  <div className="h-[22px] flex-1 relative text-sm tracking-[-0.01em] font-medium font-poppins text-darkslategray-900 text-left inline-block min-w-[110px]">
                    Bissi ABIOLA bissi@abiola.com
                  </div>
                  <div className="h-[22px] flex-1 relative text-sm tracking-[-0.01em] font-medium font-poppins text-darkslategray-900 text-left inline-block min-w-[110px]">
                    10 MAI 2023
                  </div>
                </div>
                <div className="w-[137px] flex flex-col items-start justify-start">
                  <div className="h-[22px] relative text-sm tracking-[-0.01em] font-medium font-poppins text-darkslategray-900 text-left inline-block shrink-0">
                    10 mai 2024
                  </div>
                </div>
                <button className="cursor-pointer py-1 pr-[7px] pl-[11px] bg-mediumaquamarine-200 w-[84px] rounded box-border flex flex-row items-center justify-center whitespace-nowrap border-[1px] border-solid border-mediumseagreen hover:bg-seagreen-200 hover:box-border hover:border-[1px] hover:border-solid hover:border-mediumaquamarine-100">
                  <div className="h-[21px] relative text-sm tracking-[-0.01em] font-medium font-poppins text-seagreen-100 text-left inline-block">
                    En cours
                  </div>
                </button>
              </div>
            </div>
            <div className="w-[928px] h-[13px] flex flex-row items-start justify-start pt-0 px-5 pb-[13px] box-border max-w-full">
              <div
                role={"presentation"}
                className="h-px flex-1 relative box-border max-w-full cursor-pointer z-[1] border-t-[1px] border-solid border-whitesmoke-400"
                onClick={onLine7Click}
              />
            </div>
            <div className="w-[930px] flex flex-row items-start justify-start pt-0 px-5 pb-[15px] box-border max-w-full shrink-0">
              <div
                role={"presentation"}
                className="flex-1 flex flex-row items-end justify-between gap-[20px] max-w-full cursor-pointer z-[1] mq1100:flex-wrap"
                onClick={onGroupContainer7Click}
              >
                <div className="w-[133px] flex flex-col items-start justify-start">
                  <div className="w-[89px] h-[22px] relative text-sm tracking-[-0.01em] font-medium font-poppins text-darkslategray-900 text-left inline-block shrink-0">
                    Location grue
                  </div>
                </div>
                <div className="w-[92px] flex flex-col items-start justify-start py-0 pr-7 pl-0 box-border">
                  <div className="self-stretch h-[22px] relative text-sm tracking-[-0.01em] font-medium font-poppins text-darkslategray-900 text-center inline-block shrink-0">
                    ...
                  </div>
                </div>
                <div className="w-[152px] flex flex-col items-start justify-start py-0 pr-[19px] pl-0 box-border">
                  <div className="self-stretch h-[22px] relative text-sm tracking-[-0.01em] font-medium font-poppins text-darkslategray-900 text-left inline-block shrink-0">
                    Joyce Dadjo joyce@gmail.com
                  </div>
                </div>
                <div className="h-[22px] w-[133px] relative text-sm tracking-[-0.01em] font-medium font-poppins text-darkslategray-900 text-left inline-block shrink-0">
                  07 mars 2021
                </div>
                <div className="w-[101px] flex flex-col items-start justify-start py-0 pr-[3px] pl-0 box-border">
                  <div className="self-stretch relative text-sm tracking-[-0.01em] font-medium font-poppins text-darkslategray-900 text-left">
                    11 juillet 2026
                  </div>
                </div>
                <button className="cursor-pointer py-1 pr-[7px] pl-[11px] bg-mediumaquamarine-200 w-[84px] rounded box-border flex flex-row items-center justify-center whitespace-nowrap border-[1px] border-solid border-mediumseagreen hover:bg-seagreen-200 hover:box-border hover:border-[1px] hover:border-solid hover:border-mediumaquamarine-100">
                  <div className="h-[21px] relative text-sm tracking-[-0.01em] font-medium font-poppins text-seagreen-100 text-left inline-block">
                    En cours
                  </div>
                </button>
              </div>
            </div>
            <div className="w-[928px] h-[13px] flex flex-row items-start justify-start pt-0 px-5 pb-[13px] box-border max-w-full">
              <div
                role={"presentation"}
                className="h-px flex-1 relative box-border max-w-full cursor-pointer z-[1] border-t-[1px] border-solid border-whitesmoke-400"
                onClick={onLine8Click}
              />
            </div>
            <div className="flex flex-row items-start justify-start pt-0 px-5 pb-3.5 box-border max-w-full shrink-0">
              <div
                role={"presentation"}
                className="flex flex-row items-center justify-start gap-[67px] max-w-full cursor-pointer z-[1] mq450:gap-[17px] mq1100:flex-wrap mq1100:gap-[33px]"
                onClick={onGroupContainer8Click}
              >
                <div className="w-[105px] flex flex-col items-start justify-start py-0 pr-[5px] pl-0 box-border">
                  <div className="self-stretch h-[22px] relative text-sm tracking-[-0.01em] font-medium font-poppins text-darkslategray-900 text-left inline-block shrink-0">{`Achat Pneu `}</div>
                </div>
                <div className="h-[22px] w-16 relative text-sm tracking-[-0.01em] font-medium font-poppins text-darkslategray-900 text-center inline-block shrink-0">
                  ...
                </div>
                <div className="w-[587px] flex flex-row items-center justify-center gap-[16px] max-w-full mq750:flex-wrap">
                  <div className="flex-1 flex flex-col items-start justify-start py-0 pr-[19px] pl-0 box-border min-w-[114px]">
                    <div className="self-stretch h-[22px] relative text-sm tracking-[-0.01em] font-medium font-poppins text-darkslategray-900 whitespace-pre-wrap text-left inline-block shrink-0">
                      Gontran ADJOVI gontran@straca.com
                    </div>
                  </div>
                  <div className="h-[22px] w-[156px] relative text-sm tracking-[-0.01em] font-medium font-poppins text-darkslategray-900 text-left inline-block shrink-0">
                    23 decembre 2019
                  </div>
                  <div className="w-[124px] flex flex-col items-start justify-start py-0 pr-[30px] pl-0 box-border">
                    <div className="self-stretch h-[22px] relative text-sm tracking-[-0.01em] font-medium font-poppins text-darkslategray-900 text-left inline-block shrink-0">
                      10 MAI 2023
                    </div>
                  </div>
                  <div className="w-[84px] rounded bg-lemonchiffon box-border flex flex-row items-center justify-center py-1 pr-[23px] pl-[22px] border-[1px] border-solid border-gold">
                    <div className="h-[21px] relative text-sm tracking-[-0.01em] font-medium font-poppins text-goldenrod text-left inline-block">
                      Livrer
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="w-[928px] h-[26px] flex flex-row items-start justify-start pt-0 px-5 pb-[26px] box-border max-w-full">
              <div
                role={"presentation"}
                className="h-px flex-1 relative box-border max-w-full cursor-pointer z-[1] border-t-[1px] border-solid border-whitesmoke-400"
                onClick={onLine9Click}
              />
            </div>
            <div className="w-[928px] flex flex-row items-start justify-end py-0 px-5 box-border max-w-full shrink-0">
              <div className="w-[210px] flex flex-row items-start justify-start py-0 pr-[3px] pl-0 box-border gap-[11px]">
                <div className="flex-1 rounded bg-whitesmoke-200 flex flex-row items-center justify-center p-1.5 z-[1] border-[1px] border-solid border-whitesmoke-400">
                  <div className="relative text-xs tracking-[-0.01em] leading-[100%] font-medium font-poppins text-darkslategray-100 text-left">{`<`}</div>
                </div>
                <div className="flex-1 rounded bg-cornflowerblue flex flex-row items-center justify-center p-1.5 z-[1] border-[1px] border-solid border-blueviolet">
                  <div className="relative text-xs tracking-[-0.01em] leading-[100%] font-medium font-poppins text-ghostwhite-100 text-left">
                    1
                  </div>
                </div>
                <div className="flex-1 rounded bg-whitesmoke-200 flex flex-row items-center justify-center p-1.5 z-[1] border-[1px] border-solid border-whitesmoke-400">
                  <div className="relative text-xs tracking-[-0.01em] leading-[100%] font-medium font-poppins text-darkslategray-100 text-left">
                    2
                  </div>
                </div>
                <div className="flex-1 rounded bg-whitesmoke-200 flex flex-row items-center justify-center p-1.5 z-[1] border-[1px] border-solid border-whitesmoke-400">
                  <div className="relative text-xs tracking-[-0.01em] leading-[100%] font-medium font-poppins text-darkslategray-100 text-left">
                    3
                  </div>
                </div>
                <div className="flex-1 rounded bg-whitesmoke-200 flex flex-row items-center justify-center p-1.5 z-[1] border-[1px] border-solid border-whitesmoke-400">
                  <div className="relative text-xs tracking-[-0.01em] leading-[100%] font-medium font-poppins text-darkslategray-100 text-left">
                    4
                  </div>
                </div>
                <div className="flex-1 rounded bg-whitesmoke-200 flex flex-row items-center justify-center p-1.5 z-[1] border-[1px] border-solid border-whitesmoke-400">
                  <div className="relative text-xs tracking-[-0.01em] leading-[100%] font-medium font-poppins text-darkslategray-100 text-left">{`>`}</div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </section>
    </div>*/
  );
};

export default ClientsOrders;
