const Certifications = () => {
  const data = [
    {
      year: "2019",
      iso: "ISO 9001",
      title: "Gestion de qualité",
      description:
        "Les taux de satisfactions élevés de nos clients garantissent notre recherche de l'excellence. Un engagement de qualité validé par la certification des systèmes de managment de la qualité validé par la certifacation des systèmes de mangment de la qualité ISO9001",
      image: "/leading-item@2x.png",
    },
    {
      year: "2019",
      iso: "ISO 9001",
      title: "Gestion de qualité",
      description:
        "Les taux de satisfactions élevés de nos clients garantissent notre recherche de l'excellence. Un engagement de qualité validé par la certification des systèmes de managment de la qualité validé par la certifacation des systèmes de mangment de la qualité ISO9001",
      image: "/leading-item@2x.png",
    },
    {
      year: "2019",
      iso: "ISO 9001",
      title: "Gestion de qualité",
      description:
        "Les taux de satisfactions élevés de nos clients garantissent notre recherche de l'excellence. Un engagement de qualité validé par la certification des systèmes de managment de la qualité validé par la certifacation des systèmes de mangment de la qualité ISO9001",
      image: "/leading-item@2x.png",
    },
    {
      year: "2019",
      iso: "ISO 9001",
      title: "Gestion de qualité",
      description:
        "Les taux de satisfactions élevés de nos clients garantissent notre recherche de l'excellence. Un engagement de qualité validé par la certification des systèmes de managment de la qualité validé par la certifacation des systèmes de mangment de la qualité ISO9001",
      image: "/leading-item@2x.png",
    },
  ];

  const honors = [
    {
      image: "/new/honor-1.png",
      name: "Prix de la Meilleure Entreprise de Logistique",
    },
    {
      image: "/new/honor-1.png",
      name: "Prix de la Meilleure Entreprise de Logistique",
    },
    {
      image: "/new/honor-1.png",
      name: "Prix de la Meilleure Entreprise de Logistique",
    },
  ];

  return (
    <div className="flex flex-col py-10 px-8 md:px-32 gap-10 font-urbanist">
      <h3 className="font-medium text-4xl">Certifications et honneurs</h3>
      <h2 className="text-3xl">Nos certifications</h2>
      <p className="text-base max-w-md text-gray-500">
        {
          "La gestion de la qualité est d'une importance capitale dans notre plan stratégique. Nous comprenons la qualité comme l'un des piliers stratégiques qui articulent toute notre activité, pour la satisfaction des exigences"
        }
      </p>

      <div className="flex flex-col gap-10">
        {data.map((cert, index) => (
          <div
            key={`cert-${index}`}
            className="flex flex-col md:flex-row gap-5 md:gap-0  shadow-lg shadow-blue-100  w-full items-center justify-between p-10 rounded-xl"
          >
            <div className=" flex flex-col  self-start md:self-center justify-center ">
              <span className="text-sm text-[#5956EB]">{cert.year}</span>
              <span className="text-lg font-semibold">{cert.iso}</span>
              <span className="text-base text-main-orange font-medium">
                {cert.title}
              </span>
            </div>
            <div className=" flex flex-col md:flex-row items-center gap-10">
              <p className="max-w-lg ">{cert.description}</p>
              <img
                className="h-24 w-24 object-cover "
                loading="eager"
                alt=""
                src={cert.image}
              />
            </div>
          </div>
        ))}
      </div>

      <h2 className="text-3xl">Nos honneurs</h2>
      <p className="text-base max-w-md text-gray-500">
        {
          "Notre entreprise de transport et logistique a été reconnue à plusieurs reprises pour son excellence, son professionnalisme et sa contribution significative à l'industrie. Ces honneurs témoignent de notre engagement envers la qualité de service, l'innovation et notre capacité à répondre aux besoins logistiques les plus complexes."
        }
      </p>

      <div className="flex flex-col  md:flex-row justify-around gap-10 md:gap-0">
        {honors.map((honor, index) => (
          <div
            key={`honor-${index}`}
            className="flex flex-col items-center gap-5"
          >
            <div className="w-fit h-fit border-[10px] border-main-color border-opacity-10 rounded-full">
              <div className="w-fit h-fit flex justify-center p-12 border-[10px] border-opacity-20 border-real-main-color rounded-full">
                <img
                  className="h-24 w-24"
                  loading="eager"
                  alt=""
                  src={honor.image}
                />
              </div>
            </div>
            <span className="max-w-52 font-inter text-lg text-center">
              {honor.name}
            </span>
          </div>
        ))}
      </div>

      <p className="my-10 text-base max-w-md text-gray-500">
        {
          "Nous sommes fiers de ces réalisations et nous nous engageons à continuer à fournir des services logistiques exceptionnels, à innover dans notre approche et à rester à la pointe de notre industrie."
        }
      </p>
    </div>
  );
};

export default Certifications;
