import React from "react";
import { Outlet, useNavigate } from "react-router-dom";
import Sidebar from "../../components/Sidebar";
import { FaArrowLeft } from "react-icons/fa6";

const DashboardLayout = () => {
  const navigate = useNavigate();

  return (
    <div className="flex flex-col lg:flex-row">
      <Sidebar />
      <main className="w-full h-screen max-h-screen overflow-scroll overflow-y-scroll p-10">
        <button className="mb-16" onClick={() => navigate(-1)}>
          <FaArrowLeft className="text-black h-7 w-7" />
        </button>
        <Outlet />
      </main>
    </div>
  );
};

export default DashboardLayout;
