import { Link, useNavigate } from "react-router-dom";
import {
  FaArrowRight,
  FaFacebookF,
  FaLinkedinIn,
  FaPhoneAlt,
} from "react-icons/fa";
import { CiMail } from "react-icons/ci";
import { FaXTwitter } from "react-icons/fa6";

const Footer = () => {
  // navigations

  const navigate = useNavigate();

  const navigation = {
    pages: [
      { name: "Straca", href: "/straca" },
      { name: "Expertise", href: "/expeertise" },
      { name: "Culture", href: "/culture/actualite" },
      { name: "Services", href: "#" },
      { name: "Partenaires", href: "/partenaires" },
      { name: "Clients", href: "#" },
    ],
    informations: [
      { name: "FAQ", href: "#" },
      { name: "Culture", href: "#" },
    ],
    company: [
      { name: "Espace client", href: "/espace-client" },
      { name: "Espace salarié", href: "#" },
      {
        name: "Collaborons",
        href: "/collaborons",
        type: "button",
        onClick: () => {
          navigate("/collaborons");
        },
      },
    ],
    legal: [
      { name: "Conditions", href: "#" },
      { name: "Confidentialité", href: "#" },
      { name: "Cookies", href: "#" },
    ],
    social: [
      {
        name: "Linkedin",
        href: "https://www.linkedin.com/company/straca-sa/",
        icon: (props) => <FaLinkedinIn {...props} />,
      },
      {
        name: "Facebook",
        href: "#",
        icon: (props) => <FaFacebookF {...props} />,
      },
      {
        name: "X",
        href: "#",
        icon: (props) => <FaXTwitter {...props} />,
      },
    ],
  };

  //const [email, setEmail] = useState("");

  // NewsLetter popup
  /*  const [popupVisible, setPopupVisible] = useState(false);

  const togglePopupVisible = () => {
    setPopupVisible(!popupVisible);
  };*/

  // Newsletter API
  /*
  const [newsLetterMessage, setNewsLetterMessage] = useState("");
  const sendNewsLetterEmail = async () => {
    try {
      if (email) {
        await axios.post(`${serverUrl()}/newsLetter`, {
          email,
        });

        setNewsLetterMessage("Your email will now receive our newsletters");
        togglePopupVisible();
      }
    } catch (error) {
      const errorMessage = error.response.data.message;
      console.log(errorMessage);
      setNewsLetterMessage(errorMessage);
      togglePopupVisible();
    }
  };
*/

  return (
    <footer
      aria-labelledby="footer-heading"
      className="bg-white font-inter drop-shadow-2xl w-full "
    >
      <h2 id="footer-heading" className="sr-only">
        Footer
      </h2>
      <div className="mx-auto max-w-7xl px-6 pb-8 pt-20 sm:pt-24 lg:px-8 lg:pt-32">
        <div className="flex flex-col md:flex-row gap-20 ">
          <div className="md:w-[25%]">
            <div className="">
              <h3 className="text-sm font-semibold leading-6 text-gray-900">
                Pages
              </h3>
              <ul className="mt-6 space-y-4">
                {navigation.pages.map((item) => (
                  <li key={`${item.name}-pages`}>
                    <a
                      href={item.href}
                      className="text-sm leading-6 text-gray-600 hover:text-gray-900"
                    >
                      {item.name}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className="md:w-[40%] flex flex-col md:flex-row gap-10">
            <div className="">
              <h3 className="text-sm font-semibold leading-6 text-gray-900">
                Informations
              </h3>
              <ul className="mt-6 space-y-4">
                {navigation.informations.map((item) => (
                  <li key={`${item.name}-infos`}>
                    <a
                      href={item.href}
                      className="text-sm leading-6 text-gray-600 hover:text-gray-900"
                    >
                      {item.name}
                    </a>
                  </li>
                ))}
              </ul>

              <div className="mt-8 md:mt-16 flex flex-col gap-2">
                <div className="flex flex-row text-base items-center gap-2">
                  <FaPhoneAlt className="h-5 w-5 text-gray-400" />
                  <span>+229 95 95 67 31</span>
                </div>
                <div className="flex flex-row text-base items-center gap-2">
                  <CiMail className="h-5 w-5 text-gray-400" />
                  <a href="mailto:conctat@straca-sa.fr">contat@straca-sa.com</a>
                </div>
              </div>
            </div>
            <div className="">
              <h3 className="text-sm font-semibold leading-6 text-gray-900">
                Important
              </h3>
              <ul className="mt-6 space-y-4">
                {navigation.company.map((item) => (
                  <div key={`${item.name}-company`}>
                    {item.type === "button" ? (
                      <li>
                        <button
                          onClick={item.onClick}
                          className="text-sm text-white py-2 px-4 bg-real-main-color rounded-lg"
                        >
                          {item.name}
                        </button>
                      </li>
                    ) : (
                      <li>
                        <a
                          href={item.href}
                          className="text-sm leading-6 text-gray-600 hover:text-gray-900"
                        >
                          {item.name}
                        </a>
                      </li>
                    )}
                  </div>
                ))}
              </ul>
            </div>
          </div>

          <div className="md:w-[35%] mt-10 xl:mt-0 bg-gray-100 p-10">
            <h3 className="text-sm font-semibold leading-6 text-gray-900">
              Newsletter
            </h3>

            <div className="mt-6 flex rounded-md shadow-sm">
              <div className="relative flex flex-grow items-stretch focus-within:z-10">
                <input
                  id="email"
                  name="email"
                  type="email"
                  placeholder="Email"
                  className="block w-full rounded-none rounded-l-md border-0 py-1.5 pl-3 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
              <button
                type="button"
                className="relative inline-flex items-center gap-x-1.5 rounded-r-md px-3 py-2 text-sm font-semibold text-white bg-real-main-color hover:bg-opacity-90"
              >
                <FaArrowRight className="h-4 w-4" />
              </button>
            </div>
            <p className="mt-6 text-sm leading-6 text-gray-600">
              {
                "Bonjour, nous sommes Straca. Notre objectif ici est d'informer nos clients en temps et en heure."
              }
            </p>
          </div>
        </div>
        <div className="mt-16 border-t border-gray-900/10 pt-8 sm:mt-20 md:flex md:items-center md:justify-between lg:mt-24">
          <div className="flex lg:flex-row flex-col md:order-2 gap-5 text-sm">
            {navigation.legal.map((item) => (
              <Link key={`${item.name}-legal`} to={item.href}>
                {item.name}
              </Link>
            ))}
          </div>

          <div className="flex space-x-6 md:order-3 mt-8 md:mt-0">
            {navigation.social.map((item) => (
              <a
                key={`${item.name}-social`}
                href={item.href}
                target="_blank"
                rel="noopener noreferrer"
                className="text-black border p-2 rounded-full"
              >
                <span className="sr-only">{item.name}</span>
                <item.icon aria-hidden="true" className="h-4 w-4" />
              </a>
            ))}
          </div>
          <img
            src="/straca_benin/straca_benin.svg"
            alt=""
            className="w-36 h-auto mt-8 md:order-1 md:mt-0"
          />
        </div>
      </div>
    </footer>
  );
};

export default Footer;
