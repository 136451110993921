import { useState } from "react";
import MyTextField from "../components/MyTextField";
import CustomUploadFile from "../components/CustomUploadFile";
import axios from "axios";
import Swal from "sweetalert2";
import ClipLoader from "react-spinners/ClipLoader";
import { useSelectedJobs } from "../context/SelectedJobContext";
import { serverUrl } from "../constants";

const ApplyForJob = ({ onClose }) => {
  const [isLoading, setIsLoading] = useState(false);

  const { selectedJobs } = useSelectedJobs();

  const [selectedFile, setSelectedFile] = useState(null);

  const handleSelectedFile = (file) => {
    setSelectedFile(file);
  };

  // Register
  const [rName, setRName] = useState("");
  const [rPhone, setRPhone] = useState("");
  const [rEmail, setREmail] = useState("");
  const [cvMessage, setCvMessage] = useState(
    "La STRACA vous contactera par mail afin de finaliser votre votre inscription",
  );

  const handleClose = (e) => {
    if (e.target.id === "wrapper") {
      onClose();
    }
  };
  const uploadCV = async () => {
    try {
      setIsLoading(true);
      const formData = new FormData();
      formData.append("name", rName);
      formData.append("email", rEmail);
      formData.append("file", selectedFile);
      formData.append("phoneNumber", rPhone);
      formData.append("jobIds", JSON.stringify(selectedJobs));
      if (rName && rEmail && rPhone && selectedFile) {
        const response = await axios.post(`${serverUrl()}/cv`, formData);
        console.log(response.data);

        Swal.fire({
          title: "succès",
          text: `${cvMessage}`,
          icon: "success",
        });
        onClose();
      }
    } catch (error) {
      console.log("Error:", error);
      if (error.response) {
        console.log("Response status:", error.response.status);
        console.log("Response data:", error.response.data);
        setCvMessage(error.response.data.message);
      } else if (error.request) {
        console.log("No response received:", error.request);
        setCvMessage("No response received from the server");
      } else {
        console.log("Request setup error:", error.message);
        setCvMessage("Error setting up the request");
      }
      setCvMessage(error.response.data.message);
      Swal.fire({
        title: "erreur",
        text: `${cvMessage}`,
        icon: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div
      role={"presentation"}
      className="z-[3] fixed inset-0 flex flex-col items-center justify-center min-h-screen bg-ghostwhite-100 bg-opacity-50"
      id="wrapper"
      onClick={handleClose}
    >
      <form className="m-3 w-[1208px] flex flex-row items-start justify-start gap-[72px] max-w-full mq750:gap-[36px] mq450:gap-[18px] mq1050:flex-wrap rounded-lg">
        <div className="flex-1 bg-white flex flex-col rounded-3xl backdrop-blur-3xl items-center justify-start pt-[55px] px-0 pb-[133px] box-border gap-[40px] min-w-[406px] max-w-full mq750:gap-[20px] mq750:pt-9 mq750:pb-[86px] mq750:box-border mq750:min-w-full">
          <h1 className="text-[#24285B]">Télecharger votre C.V.</h1>
          <div className="flex flex-row gap-4">
            <div className="w-[447px] flex flex-col items-center justify-center gap-[31px] max-w-full">
              <MyTextField
                title={"Nom"}
                hintText={"Entrer votre nom"}
                onChange={setRName}
                value={rName}
              />
              <MyTextField
                title={"Numéro de téléphone"}
                hintText={"Entrer votre Numéro de téléphone"}
                onChange={setRPhone}
                value={rPhone}
              />
              <MyTextField
                title={"Email"}
                hintText={"Entrer votre Numéro de email"}
                onChange={setREmail}
                value={rEmail}
              />

              <button
                type="button"
                className="cursor-pointer [border:none] pt-[27px] pb-[25px] pr-6 pl-[23px] bg-main-color w-2/3 rounded-smi flex flex-row items-center justify-center box-border z-[1] hover:bg-chocolate"
                onClick={uploadCV}
              >
                <div className="h-[67px] w-[281px] relative rounded-smi bg-main-orange hidden" />
                <div className="h-[15px] flex-1 relative text-xl leading-[22px] font-medium font-poppins text-white text-center flex items-center justify-center z-[1]">
                  {isLoading ? <ClipLoader size={40} /> : "Postuler"}
                </div>
              </button>
            </div>
            <div className="w-[0.5px] bg-main-color" />
            <div className="w-[447px]">
              <div className="flex flex-col mb-3 py-8 bg-white border border-gray-200 rounded-md justify-center items-center font-poppins">
                <img
                  src={"/cloud_upload.png"}
                  alt="Telecharger"
                  width="100"
                  height="100"
                />
                <p className="mt-3 font-semibold">Telecharger C.V.</p>
                {selectedFile && <p>{selectedFile.name}</p>}
                <p className="mb-3 text-sm text-gray-500">
                  Supported formats: .text; .rtf
                </p>
                <CustomUploadFile
                  handleChangeFile={() => {}}
                  handleChangeFileInfo={() => {}}
                  handleSelectedFile={handleSelectedFile}
                />
              </div>
            </div>
          </div>
        </div>

        {/* <h1>C.V</h1> */}
      </form>
    </div>
  );
};

export default ApplyForJob;
