import clsx from "clsx";
import BoatLocalIcon from "../icons/BoatLocalIcon";
import CityLocalIcon from "../icons/City";
import PlaneLocalIcon from "../icons/Plane";
import TruckLocalIcon from "../icons/Truck";

const PartnersFrameQuotidien = () => {
  const data = [
    {
      name: "Fiabilité",
      description:
        "Pilier central de notre entreprise afin de bâtir des relations de confiance et de répondre aux attentes les plus élevées.",
      iconLink: BoatLocalIcon,
      iconColor: "black",
      mdIconColor: "white",
      textColor: "text-black md:text-white",
      dividerColor: "bg-black md:bg-white",
    },
    {
      name: "Innovation",
      description:
        "Nous intégrons des solutions modernes et durables pour anticiper les besoins de nos clients.",
      iconLink: CityLocalIcon,
      iconColor: "black",
      mdIconColor: "white",
      textColor: "text-black md:text-white",
      dividerColor: "bg-black md:bg-white",
    },
    {
      name: "Suivi et sécurité",
      description:
        "Nous procurons à nos clients, un service personnalisé incluant des processus et formations conçus pour minimiser les risques.",
      iconLink: PlaneLocalIcon,
      iconColor: "black",
      mdIconColor: "black",
      textColor: "text-black",
      dividerColor: "bg-black",
    },
    {
      name: "Accompagnement",
      description:
        "Notre équipe est à l'écoute des besoins de chaque client, offrant des conseils d’experts et des solutions sur mesure.",
      iconLink: TruckLocalIcon,
      iconColor: "black",
      mdIconColor: "black",
      textColor: "text-black",
      dividerColor: "bg-black",
    },
  ];

  return (
    <div className="absolute -bottom-[110vh] md:-bottom-44 max-w-5xl shadow-xl rounded-3xl py-7 px-7 md:px-14 mx-5 md:mx-0 self-center bg-white bg-opacity-30 flex flex-col items-center justify-start box-border z-[2] text-left text-white font-urbanist">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-x-16 gap-y-10 ">
        {data.map((elem, index) => (
          <div key={index} className="flex flex-row space-x-5 md:space-x-10">
            <elem.iconLink
              color={elem.mdIconColor}
              className="hidden md:flex h-auto w-12 self-start"
            />
            <elem.iconLink
              color={elem.iconColor}
              className="flex md:hidden  h-auto w-24 self-start"
            />
            {/*            <img
              className="md:h-12 md:w-12 h-8 w-8 self-start "
              loading="eager"
              alt=""
              src={elem.iconLink}
            />*/}
            <div className="flex flex-row gap-4">
              <div
                className={clsx(elem.dividerColor, `h-full w-[0.5px]`)}
              ></div>
              <div
                className={clsx(
                  elem.textColor,
                  `flex flex-col gap-5 md:w-64  `,
                )}
              >
                <h1 className="text-xl m-0">{elem.name}</h1>
                <p className="text-sm  ">{elem.description}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PartnersFrameQuotidien;
