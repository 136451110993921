import { useNavigate } from "react-router-dom";
import { CiPhone } from "react-icons/ci";
import { BsEnvelope } from "react-icons/bs";

const ClientsSales = () => {
  const navigate = useNavigate();

  return (
    <div className=" bg-white flex flex-col py-10 gap-1">
      <div className="grid grid-cols-1 lg:grid-cols-3 lg:max-w-[75vw] shadow">
        {/* Contact information */}
        <div className="relative overflow-hidden bg-indigo-700 px-6 py-10 sm:px-10 xl:p-12">
          <div
            aria-hidden="true"
            className="pointer-events-none absolute inset-0 sm:hidden"
          >
            <svg
              fill="none"
              width={343}
              height={388}
              viewBox="0 0 343 388"
              preserveAspectRatio="xMidYMid slice"
              className="absolute inset-0 h-full w-full"
            >
              <path
                d="M-99 461.107L608.107-246l707.103 707.107-707.103 707.103L-99 461.107z"
                fill="url(#linear1)"
                fillOpacity=".1"
              />
              <defs>
                <linearGradient
                  id="linear1"
                  x1="254.553"
                  x2="961.66"
                  y1="107.554"
                  y2="814.66"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#fff" />
                  <stop offset={1} stopColor="#fff" stopOpacity={0} />
                </linearGradient>
              </defs>
            </svg>
          </div>
          <div
            aria-hidden="true"
            className="pointer-events-none absolute bottom-0 right-0 top-0 hidden w-1/2 sm:block lg:hidden"
          >
            <svg
              fill="none"
              width={359}
              height={339}
              viewBox="0 0 359 339"
              preserveAspectRatio="xMidYMid slice"
              className="absolute inset-0 h-full w-full"
            >
              <path
                d="M-161 382.107L546.107-325l707.103 707.107-707.103 707.103L-161 382.107z"
                fill="url(#linear2)"
                fillOpacity=".1"
              />
              <defs>
                <linearGradient
                  id="linear2"
                  x1="192.553"
                  x2="899.66"
                  y1="28.553"
                  y2="735.66"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#fff" />
                  <stop offset={1} stopColor="#fff" stopOpacity={0} />
                </linearGradient>
              </defs>
            </svg>
          </div>
          <div
            aria-hidden="true"
            className="pointer-events-none absolute bottom-0 right-0 top-0 hidden w-1/2 lg:block"
          >
            <svg
              fill="none"
              width={160}
              height={678}
              viewBox="0 0 160 678"
              preserveAspectRatio="xMidYMid slice"
              className="absolute inset-0 h-full w-full"
            >
              <path
                d="M-161 679.107L546.107-28l707.103 707.107-707.103 707.103L-161 679.107z"
                fill="url(#linear3)"
                fillOpacity=".1"
              />
              <defs>
                <linearGradient
                  id="linear3"
                  x1="192.553"
                  x2="899.66"
                  y1="325.553"
                  y2="1032.66"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#fff" />
                  <stop offset={1} stopColor="#fff" stopOpacity={0} />
                </linearGradient>
              </defs>
            </svg>
          </div>
          <h3 className="text-lg font-medium text-white">
            Contact information
          </h3>
          <p className="mt-6 max-w-3xl text-base text-indigo-50">
            Nullam risus blandit ac aliquam justo ipsum. Quam mauris volutpat
            massa dictumst amet. Sapien tortor lacus arcu.
          </p>
          <dl className="mt-8 space-y-6">
            <dt>
              <span className="sr-only">Phone number</span>
            </dt>
            <dd className="flex text-base text-indigo-50">
              <CiPhone
                aria-hidden="true"
                className="h-6 w-6 flex-shrink-0 text-indigo-200"
              />
              <span className="ml-3">+1 (555) 123-4567</span>
            </dd>
            <dt>
              <span className="sr-only">Email</span>
            </dt>
            <dd className="flex text-base text-indigo-50">
              <BsEnvelope
                aria-hidden="true"
                className="h-6 w-6 flex-shrink-0 text-indigo-200"
              />
              <span className="ml-3">support@workcation.com</span>
            </dd>
          </dl>
          <ul className="mt-8 flex space-x-12">
            <li>
              <a href="/" className="text-indigo-200 hover:text-indigo-100">
                <span className="sr-only">Facebook</span>
                <svg
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  aria-hidden="true"
                  className="h-6 w-6"
                >
                  <path
                    d="M20 10c0-5.523-4.477-10-10-10S0 4.477 0 10c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V10h2.54V7.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V10h2.773l-.443 2.89h-2.33v6.988C16.343 19.128 20 14.991 20 10z"
                    clipRule="evenodd"
                    fillRule="evenodd"
                  />
                </svg>
              </a>
            </li>
            <li>
              <a href="/" className="text-indigo-200 hover:text-indigo-100">
                <span className="sr-only">GitHub</span>
                <svg
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  aria-hidden="true"
                  className="h-6 w-6"
                >
                  <path
                    d="M10 0C4.477 0 0 4.484 0 10.017c0 4.425 2.865 8.18 6.839 9.504.5.092.682-.217.682-.483 0-.237-.008-.868-.013-1.703-2.782.605-3.369-1.343-3.369-1.343-.454-1.158-1.11-1.466-1.11-1.466-.908-.62.069-.608.069-.608 1.003.07 1.531 1.032 1.531 1.032.892 1.53 2.341 1.088 2.91.832.092-.647.35-1.088.636-1.338-2.22-.253-4.555-1.113-4.555-4.951 0-1.093.39-1.988 1.029-2.688-.103-.253-.446-1.272.098-2.65 0 0 .84-.27 2.75 1.026A9.564 9.564 0 0110 4.844c.85.004 1.705.115 2.504.337 1.909-1.296 2.747-1.027 2.747-1.027.546 1.379.203 2.398.1 2.651.64.7 1.028 1.595 1.028 2.688 0 3.848-2.339 4.695-4.566 4.942.359.31.678.921.678 1.856 0 1.338-.012 2.419-.012 2.747 0 .268.18.58.688.482A10.019 10.019 0 0020 10.017C20 4.484 15.522 0 10 0z"
                    clipRule="evenodd"
                    fillRule="evenodd"
                  />
                </svg>
              </a>
            </li>
            <li>
              <a href="/" className="text-indigo-200 hover:text-indigo-100">
                <span className="sr-only">X</span>
                <svg
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  aria-hidden="true"
                  className="h-6 w-6"
                >
                  <path d="M11.4678 8.77491L17.2961 2H15.915L10.8543 7.88256L6.81232 2H2.15039L8.26263 10.8955L2.15039 18H3.53159L8.87581 11.7878L13.1444 18H17.8063L11.4675 8.77491H11.4678ZM9.57608 10.9738L8.95678 10.0881L4.02925 3.03974H6.15068L10.1273 8.72795L10.7466 9.61374L15.9156 17.0075H13.7942L9.57608 10.9742V10.9738Z" />
                </svg>
              </a>
            </li>
          </ul>
        </div>

        {/* Contact form */}
        <div className="px-6 py-10 sm:px-10 lg:col-span-2 xl:p-12">
          <h3 className="text-lg font-medium text-gray-900">
            Send us a message
          </h3>
          <form
            action="#"
            method="POST"
            className="mt-6 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8"
          >
            <div>
              <label
                htmlFor="first-name"
                className="block text-sm font-medium text-gray-900"
              >
                First name
              </label>
              <div className="mt-1">
                <input
                  id="first-name"
                  name="first-name"
                  type="text"
                  autoComplete="given-name"
                  className="block w-full rounded-md border-gray-300 px-4 py-3 text-gray-900 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                />
              </div>
            </div>
            <div>
              <label
                htmlFor="last-name"
                className="block text-sm font-medium text-gray-900"
              >
                Last name
              </label>
              <div className="mt-1">
                <input
                  id="last-name"
                  name="last-name"
                  type="text"
                  autoComplete="family-name"
                  className="block w-full rounded-md border-gray-300 px-4 py-3 text-gray-900 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                />
              </div>
            </div>
            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium text-gray-900"
              >
                Email
              </label>
              <div className="mt-1">
                <input
                  id="email"
                  name="email"
                  type="email"
                  autoComplete="email"
                  className="block w-full rounded-md border-gray-300 px-4 py-3 text-gray-900 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                />
              </div>
            </div>
            <div>
              <div className="flex justify-between">
                <label
                  htmlFor="phone"
                  className="block text-sm font-medium text-gray-900"
                >
                  Phone
                </label>
                <span id="phone-optional" className="text-sm text-gray-500">
                  Optional
                </span>
              </div>
              <div className="mt-1">
                <input
                  id="phone"
                  name="phone"
                  type="text"
                  autoComplete="tel"
                  aria-describedby="phone-optional"
                  className="block w-full rounded-md border-gray-300 px-4 py-3 text-gray-900 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                />
              </div>
            </div>
            <div className="sm:col-span-2">
              <label
                htmlFor="subject"
                className="block text-sm font-medium text-gray-900"
              >
                Subject
              </label>
              <div className="mt-1">
                <input
                  id="subject"
                  name="subject"
                  type="text"
                  className="block w-full rounded-md border-gray-300 px-4 py-3 text-gray-900 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                />
              </div>
            </div>
            <div className="sm:col-span-2">
              <div className="flex justify-between">
                <label
                  htmlFor="message"
                  className="block text-sm font-medium text-gray-900"
                >
                  Message
                </label>
                <span id="message-max" className="text-sm text-gray-500">
                  Max. 500 characters
                </span>
              </div>
              <div className="mt-1">
                <textarea
                  id="message"
                  name="message"
                  rows={4}
                  aria-describedby="message-max"
                  className="block w-full rounded-md border-gray-300 px-4 py-3 text-gray-900 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                  defaultValue={""}
                />
              </div>
            </div>
            <div className="sm:col-span-2 sm:flex sm:justify-end">
              <button
                type="submit"
                className="mt-2 inline-flex w-full items-center justify-center rounded-md border border-transparent bg-indigo-600 px-6 py-3 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
    /*    <div className="w-full relative bg-white  flex flex-row items-start justify-start gap-[9px] tracking-[normal] text-left text-sm text-black font-poppins mq1325:flex-wrap">
      <section className="w-[1049px] flex flex-col items-start justify-start pt-[87px] px-0 pb-0 box-border max-w-full text-left text-9xl text-white font-poppins mq800:pt-[37px] mq800:box-border mq1125:pt-[57px] mq1125:box-border">
        <div className="self-stretch flex flex-col items-start justify-start pt-[78px] px-0 pb-0 box-border relative gap-[22px] max-w-full mq450:pt-[33px] mq450:box-border mq1125:pt-[51px] mq1125:box-border">
          <img
            role={"presentation"}
            className="w-[34px] h-[34px] absolute my-0 mx-[!important] top-[0px] left-[22px] overflow-hidden shrink-0 cursor-pointer"
            loading="eager"
            alt=""
            src="/icroundarrowback.svg"
            onClick={onIcroundArrowBackIconClick}
          />
          <div className="self-stretch flex flex-row items-start justify-start pt-0 px-0 pb-11 box-border max-w-full mq800:pb-[29px] mq800:box-border">
            <div className="flex-1 rounded-3xs bg-white shadow-[0px_0px_60px_30px_rgba(0,_0,_0,_0.03)] flex flex-row items-start justify-start py-2.5 pr-11 pl-2 box-border gap-[43px] max-w-full mq800:gap-[21px] mq1125:flex-wrap mq1125:pr-[22px] mq1125:box-border">
              <div className="h-[667px] w-[1049px] relative rounded-3xs bg-white shadow-[0px_0px_60px_30px_rgba(0,_0,_0,_0.03)] hidden max-w-full" />
              <div className="w-[430.7px] overflow-hidden shrink-0 flex flex-col items-start justify-start min-w-[430.7px] max-w-full z-[1] mq800:min-w-full mq1125:flex-1">
                <div className="w-[491px] flex flex-row items-start justify-start relative max-w-[114%] shrink-0">
                  <div className="h-[269px] w-[269px] absolute my-0 mx-[!important] right-[-89px] bottom-[-86px] rounded-[50%] bg-royalblue z-[1]" />
                  <div className="flex-1 rounded-3xs flex flex-col items-start justify-start pt-10 pb-9 pr-[69px] pl-[39px] box-border gap-[84px] bg-cover bg-no-repeat bg-[top] max-w-full mq800:gap-[42px] mq800:pt-[26px] mq800:pr-[34px] mq800:pb-[23px] mq800:box-border">
                    <img
                      className="w-[491px] h-[647px] relative rounded-3xs hidden max-w-full"
                      alt=""
                      src="/rectangle-3841.svg"
                    />
                    <div className="w-[337px] flex flex-col items-start justify-start gap-[6px] max-w-full">
                      <h1 className="m-0 h-[42px] relative text-inherit font-semibold font-inherit inline-block max-w-full z-[1] mq450:text-3xl">
                        Information de contact
                      </h1>
                      <h3 className="m-0 self-stretch h-[54px] relative text-lg font-normal font-inherit text-silver-100 whitespace-pre-wrap inline-block z-[1]">
                        Ecrivez-nous pour démarrer une discussion en direct !
                      </h3>
                    </div>
                    <div className="self-stretch flex flex-col items-start justify-start gap-[50px] max-w-full text-base mq450:gap-[25px]">
                      <div className="flex flex-row items-start justify-start py-0 pr-px pl-0 gap-[24px]">
                        <img
                          className="h-6 w-6 relative overflow-hidden shrink-0 min-h-[24px] z-[1]"
                          loading="eager"
                          alt=""
                          src="/bxsphonecall.svg"
                        />
                        <div className="h-6 relative inline-block whitespace-nowrap z-[1]">
                          +229 97 34 56 78
                        </div>
                      </div>
                      <div className="self-stretch flex flex-col items-start justify-start gap-[16px] max-w-full">
                        <div className="w-[337px] flex flex-col items-start justify-start gap-[50px] max-w-full mq450:gap-[25px]">
                          <div className="flex flex-row items-start justify-start gap-[24px]">
                            <img
                              className="h-6 w-6 relative overflow-hidden shrink-0 min-h-[24px] z-[1]"
                              loading="eager"
                              alt=""
                              src="/icsharpemail.svg"
                            />
                            <div className="relative whitespace-nowrap z-[1]">
                              secretariat@straca.org
                            </div>
                          </div>
                          <div className="self-stretch flex flex-row items-start justify-start py-0 pr-px pl-0 gap-[24px] mq450:flex-wrap">
                            <img
                              className="h-6 w-6 relative overflow-hidden shrink-0 z-[1]"
                              loading="eager"
                              alt=""
                              src="/carbonlocationfilled.svg"
                            />
                            <div className="h-12 flex-1 relative inline-block min-w-[187px] z-[1]">
                              Quartier Avakpa, BP 613, Porto-Novo, Bénin
                            </div>
                          </div>
                        </div>
                        <div className="self-stretch h-[173px] flex flex-col items-start justify-start gap-[5px]">
                          <div className="self-stretch flex-1 flex flex-row items-start justify-end">
                            <div className="self-stretch w-[138px] relative rounded-[50%] bg-royalblue z-[2]" />
                          </div>
                          <div className="flex flex-row items-start justify-start py-0 pr-0.5 pl-0 gap-[23px]">
                            <img
                              className="h-[30px] w-[30px] relative min-h-[30px] z-[1]"
                              loading="eager"
                              alt=""
                              src="/group-1000001749.svg"
                            />
                            <div className="flex flex-row items-start justify-start relative">
                              <img
                                className="h-[30px] w-[30px] relative z-[1]"
                                alt=""
                                src="/group-1000001750.svg"
                              />
                              <img
                                className="h-6 w-6 absolute my-0 mx-[!important] right-[-7.8px] bottom-[-20px] overflow-hidden shrink-0 z-[2]"
                                loading="eager"
                                alt=""
                                src="/claritycursorhandclickline.svg"
                              />
                            </div>
                            <img
                              className="h-[30px] w-[30px] relative min-h-[30px] z-[1]"
                              loading="eager"
                              alt=""
                              src="/group-1000001751.svg"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex-1 flex flex-col items-start justify-start pt-[50px] px-0 pb-0 box-border min-w-[340px] max-w-full text-xs text-gray-100 mq450:min-w-full mq800:pt-8 mq800:box-border">
                <div className="self-stretch flex flex-col items-end justify-start gap-[45px] max-w-full mq800:gap-[22px]">
                  <div className="self-stretch flex flex-col items-start justify-start gap-[11px]">
                    <div className="w-[305px] flex flex-col items-end justify-start gap-[4px]">
                      <div className="self-stretch flex flex-row items-start justify-between gap-[20px]">
                        <div className="relative leading-[20px] font-medium z-[2]">
                          Prénom
                        </div>
                        <div className="relative leading-[20px] font-medium text-black z-[2]">
                          Nom
                        </div>
                      </div>
                      <div className="self-stretch flex flex-row items-end justify-between py-0 pr-0 pl-1.5 gap-[20px] text-sm">
                        <img
                          className="h-[11.5px] w-px relative z-[2]"
                          loading="eager"
                          alt=""
                          src="/vector-8.svg"
                        />
                        <div className="relative leading-[20px] font-medium z-[2]">
                          Doe
                        </div>
                      </div>
                    </div>
                    <div className="self-stretch h-0 flex flex-col items-start justify-start">
                      <img
                        className="w-[243.6px] h-px relative z-[2]"
                        loading="eager"
                        alt=""
                        src="/vector-4.svg"
                      />
                      <div className="self-stretch h-0 flex flex-row items-start justify-end">
                        <img
                          className="h-px w-[243.6px] relative z-[2]"
                          loading="eager"
                          alt=""
                          src="/vector-5.svg"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="self-stretch flex flex-row items-start justify-start py-0 pr-0 pl-0.5 gap-[33px] text-black mq450:flex-wrap mq800:gap-[16px]">
                    <div className="flex-1 flex flex-col items-start justify-start gap-[15px] min-w-[159px]">
                      <input
                        className="w-[29.8px] [border:none] [outline:none] font-medium font-poppins text-xs bg-[transparent] h-10 relative leading-[20px] text-gray-100 text-left inline-block z-[2]"
                        placeholder="Email"
                        type="text"
                      />
                      <img
                        className="self-stretch h-px relative max-w-full overflow-hidden shrink-0 z-[2]"
                        alt=""
                        src="/vector-4.svg"
                      />
                    </div>
                    <div className="flex-1 flex flex-col items-start justify-start gap-[10px] min-w-[159px]">
                      <div className="w-[167px] flex flex-col items-start justify-start gap-[5px]">
                        <div className="w-[151px] relative leading-[20px] font-medium inline-block z-[2]">{`Numéro de téléphone `}</div>
                        <input
                          className="w-full [border:none] [outline:none] font-medium font-poppins text-sm bg-[transparent] self-stretch h-5 relative leading-[20px] text-gray-500 text-left inline-block min-w-[100px] whitespace-nowrap z-[2]"
                          placeholder="+229 97 34 56 78"
                          type="text"
                        />
                      </div>
                      <img
                        className="self-stretch h-px relative max-w-full overflow-hidden shrink-0 z-[2]"
                        alt=""
                        src="/vector-7.svg"
                      />
                    </div>
                  </div>
                  <div className="self-stretch flex flex-col items-start justify-start py-0 pr-0 pl-0.5 box-border gap-[14px] max-w-full text-sm text-gray-500">
                    <div className="w-[178px] relative leading-[20px] font-semibold inline-block z-[2]">
                      Sélectionnez un sujet
                    </div>
                    <div className="self-stretch flex flex-col items-start justify-start gap-[10px] max-w-full text-xs">
                      <div className="w-[456px] flex flex-col items-start justify-start gap-[5px] max-w-full shrink-0">
                        <div className="self-stretch flex flex-col items-start justify-start gap-[45px] mq450:gap-[22px]">
                          <div className="self-stretch flex flex-row items-start justify-start py-0 pr-px pl-0 gap-[17px] mq450:flex-wrap">
                            <div className="flex-1 flex flex-row items-center justify-start py-0 pr-px pl-0 box-border gap-[8px] min-w-[142px]">
                              <img
                                className="h-[13.2px] w-[11.4px] relative overflow-hidden shrink-0 z-[2]"
                                loading="eager"
                                alt=""
                                src="/teenyiconstickcirclesolid.svg"
                              />
                              <div className="flex-1 flex flex-row items-center justify-start">
                                <div className="flex-1 relative leading-[20px] z-[2]">
                                  Renseignements généraux
                                </div>
                                <img
                                  className="h-[13.2px] w-[11.4px] relative overflow-hidden shrink-0 z-[3]"
                                  loading="eager"
                                  alt=""
                                  src="/teenyiconstickcirclesolid-1.svg"
                                />
                              </div>
                              <div className="w-[80.6px] relative leading-[20px] inline-block shrink-0 z-[2]">{`Partenariat `}</div>
                            </div>
                            <div className="h-5 w-[101px] flex flex-row items-center justify-start gap-[9px]">
                              <img
                                className="h-[13.2px] w-[11.4px] relative overflow-hidden shrink-0 z-[2]"
                                loading="eager"
                                alt=""
                                src="/teenyiconstickcirclesolid-2.svg"
                              />
                              <div className="flex-1 relative leading-[20px] z-[2]">{`Partenariat `}</div>
                            </div>
                            <div className="h-5 w-[101px] flex flex-row items-center justify-start py-0 pr-px pl-0 box-border gap-[8px]">
                              <img
                                className="h-[13.2px] w-[11.4px] relative overflow-hidden shrink-0 z-[2]"
                                loading="eager"
                                alt=""
                                src="/teenyiconstickcirclesolid-3.svg"
                              />
                              <div className="flex-1 relative leading-[20px] z-[2]">{`Partenariat `}</div>
                            </div>
                          </div>
                          <div className="relative leading-[20px] font-medium text-gray-100 z-[2]">
                            Message
                          </div>
                        </div>
                        <div className="w-[170px] relative text-sm leading-[20px] font-medium text-gray-100 inline-block z-[2]">
                          Écrivez votre message ...
                        </div>
                      </div>
                      <img
                        className="self-stretch relative max-w-full overflow-hidden max-h-full shrink-0 z-[2]"
                        alt=""
                        src="/whatsapp-chat.svg"
                      />
                    </div>
                  </div>
                  <button className="cursor-pointer [border:none] p-[15px] bg-main-color w-[233px] rounded-8xs shadow-[0px_0px_14px_rgba(0,_0,_0,_0.12)] flex flex-row items-center justify-center box-border whitespace-nowrap z-[2] hover:bg-steelblue-100">
                    <div className="relative text-base font-medium font-poppins text-white text-center">
                      Envoyez un message
                    </div>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-row items-start justify-start py-0 px-3.5 box-border max-w-full text-center text-base text-black">
            <div className="relative font-medium">
              Ecrivez nous directement sur whathsapp
            </div>
          </div>
          <div className="w-[255px] flex flex-row items-start justify-start py-0 px-[11px] box-border">
            <button className="cursor-pointer [border:none] p-[15px] bg-main-color flex-1 rounded-8xs shadow-[0px_0px_14px_rgba(0,_0,_0,_0.12)] flex flex-row items-center justify-center hover:bg-steelblue-100">
              <div
                role={"presentation"}
                className="h-6 relative text-base font-medium font-poppins text-white text-center inline-block cursor-pointer"
                onClick={onDiscutonsTextClick}
              >
                Discutons
              </div>
            </button>
          </div>
        </div>
      </section>
    </div>*/
  );
};

export default ClientsSales;
