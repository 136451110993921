import React, { useRef } from "react";

const CustomUploadFile = ({
  handleChangeFile,
  handleChangeFileInfo,
  handleSelectedFile,
}) => {
  const hiddenFileInput = useRef(null);

  const handleClick = () => {
    hiddenFileInput.current.click();
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (!file) return;

    const fileExtension = file.name.split(".").pop();
    const reader = new FileReader();
    reader.readAsText(file);

    reader.onload = (e) => {
      const content = e.target.result;
      //setSelectedFile(file);

      console.log(`File Content: ${content}`);
      console.log(`File Name: ${file.name}`);
      console.log(`File Extension: ${fileExtension}`);

      if (fileExtension === "docx") {
        console.log("====> File is .docx <====");
        // Handle .docx file
      } else {
        handleChangeFile(content);
        handleChangeFileInfo(file);
      }
      handleSelectedFile(file);
    };

    reader.onerror = () => {
      console.log("File error:", reader.error);
    };
  };

  return (
    <div className="ml-0">
      <button
        type="button"
        onClick={handleClick}
        className="ml-2 px-4 py-2 font-semibold text-[#004EEB] bg-[#2970ff6a] border border-gray-200 rounded-md"
      >
        Choose File
      </button>

      <input
        type="file"
        name="myImage"
        ref={hiddenFileInput}
        style={{ display: "none" }}
        onChange={handleFileChange}
      />
    </div>
  );
};

export default CustomUploadFile;
