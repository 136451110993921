import React from "react";
import { Outlet } from "react-router-dom";
import { Header } from "../../components/Header";
import Footer from "../../components/Footer";
import { CultureTabsComponent } from "../../components/culture/CultureTabsComponent";

const CultureMainPage = () => {
  return (
    <section className="w-screen  bg-white  flex flex-col overflow-hidden">
      <div className="px-5 md:px-20 py-5">
        <Header
          logo="/straca_benin/straca_benin.svg"
          textColor="text-black"
          buttonColor="bg-main-color"
          btnTextColor="text-white"
          decorationColor="decoration-black"
        />
      </div>
      <CultureTabsComponent />
      <Outlet />
      <Footer />
    </section>
  );
};

export default CultureMainPage;
