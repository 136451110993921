export default function PlaneLocalIcon({ props, className, color = "white" }) {
  return (
    <svg
      width="77"
      height="66"
      viewBox="0 0 77 66"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      className={className}
    >
      <path
        d="M17.8042 55.4346H53.6738"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M34.8335 40.4766L50.3555 31.1317"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.1958 37.8877L16.211 43.9029"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.5 35.7139L7.51521 41.7291"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.2682 35.4434L25.7257 44.5155C26.9868 45.7266 28.5957 46.4883 30.3132 46.6874C32.0308 46.8865 33.7652 46.5124 35.2585 45.6206L73.2391 23.0045L68.573 17.1449C67.3593 15.6262 65.6553 14.6023 63.769 14.2582C61.8827 13.9141 59.9385 14.2726 58.2877 15.2688L47.1494 21.9765L32.0976 16.8365L27.9584 18.6612C27.6487 18.7971 27.3774 19.0108 27.1696 19.2824C26.9617 19.554 26.8239 19.8749 26.7689 20.2154C26.7139 20.556 26.7435 20.9052 26.855 21.2309C26.9664 21.5567 27.1561 21.8484 27.4065 22.0793L35.108 29.1725L28.0838 33.2845L21.0597 30.2005L16.8452 32.0509C16.5386 32.1859 16.2696 32.397 16.0627 32.6652C15.8558 32.9335 15.7174 33.2503 15.6602 33.5871C15.6029 33.9239 15.6285 34.27 15.7346 34.5941C15.8408 34.9183 16.0242 35.2101 16.2682 35.4434V35.4434Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M69.4347 18.4783C63.4047 23.2144 57.4523 23.8096 53.1304 18.4783L56.5263 16.271C60.7464 13.5279 66.3659 14.4889 69.4347 18.4783Z"
        fill="#59AAD6"
        stroke={color}
        strokeWidth="2"
      />
    </svg>
  );
}
