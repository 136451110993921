import React, { useEffect, useState } from "react";
import { Header } from "../components/Header";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { NumericFormat } from "react-number-format";
import { useMutation } from "@tanstack/react-query";
import { loginClient, registerClient } from "../api";
import Swal from "sweetalert2";
import { LuLoader2 } from "react-icons/lu";
import { useAuth } from "../context/AuthContext";
import { useNavigate } from "react-router-dom";

const LoginClient = () => {
  const { login } = useAuth();
  const navigate = useNavigate();

  // Register
  const [rFirstName, setRFirstName] = useState("");
  const [rLastName, setRLastName] = useState("");
  const [rPhone, setRPhone] = useState("");
  const [rEmail, setREmail] = useState("");
  const [rPass, setRPass] = useState("");
  const [rConfPass, setRConfPass] = useState("");
  const [showRPass, setShowRPass] = useState(false);
  const [showRConfPass, setShowRConfPass] = useState(false);

  // Login
  const [lEmail, setLEmail] = useState("");
  const [lPass, setLPass] = useState("");
  const [showLPass, setShowLPass] = useState(false);

  const [errorsRegister, setErrorsRegister] = useState([]);

  const registerMutation = useMutation({
    mutationKey: ["registerClient"],
    mutationFn: (payload) => {
      return registerClient({ payload });
    },
    onSuccess: () => {
      setRLastName("");
      setRFirstName("");
      setRPhone("");
      setREmail("");
      setRPass("");
      setRConfPass("");
      setErrorsRegister([]);
      Swal.fire({
        title: "Succès",
        text: `La STRACA vous contactera par mail afin de finaliser votre votre inscription`,
        icon: "success",
      });
    },
    onError: () => {
      Swal.fire({
        title: "Erreur",
        text: `Une erreur est survenue`,
        icon: "error",
      });
    },
  });

  const loginMutation = useMutation({
    mutationKey: ["loginClient"],
    mutationFn: (payload) => {
      return loginClient({ payload });
    },
    onSuccess: (response) => {
      setLEmail("");
      setLPass("");

      const userData = { ...response };

      login(userData);

      navigate("/client/dashboard/orders");
    },
    onError: () => {
      Swal.fire({
        title: "Erreur",
        text: `Une erreur est survenue`,
        icon: "error",
      });
    },
  });

  useEffect(() => {
    if (rPass.length > 0 && rConfPass.length > 0) {
      if (rPass !== rConfPass)
        setErrorsRegister((prevState) => [
          ...prevState.filter(
            (item) => item !== "Les mot de passe ne sont pas identiques",
          ),
          "Les mot de passe ne sont pas identiques",
        ]);
      else
        setErrorsRegister((prevState) =>
          prevState.filter(
            (item) => item !== "Les mot de passe ne sont pas identiques",
          ),
        );
    }
  }, [rConfPass, rPass]);

  return (
    <section className="w-screen  bg-white  flex flex-col overflow-hidden font-poppins">
      <div className="px-5 md:px-20 py-5">
        <Header
          logo="/straca_benin/straca_benin.svg"
          textColor="text-black"
          buttonColor="bg-main-color"
          btnTextColor="text-white"
          decorationColor="decoration-black"
        />
      </div>
      <section className="w-full bg-white flex flex-col items-center lg:flex-row p-10 lg:p-20 gap-16">
        <form className="w-full lg:w-1/2 h-fit flex flex-col items-center bg-[#FAFBFF] py-10 px-5 lg:px-20 gap-10">
          <span className="text-main-orange text-3xl font-medium">
            Inscription
          </span>
          <div className="w-full relative">
            <label
              htmlFor="client-register-lastname"
              className="absolute -top-2 left-2 inline-block bg-white px-2 text-xs font-medium text-gray-500"
            >
              Nom
            </label>
            <input
              id="client-register-lastname"
              name="client-register-lastname"
              type="text"
              autoComplete="on"
              placeholder="Entrez votre nom"
              className="block w-full rounded-lg border-0 py-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-main-orange sm:text-sm sm:leading-6"
              value={rLastName}
              onChange={(e) => setRLastName(e.target.value)}
            />
          </div>
          <div className="w-full relative">
            <label
              htmlFor="client-register-firstname"
              className="absolute -top-2 left-2 inline-block bg-white px-2 text-xs font-medium text-gray-500"
            >
              Prénom(s)
            </label>
            <input
              id="client-register-firstname"
              name="client-register-firstname"
              type="text"
              autoComplete="on"
              placeholder="Entrez votre/vos prénom(s)"
              className="block w-full rounded-lg border-0 py-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-main-orange sm:text-sm sm:leading-6"
              value={rFirstName}
              onChange={(e) => setRFirstName(e.target.value)}
            />
          </div>
          <div className="w-full relative">
            <label
              htmlFor="client-register-phone"
              className="absolute -top-2 left-2 inline-block bg-white px-2 text-xs font-medium text-gray-500"
            >
              Numéro de téléphone
            </label>
            <NumericFormat
              id="client-register-phone"
              name="client-register-phone"
              allowNegative={false}
              autoComplete="on"
              placeholder={"Entrez votre numéro de téléphone"}
              className="block w-full rounded-lg border-0 py-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-main-orange sm:text-sm sm:leading-6"
              value={rPhone}
              onChange={(e) => setRPhone(e.target.value)}
            />
          </div>
          <div className="w-full relative">
            <label
              htmlFor="client-register-email"
              className="absolute -top-2 left-2 inline-block bg-white px-2 text-xs font-medium text-gray-500"
            >
              Email
            </label>
            <input
              id="client-register-email"
              name="client-register-email"
              type="email"
              autoComplete="on"
              placeholder="Entrez votre email"
              className="block w-full rounded-lg border-0 py-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-main-orange sm:text-sm sm:leading-6"
              value={rEmail}
              onChange={(e) => setREmail(e.target.value)}
            />
          </div>
          <div className="w-full relative">
            <label
              htmlFor="client-register-password"
              className="absolute -top-2 left-2 inline-block bg-white px-2 text-xs font-medium text-gray-500"
            >
              Mot de passe
            </label>
            <input
              id="client-register-password"
              name="client-register-password"
              type={showRPass ? "text" : "password"}
              autoComplete="on"
              placeholder="Crée un mot de passe"
              className="block w-full rounded-lg border-0 py-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-main-orange sm:text-sm sm:leading-6"
              value={rPass}
              onChange={(e) => setRPass(e.target.value)}
            />
            <button
              type="button"
              onClick={() => {
                setShowRPass((prevState) => !prevState);
              }}
              className="absolute inset-y-0 right-0 flex items-center pr-3"
            >
              {showRPass ? (
                <AiFillEye
                  aria-hidden="true"
                  className="h-5 w-5 text-gray-400"
                />
              ) : (
                <AiFillEyeInvisible
                  aria-hidden="true"
                  className="h-5 w-5 text-gray-400"
                />
              )}
            </button>
          </div>

          <div className="w-full relative">
            <label
              htmlFor="client-register-confirm-password"
              className="absolute -top-2 left-2 inline-block bg-white px-2 text-xs font-medium text-gray-500"
            >
              Confirmation du mot de passe
            </label>
            <input
              id="client-register-confirm-password"
              name="client-register-confirm-password"
              type={showRConfPass ? "text" : "password"}
              autoComplete="on"
              placeholder="Confirmer votre mot de passe"
              className="block w-full rounded-lg border-0 py-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-main-orange sm:text-sm sm:leading-6"
              value={rConfPass}
              onChange={(e) => setRConfPass(e.target.value)}
            />
            <button
              type="button"
              onClick={() => {
                setShowRConfPass((prevState) => !prevState);
              }}
              className="absolute inset-y-0 right-0 flex items-center pr-3"
            >
              {showRConfPass ? (
                <AiFillEye
                  aria-hidden="true"
                  className="h-5 w-5 text-gray-400"
                />
              ) : (
                <AiFillEyeInvisible
                  aria-hidden="true"
                  className="h-5 w-5 text-gray-400"
                />
              )}
            </button>
          </div>
          <div className="w-full flex flex-col  items-start gap-2">
            {errorsRegister.map((error, index) => (
              <div
                key={`error-register-${index}`}
                className="flex flex-row items-center gap-2"
              >
                <div className="bg-red-500 w-1 h-1 rounded-full" />
                <span className="text-sm  text-red-500">{error}</span>
              </div>
            ))}
          </div>
          <button
            type="button"
            disabled={registerMutation.isLoading || errorsRegister.length > 0}
            onClick={(e) => {
              e.preventDefault();
              let payload = {};

              if (rLastName.length > 0) payload.lastName = rLastName;
              if (rFirstName.length > 0) payload.firstName = rFirstName;
              if (rEmail.length > 0) payload.email = rEmail;
              if (rPhone.length > 0) payload.phone = rPhone;
              if (rPass.length > 0) payload.password = rPass;

              if (Object.keys(payload).length > 0) {
                registerMutation.mutate(payload);
              }
            }}
            className="w-56 px-5 py-2 text-white bg-main-orange rounded-xl hover:bg-opacity-80 flex justify-center"
          >
            {registerMutation.isPending ? (
              <LuLoader2
                aria-hidden="true"
                className="-mr-0.5 h-5 w-5 animate-spin self-center"
              />
            ) : (
              "Envoyez une demande d’inscription"
            )}
          </button>
        </form>
        <form className="w-full lg:w-1/2 h-fit flex flex-col items-center bg-[#FAFBFF] py-10 px-5 lg:px-20 gap-16">
          <span className="text-main-orange text-3xl font-medium">
            Connexion
          </span>
          <div className="w-full relative">
            <label
              htmlFor="client-login-email"
              className="absolute -top-2 left-2 inline-block bg-white px-2 text-xs font-medium text-gray-500"
            >
              Email
            </label>
            <input
              id="client-login-email"
              name="client-login-email"
              type="email"
              autoComplete="on"
              placeholder="Entrez votre email"
              className="block w-full rounded-lg border-0 py-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-main-orange sm:text-sm sm:leading-6"
              value={lEmail}
              onChange={(e) => setLEmail(e.target.value)}
            />
          </div>
          <div className="w-full relative">
            <label
              htmlFor="client-login-password"
              className="absolute -top-2 left-2 inline-block bg-white px-2 text-xs font-medium text-gray-500"
            >
              Mot de passe
            </label>
            <input
              id="client-login-password"
              name="client-login-password"
              type={showLPass ? "text" : "password"}
              autoComplete="on"
              placeholder="Crée un mot de passe"
              className="block w-full rounded-lg border-0 py-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-main-orange sm:text-sm sm:leading-6"
              value={lPass}
              onChange={(e) => setLPass(e.target.value)}
            />
            <button
              type={"button"}
              onClick={() => {
                setShowLPass((prevState) => !prevState);
              }}
              className="absolute inset-y-0 right-0 flex items-center pr-3"
            >
              {showLPass ? (
                <AiFillEye
                  aria-hidden="true"
                  className="h-5 w-5 text-gray-400"
                />
              ) : (
                <AiFillEyeInvisible
                  aria-hidden="true"
                  className="h-5 w-5 text-gray-400"
                />
              )}
            </button>
          </div>
          <button
            type="button"
            disabled={loginMutation.isLoading}
            onClick={(e) => {
              e.preventDefault();
              let payload = {};

              if (lEmail.length > 0) payload.email = lEmail;
              if (lPass.length > 0) payload.password = lPass;

              if (Object.keys(payload).length > 0) {
                loginMutation.mutate(payload);
              }
            }}
            className="w-56 p-4 text-main-orange bg-white border border-main-orange rounded-xl hover:bg-opacity-80"
          >
            {loginMutation.isPending ? (
              <LuLoader2
                aria-hidden="true"
                className="-mr-0.5 h-5 w-5 animate-spin self-center"
              />
            ) : (
              "Connexion"
            )}
          </button>
        </form>
      </section>
    </section>
  );
};

export default LoginClient;
