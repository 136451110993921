import React, { useState } from "react";
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./MySlick.css";
import { dataDigitalBestSeller } from "./data";
import {
  IoArrowBackCircleOutline,
  IoArrowForwardCircleOutline,
} from "react-icons/io5";

const imgGirl =
  "https://images.unsplash.com/photo-1567789884554-0b844b597180?q=80&w=1740&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D";

function MySlick() {
  const [defaultImage, setDefaultImage] = useState({});

  function SampleNextArrow(props) {
    const { onClick } = props;
    return (
      <div className="absolute z-[1] top-0 right-0 h-96 w-16 md:w-32 bg-gradient-to-r from-[#D6D4D4]/25   via-[#D6D4D4]/90  to-[#D6D4D4]/25  flex items-center justify-center">
        <IoArrowForwardCircleOutline
          className="h-8 w-8 md:h-10 md:w-10 text-black "
          onClick={onClick}
        />
      </div>
    );
  }

  function SamplePrevArrow(props) {
    const { onClick } = props;
    return (
      <div className="absolute z-[1] h-96 w-16 md:w-32 bg-gradient-to-r from-[#D6D4D4]/25   via-[#D6D4D4]/90  to-[#D6D4D4]/25 flex items-center justify-center">
        <IoArrowBackCircleOutline
          className="h-8 w-8 md:h-10 md:w-10 text-black "
          onClick={onClick}
        />
      </div>
    );
  }

  const settings = {
    className: " h-96 text-white",
    dots: false,
    useTransform: true,
    infinite: false,
    currentSlide: 0,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 1,

    initialSlide: 0,
    prevArrow: <SamplePrevArrow />,
    nextArrow: <SampleNextArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const handleErrorImage = (data) => {
    setDefaultImage((prev) => ({
      ...prev,
      [data.target.alt]: data.target.alt,
      linkDefault: imgGirl,
    }));
  };

  return (
    <div className="slider-container">
      <Slider {...settings}>
        {dataDigitalBestSeller.map((item) => (
          <div key={item.id} id={`${item.id}`} className="h-96">
            <img
              className="w-full h-full object-cover"
              src={
                defaultImage[item.title] === item.title
                  ? defaultImage.linkDefault
                  : item.linkImg
              }
              alt={item.title}
              onError={handleErrorImage}
            />
          </div>
        ))}
      </Slider>
    </div>
  );
}

export default MySlick;
