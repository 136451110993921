import { useCallback } from "react";
import { useNavigate } from "react-router-dom";

const EspaceClient2 = () => {
  const navigate = useNavigate();

  const onListMenuContainerClick = useCallback(() => {
    navigate("/espace-client3");
  }, [navigate]);

  const onIcroundArrowBackIconClick = useCallback(() => {
    navigate("/espace-client2");
  }, [navigate]);

  return (
    <div className="w-full relative bg-white overflow-hidden flex flex-col items-end justify-start pt-0 px-0 pb-12 box-border tracking-[normal] text-left text-7xl text-black font-poppins">
      <div className="w-[329px] h-full my-0 mx-[!important] absolute top-[0px] bottom-[0px] left-[0px] bg-ghostwhite-100 flex flex-col items-center justify-start pt-9 pb-[106px] pr-[51px] pl-7 box-border gap-[36px] max-w-full">
        <div className="w-[329px] h-[1198px] relative bg-ghostwhite-100 hidden max-w-full" />
        <div className="h-[67px] flex flex-row items-start justify-start py-0 pr-5 pl-[38px] box-border">
          <h2 className="m-0 h-[39px] relative text-inherit tracking-[0.01em] font-semibold font-inherit inline-block z-[1] mq450:text-2xl">
            Espace client
          </h2>
        </div>
        <div className="self-stretch rounded-lg bg-main-color flex flex-row items-start justify-start py-[11px] px-2.5 gap-[14px] z-[1]">
          <div className="h-[46px] w-[250px] relative rounded-lg bg-main-color hidden" />
          <img
            className="h-6 w-6 relative overflow-hidden shrink-0 min-h-[24px] z-[1]"
            alt=""
            src="/icon--24--outline--keysquare.svg"
          />
          <input
            className="w-[calc(100%_-_44px)] [border:none] [outline:none] font-medium font-poppins text-sm bg-[transparent] h-[21px] flex-1 relative tracking-[-0.01em] text-white text-left inline-block min-w-[115px] z-[1]"
            placeholder="Historique des commandes"
            type="text"
          />
        </div>
        <div className="flex-1 flex flex-col items-start justify-start py-0 pr-2.5 pl-[9px] gap-[36px] text-sm text-lightslategray-200">
          <div
            role={"presentation"}
            className="flex flex-row items-end justify-start py-0 pr-px pl-0 gap-[13px] cursor-pointer z-[1]"
            onClick={onListMenuContainerClick}
          >
            <img
              className="h-6 w-6 relative overflow-hidden shrink-0 min-h-[24px]"
              loading="eager"
              alt=""
              src="/walletmoney-2.svg"
            />
            <div className="flex flex-row items-center justify-start py-0 pr-px pl-0 gap-[28px]">
              <div className="h-[21px] relative tracking-[-0.01em] font-medium inline-block">
                Sercice commerciale
              </div>
              <img
                className="h-4 w-4 relative overflow-hidden shrink-0"
                loading="eager"
                alt=""
                src="/chevronright-2.svg"
              />
            </div>
          </div>
          <div className="flex flex-row items-end justify-start gap-[14px]">
            <img
              className="h-6 w-6 relative overflow-hidden shrink-0 min-h-[24px] z-[1]"
              loading="eager"
              alt=""
              src="/usersquare-1.svg"
            />
            <div className="relative tracking-[-0.01em] font-medium z-[1]">
              Paramètre
            </div>
          </div>
        </div>
        <div className="self-stretch flex flex-row items-start justify-start py-0 px-2.5 text-base text-main-text-color font-inter">
          <div className="flex flex-row items-center justify-start gap-[16px]">
            <img
              className="h-6 w-6 relative overflow-hidden shrink-0 z-[1]"
              loading="eager"
              alt=""
              src="/iconslogout.svg"
            />
            <div className="relative font-medium z-[1]">Déconnexion</div>
          </div>
        </div>
      </div>
      <div className="w-1 h-12 relative bg-gainsboro-100 hidden z-[1]" />
      <div className="w-[1308px] h-[1150px] flex flex-row items-start justify-start py-0 pr-0 pl-5 box-border max-w-full mq1025:h-auto">
        <main className="h-[1197px] w-[1330px] overflow-y-auto shrink-0 flex flex-col items-center justify-start pt-[840px] pb-0 pr-[97px] pl-5 box-border relative gap-[14px] max-w-[103%] z-[3] text-left text-sm text-black font-poppins mq750:pt-[355px] mq750:pr-6 mq750:box-border mq1025:h-auto mq1125:pt-[546px] mq1125:pr-12 mq1125:box-border">
          <img
            className="w-[1.5px] h-[251px] absolute my-0 mx-[!important] bottom-[-155px] left-[650px] rounded-sm shrink-0 object-contain"
            loading="eager"
            alt=""
            src="/vector-6.svg"
          />
          <section className="w-[959px] flex flex-row items-end justify-between gap-[20px] max-w-full shrink-0 text-left text-mid-6 text-main-text-color font-poppins mq1025:flex-wrap">
            <div className="w-[389px] rounded-21xl bg-darkslateblue flex flex-row items-start justify-start pt-1.5 pb-[46px] pr-[15px] pl-1 box-border gap-[3px] min-w-[388px] max-w-full z-[2] mq750:min-w-full mq450:flex-wrap mq1025:flex-1">
              <div className="h-[262px] w-[388px] relative rounded-21xl bg-darkslateblue hidden max-w-full" />
              <div className="flex-1 rounded-[44.05px] bg-gray-1700 [backdrop-filter:blur(22.02px)] box-border flex flex-row items-start justify-center pt-0 px-0 pb-[19px] min-w-[123px] z-[3] border-[1.1px] border-solid border-gray-700">
                <div className="h-[201.2px] w-[191.2px] relative rounded-[44.05px] bg-gray-1700 [backdrop-filter:blur(22.02px)] box-border hidden border-[1.1px] border-solid border-gray-700" />
                <div className="flex-1 rounded-[44.05px] bg-whitesmoke-100 flex flex-col items-center justify-start pt-[33px] pb-[58px] pr-10 pl-[26px] gap-[11px] z-[1]">
                  <div className="w-[189.4px] relative rounded-[44.05px] bg-whitesmoke-100 h-[180.6px] hidden" />
                  <div className="self-stretch flex flex-row items-start justify-start py-0 pr-4 pl-0">
                    <div className="flex-1 flex flex-col items-start justify-start gap-[5px]">
                      <div className="flex flex-row items-start justify-start py-0 pr-0 pl-1">
                        <div className="h-[26px] relative inline-block z-[4]">
                          Conducteur
                        </div>
                      </div>
                      <div className="h-[21px] relative text-[14.3px] font-medium inline-block opacity-[0.6] z-[4]">
                        97728299
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-row items-start justify-start py-0 pr-0 pl-1">
                    <div className="h-[26px] relative [text-decoration:underline] font-semibold inline-block z-[4]">
                      AKAKPO BOB
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-[177px] flex flex-col items-start justify-start pt-2 pb-0 pr-px pl-0 box-border min-w-[177px] text-base mq450:flex-1 mq450:pr-0 mq450:box-border">
                <div className="self-stretch rounded-21xl bg-gray-1700 flex flex-col items-end justify-start pt-5 px-0 pb-[22px] gap-[12px] z-[1] border-[1px] border-solid border-dimgray-200">
                  <div className="self-stretch relative rounded-21xl bg-gray-1700 box-border h-[204px] hidden border-[1px] border-solid border-dimgray-200" />
                  <div className="self-stretch flex flex-row items-start justify-start py-0 px-[18px]">
                    <div className="flex flex-col items-start justify-start gap-[16px]">
                      <div className="relative leading-[150%] font-semibold z-[2]">
                        Addresse
                      </div>
                      <div className="flex flex-col items-start justify-start gap-[12px]">
                        <img
                          className="w-6 h-6 relative overflow-hidden shrink-0 z-[2]"
                          loading="eager"
                          alt=""
                          src="/materialsymbolslocationon.svg"
                        />
                        <div className="flex flex-row items-start justify-start relative">
                          <div className="relative leading-[150%] font-semibold z-[2]">
                            Livraison
                          </div>
                          <div className="h-12 w-[127px] absolute my-0 mx-[!important] top-[-40px] right-[-88px] leading-[150%] inline-block z-[2]">
                            Porto-Novo rue 229
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="w-[158px] flex flex-row items-start justify-start gap-[7px]">
                    <div className="flex flex-col items-start justify-start pt-[3px] px-0 pb-0">
                      <img
                        className="w-6 h-6 relative overflow-hidden shrink-0 z-[2]"
                        loading="eager"
                        alt=""
                        src="/materialsymbolscalendarmonthoutlinerounded.svg"
                      />
                    </div>
                    <div className="h-12 flex-1 relative leading-[150%] inline-block z-[2]">
                      <p className="m-0">{`12:30 `}</p>
                      <p className="m-0">31 Juillet 2023</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="w-[482px] flex flex-col items-start justify-start pt-0 px-0 pb-[9px] box-border min-w-[482px] max-w-full text-3xl text-c-black-20 font-urbanist mq750:min-w-full mq1025:flex-1">
              <div className="self-stretch flex flex-col items-start justify-start gap-[48px] mq750:gap-[24px]">
                <h3 className="m-0 self-stretch h-[252px] relative text-inherit leading-[131%] font-medium font-inherit inline-block shrink-0 z-[2] mq450:text-lg mq450:leading-[23px]">
                  {
                    "Pour les émissions de CO2 que nous ne pouvons pas encore éliminer complètement, nous compensons notre empreinte carbone en investissant dans des projets de compensation carbone certifiés. Ces projets soutiennent des initiatives telles que la reforestation, les énergies renouvelables et l'efficacité énergétique, contribuant ainsi à la neutralité carbone globale."
                  }
                </h3>
                <div className="w-[315px] flex flex-row items-start justify-start py-0 px-4 box-border text-sm text-black font-poppins">
                  <div className="flex-1 flex flex-row items-start justify-start gap-[8px] mq450:flex-wrap">
                    <img
                      className="h-5 w-5 relative z-[1]"
                      alt=""
                      src="/group-8.svg"
                    />
                    <div className="flex-1 flex flex-col items-start justify-start gap-[9px] min-w-[166px]">
                      <div className="self-stretch relative leading-[150%] font-semibold">
                        Entrepôt Bazounkpa
                      </div>
                      <div className="relative text-xs leading-[150%] uppercase font-medium opacity-[0.8]">
                        Pahou, bénin
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <div className="w-[593px] h-32 shrink-0 flex flex-row items-start justify-end max-w-full">
            <div className="h-[118px] w-[289px] flex flex-row items-start justify-start gap-[2px]">
              <div className="flex flex-col items-center justify-start gap-[45px]">
                <img className="w-5 h-5 relative z-[1]" alt="" />
                <img className="w-8 h-8 relative z-[1]" alt="" />
              </div>
              <div className="self-stretch flex-1 flex flex-col items-start justify-start gap-[24px]">
                <div className="self-stretch flex-1 flex flex-col items-start justify-start gap-[8px]">
                  <div className="self-stretch flex-1 relative leading-[150%] font-semibold">
                    Etoile rouge
                  </div>
                  <div className="w-[99px] flex-1 relative text-xs leading-[150%] uppercase font-medium inline-block opacity-[0.8]">
                    Cotonou,Bénin
                  </div>
                </div>
                <div className="self-stretch flex-1 flex flex-col items-start justify-start gap-[8px]">
                  <div className="self-stretch flex-1 relative leading-[150%] font-semibold">
                    Payage
                  </div>
                  <div className="w-[31px] flex-1 relative text-xs leading-[150%] uppercase font-medium inline-block opacity-[0.8]">
                    Seme
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="w-[593px] h-[47px] shrink-0 flex flex-row items-start justify-end max-w-full">
            <div className="self-stretch w-[283px] flex flex-row items-start justify-start gap-[8px]">
              <img className="h-5 w-5 relative" alt="" />
              <div className="self-stretch flex-1 flex flex-col items-start justify-start gap-[8px]">
                <div className="self-stretch flex-1 relative leading-[150%] font-semibold">
                  Livraison
                </div>
                <div className="w-[133px] flex-1 relative text-xs leading-[150%] uppercase font-medium inline-block opacity-[0.8]">
                  Porto-Novo rue 229
                </div>
              </div>
            </div>
          </div>
          <section className="w-[1041px] h-[1033px] absolute my-0 mx-[!important] top-[0px] left-[0px] shrink-0 text-left text-sm text-main-text-color font-poppins">
            <div className="absolute top-[69.6px] left-[115.4px] w-[926px] h-[963px]">
              <img
                className="absolute top-[0px] left-[0px] rounded-21xl w-full h-full"
                alt=""
                src="/rectangle-11848.svg"
              />
              <div className="absolute top-[85.4px] left-[513.6px] rounded-21xl w-[388px] h-[584px] bg-[url('/public/rectangle-1@2x.png')] bg-cover bg-no-repeat bg-[top] z-[1]">
                <img
                  className="absolute top-[0px] left-[0px] rounded-21xl w-full h-full object-cover hidden"
                  alt=""
                  src="/rectangle-1@2x.png"
                />
                <img
                  className="absolute top-[506.6px] left-[235.4px] w-14 h-14 z-[1]"
                  loading="eager"
                  alt=""
                  src="/group-3.svg"
                />
                <div className="absolute top-[507.6px] left-[307.4px] w-14 h-14 z-[1]">
                  <div className="absolute top-[0px] left-[0px] rounded-[18px] bg-gray-700 w-full h-full opacity-[0.5]" />
                  <img
                    className="absolute top-[28px] left-[14px] rounded-sm w-7 h-[3px] z-[1]"
                    loading="eager"
                    alt=""
                    src="/vector-41.svg"
                  />
                </div>
                <img
                  className="absolute top-[173.1px] left-[127.7px] w-[54px] h-[54.9px] object-contain z-[1]"
                  loading="eager"
                  alt=""
                  src="/group-221@2x.png"
                />
                <img
                  className="absolute top-[121.6px] left-[115.9px] w-[232px] h-[232px] z-[2]"
                  alt=""
                  src="/group-37196.svg"
                />
              </div>
              <div className="absolute top-[462px] left-[32px] text-lg text-gray-700 z-[2]">{`Volkswagen `}</div>
              <div className="absolute top-[690.4px] left-[263.6px] w-[216px] h-[164.5px] z-[2] text-base">
                <div className="absolute top-[0px] left-[0px] rounded-13xl bg-aliceblue box-border w-full h-full opacity-[0.4] border-[1px] border-solid border-silver-200" />
                <div className="absolute top-[24px] left-[20px] text-29xl font-semibold flex items-center z-[1] mq450:text-10xl mq1025:text-19xl">
                  123
                </div>
                <div className="absolute top-[66px] left-[101px] font-semibold flex items-center z-[1]">
                  In
                </div>
                <div className="absolute top-[54px] left-[116px] font-semibold flex items-center z-[1]">
                  3
                </div>
                <div className="absolute top-[119px] left-[20px] text-sm text-black z-[1]">
                  Volume
                </div>
                <img
                  className="absolute top-[20px] left-[172px] w-6 h-6 overflow-hidden z-[1]"
                  loading="eager"
                  alt=""
                  src="/mdiweight.svg"
                />
              </div>
              <div className="absolute top-[690.4px] left-[31.6px] w-[216px] h-[164.5px] z-[2]">
                <div className="absolute top-[0px] left-[0px] rounded-13xl bg-aliceblue box-border w-full h-full opacity-[0.4] border-[1px] border-solid border-silver-200" />
                <div className="absolute top-[119px] left-[20px] text-black z-[3]">
                  Poids
                </div>
                <div className="absolute top-[24px] left-[20px] text-29xl font-semibold flex items-center z-[3] mq450:text-10xl mq1025:text-19xl">
                  101
                </div>
                <div className="absolute top-[66px] left-[93px] text-base font-semibold flex items-center z-[3]">
                  Kg
                </div>
                <img
                  className="absolute top-[16px] left-[164px] rounded-lg w-9 h-[37px] overflow-hidden z-[3]"
                  loading="eager"
                  alt=""
                  src="/uilparcel.svg"
                />
              </div>
              <div className="absolute top-[510px] left-[264px] w-[216px] h-[164.5px] z-[2]">
                <div className="absolute top-[0px] left-[0px] rounded-13xl bg-aliceblue box-border w-full h-full opacity-[0.4] border-[1px] border-solid border-silver-200" />
                <div className="absolute top-[119px] left-[20px] text-black z-[1]">
                  Arrivée dans environ
                </div>
                <div className="absolute top-[24px] left-[20px] text-29xl font-semibold flex items-center z-[1] mq450:text-10xl mq1025:text-19xl">
                  90
                </div>
                <div className="absolute top-[65.4px] left-[87.6px] text-base font-semibold flex items-center z-[1]">
                  Mins
                </div>
                <img
                  className="absolute top-[20px] left-[172px] w-6 h-6 overflow-hidden z-[1]"
                  loading="eager"
                  alt=""
                  src="/materialsymbolsnestclockfarsightanalogoutlinerounded.svg"
                />
              </div>
              <div className="absolute top-[509px] left-[32px] w-[216px] h-[164.5px] z-[2]">
                <div className="absolute top-[0px] left-[0px] rounded-13xl bg-aliceblue box-border w-full h-full opacity-[0.4] border-[1px] border-solid border-silver-200" />
                <div className="absolute top-[118.4px] left-[19.6px] text-black z-[1]">
                  Distance
                </div>
                <div className="absolute top-[24px] left-[20px] text-29xl font-semibold flex items-center z-[1] mq450:text-10xl mq1025:text-19xl">
                  51
                </div>
                <div className="absolute top-[65.4px] left-[74.6px] text-base font-semibold flex items-center z-[1]">
                  Km
                </div>
                <img
                  className="absolute top-[20px] left-[172px] w-6 h-6 overflow-hidden z-[1]"
                  loading="eager"
                  alt=""
                  src="/gameiconspathdistance.svg"
                />
              </div>
              <h1 className="m-0 absolute top-[719.4px] left-[508.6px] text-13xl font-normal font-urbanist text-black inline-block w-[441px] h-[41px] z-[2] mq450:text-lgi mq1025:text-7xl">
                Détails
              </h1>
            </div>
            <div className="absolute top-[0px] left-[0px] w-[699px] h-[624px]">
              <img
                className="absolute top-[332px] left-[28.8px] w-4 h-4 overflow-hidden z-[1]"
                alt=""
                src="/chevronright-2-1.svg"
              />
              <input
                className="m-0 absolute top-[318px] left-[30px] w-4 h-4 z-[2]"
                type="checkbox"
              />
              <img
                className="absolute top-[0px] left-[0px] w-full h-full object-contain z-[3]"
                alt=""
                src="/3dtruck-1@2x.png"
              />
              <img
                role={"presentation"}
                className="absolute top-[134px] left-[136px] w-[34px] h-[34px] overflow-hidden cursor-pointer z-[5]"
                loading="eager"
                alt=""
                src="/icroundarrowback.svg"
                onClick={onIcroundArrowBackIconClick}
              />
            </div>
          </section>
        </main>
      </div>
    </div>
  );
};

export default EspaceClient2;
