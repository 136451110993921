import { useCallback } from "react";
import { useNavigate } from "react-router-dom";

const MacBookPro14 = () => {
  const navigate = useNavigate();

  const onMacBookPro142Click = useCallback(() => {
    navigate("/espace-client2");
  }, [navigate]);

  return (
    <div
      role={"presentation"}
      className="w-full relative bg-white overflow-hidden flex flex-col items-center justify-start pt-[245px] pb-[378px] pr-[21px] pl-5 box-border gap-[24px] cursor-pointer text-left text-45xl text-black font-inter"
      onClick={onMacBookPro142Click}
    >
      <img
        className="w-64 h-[258px] relative overflow-hidden shrink-0"
        loading="eager"
        alt=""
        src="/logoswhatsappicon.svg"
      />
      <div className="h-[77px] relative inline-block">Discussion Wathsapp</div>
    </div>
  );
};

export default MacBookPro14;
