import TestMap from "../TestMap";
import React from "react";

const ConstructionContent = () => {
  return (
    <section className="h-[75vh]  md:h-[85vh] w-full flex flex-col items-center relative overflow-hidden font-urbanist bg-white">
      <h3 className="m-0 text-center text-4xl mb-5 md:mb-10 mt-5 md:mt-20">
        Nos chantiers
      </h3>
      <p className="max-w-4xl text-center text-sm md:text-base leading-relaxed px-5 md:px-0">
        {
          "STRACA BENIN SA est active sur de nombreux chantiers à travers l'Afrique de l'Ouest, notamment au Bénin, au Burkina Faso, à Lomé, et bientôt au Niger et en Côte d'Ivoire. Nous disposons de nombreux sites de service répartie stratégiquement pour répondre efficacement aux besoins de nos clients."
        }
      </p>
      <div className="absolute z-[2] w-full md:w-56 h-20 md:h-16 bg-white inset-x-0 top-[67.5vh] md:top-[80vh] shadow-md md:shadow-none">
        {" "}
      </div>
      <div className="hidden md:flex absolute z-[2] w-80 h-16 bg-white  top-[80vh] left-[78vw]">
        {" "}
      </div>
      <div className="z-[2] absolute left-[5vw]  top-[33vh] md:top-[45vh] flex flex-row max-w-[40%] md:max-w-[13%] gap-5 italic text-sm">
        <img
          className="h-8 w-8 md:h-10 md:w-10 object-contain"
          loading="eager"
          alt=""
          src="/component-15@2x.png"
        />
        <span className="text-xs md:text-sm">
          Cliquez sur la carte pour en savoir plus
        </span>
      </div>
      <div className="hidden md:flex z-[1] absolute h-[45vh] w-full top-[40vh] ">
        <TestMap center={[22, 20]} />
      </div>
      <div className="flex md:hidden z-[1] absolute h-[40vh] w-full top-[32vh] ">
        <TestMap center={[22, 18]} zoom={2.9} />
      </div>
      {/*<MapFooterComponent />*/}
    </section>
  );
};

export default ConstructionContent;
