// Timeline.js
import React, { useEffect, useState } from "react";
import { CircleAnimated } from "../timeline-components/AnimatedCircle";
import clsx from "clsx";

const animatedYearColor = `
  text-[#00508D]
  transition-colors
  duration-2000
`;

const animatedColorClass = `
  text-[#2e2e2e]
  transition-colors
  duration-2000
`;

const animatedRadioImg = `
  width
  0.5s
  ease-in-out
`;

const animatedVertDivider = `
  height
  1.5s
  ease-in-out
`;

const animatedCircle = `
  height
  width
  1.5s
  ease-in-out
`;

const Timeline = () => {
  const data = [
    {
      top: { type: "date", text: "1982-1991" },
      bottom: {
        type: "description",
        text: "Exercitation veinam consequat veinam veinam veinam veinam veinam veinam veinam veinam veinam veinam",
      },
    },
    {
      top: { type: "description", text: "Exercitation veinam consequat" },
      bottom: { type: "date", text: "1991-2000" },
    },
    {
      top: { type: "date", text: "2000-2004" },
      bottom: { type: "description", text: "Exercitation veinam consequat" },
    },
    {
      top: { type: "description", text: "Exercitation veinam consequat" },
      bottom: { type: "date", text: "2004-2011" },
    },
    {
      top: { type: "date", text: "2011-2018" },
      bottom: { type: "description", text: "Exercitation veinam consequat" },
    },
    {
      top: { type: "description", text: "Exercitation veinam consequat" },
      bottom: { type: "date", text: "2018-2021" },
    },
    {
      top: { type: "date", text: "2021-2024" },
      bottom: { type: "description", text: "Exercitation veinam consequat" },
    },
  ];
  const [step, setStep] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setStep((prevState) => prevState + 1);

      if (step === data.length + 1) setStep(0);
    }, 3000);

    return () => clearInterval(interval);
  }, [data.length, step]);

  return (
    <div className="flex flex-col font-urbanist p-8 md:p-20 bg-ghostwhite-200">
      <h3 className="text-3xl font-medium">Notre histoire</h3>

      <div className="mt-16 w-[90vw] md:w-full text-main-color overflow-x-scroll">
        <div className="flex flex-col">
          {/*Top Bottom*/}
          <div className="w-[200vw] md:w-full flex flex-row items-end">
            {data.map((topLine, index) =>
              topLine.top.type === "date" ? (
                <span
                  key={`top-line-date-${index}`}
                  className={`translate-x-[calc(100%/2.5)] md:translate-x-[calc(100%/2.3)] flex justify-center text-center text-xl font-extrabold ${
                    step < index + 1 ? "text-transparent" : animatedYearColor
                  }`}
                  style={{ width: `calc(100% * 1/${data.length + 1})` }}
                >
                  {topLine.top.text}
                </span>
              ) : (
                <div
                  key={`top-line-description-${index}`}
                  className="translate-x-[calc(100%/2.5)] md:translate-x-[calc(100%/2.3)] flex flex-col text-sm text-center items-center"
                  style={{ width: `calc(100% * 1/${data.length + 1})` }}
                >
                  <p
                    className={clsx(
                      step < index + 1
                        ? "text-transparent"
                        : animatedColorClass,
                      "py-2",
                    )}
                  >
                    {topLine.top.text}
                  </p>
                  <div
                    className="relative w-[1px] bg-[#d7d7d7]"
                    style={{
                      height: `${step < index + 1 ? "0" : "50"}px`,
                      transition: `${
                        step < index + 1 ? "" : animatedVertDivider
                      }`,
                    }}
                  >
                    <CircleAnimated
                      step={step}
                      minIndex={index + 1}
                      animation={animatedCircle}
                    />
                  </div>
                </div>
              ),
            )}
          </div>

          {/*Divider div*/}
          <div className="relative w-[200vw] md:w-full">
            <div
              className="relative h-[1px] mt-[15px] bg-main-color"
              style={{
                width:
                  step > 0
                    ? `calc((100% * ${step}/${data.length + 1}) - 10px)`
                    : 0,
                transition: "width 1s ease-in-out",
              }}
            ></div>
            <div className="absolute w-[200vw] md:w-full flex flex-row items-center text-sm right-0 top-0">
              {data.map((_, index) => (
                <div
                  key={index}
                  className="flex justify-end"
                  style={{
                    width: `calc(100% * 1/${data.length + 1})`,
                  }}
                >
                  <img
                    key={index}
                    className="h-[30px] w-4 my-0 mx-[!important] object-contain"
                    alt=""
                    src="/radio.svg"
                    style={{
                      width: `${step < index + 1 ? "0" : "20"}px`,
                      transition: `${step < 1 ? "" : animatedRadioImg}`,
                    }}
                  />
                </div>
              ))}
            </div>
          </div>

          {/*Line Bottom*/}
          <div className="w-[200vw] md:w-full flex flex-row  items-start">
            {data.map((bottomLine, index) =>
              bottomLine.bottom.type === "date" ? (
                <span
                  key={`bottom-line-date-${index}`}
                  className={`translate-x-[calc(100%/2.5)] md:translate-x-[calc(100%/2.3)] mt-4 flex justify-center text-center  text-xl font-extrabold ${
                    step < index + 1 ? "text-transparent" : animatedYearColor
                  }`}
                  style={{
                    width: `calc(100% * 1/${data.length + 1})`,
                  }}
                >
                  {bottomLine.bottom.text}
                </span>
              ) : (
                <div
                  key={`bottom-line-description-${index}`}
                  className="translate-x-[calc(100%/2.5)] md:translate-x-[calc(100%/2.3)] mt-4 flex flex-col text-sm text-center items-center"
                  style={{
                    width: `calc(100% * 1/${data.length + 1})`,
                  }}
                >
                  <div
                    className=" relative w-[1px] bg-[#d7d7d7]"
                    style={{
                      height: `${step < index + 1 ? "0" : "50"}px`,
                      transition: `${step < 1 ? "" : animatedVertDivider}`,
                    }}
                  >
                    <CircleAnimated
                      step={step}
                      minIndex={index + 1}
                      animation={animatedCircle}
                      rotated={true}
                    />
                  </div>
                  <p
                    className={clsx(
                      step < index + 1
                        ? "text-transparent"
                        : animatedColorClass,
                      "py-2",
                    )}
                  >
                    {bottomLine.bottom.text}
                  </p>
                </div>
              ),
            )}
          </div>
        </div>
      </div>

      {/*<div className="mt-16 w-[900px] text-main-color">
        <div className="flex flex-col">
          <div className="flex flex-row px-[90px] justify-between items-end">
            <text
              className={`text-xl font-extrabold ${
                step.current < 1 ? "text-transparent" : animatedYearColor
              }`}
            >
              1982-1999
            </text>
            <div className="ml-3 flex flex-col w-[120px] text-sm text-center items-center">
              <p
                className={
                  step.current < 2 ? "text-transparent" : animatedColorClass
                }
              >
                Exercitation veinam consequat
              </p>
              <div
                className="relative w-[1px] bg-[#d7d7d7]"
                style={{
                  height: `${step.current < 2 ? "0" : "50"}px`,
                  transition: `${step.current < 2 ? "" : animatedVertDivider}`,
                }}
              >
                 Make this round div reusable (passing the step.current and animated and int in place of the number 2 and pass animatedCircle)
                <AnimatedCircle
                  step={step}
                  minIndex={2}
                  animation={animatedCircle}
                />
              </div>
            </div>
            <text
              className={`text-xl font-bold ${
                step.current < 3 ? "text-transparent" : animatedYearColor
              }`}
            >
              2010-2015
            </text>
            <div className="ml-0 flex flex-col w-[120px] text-sm text-center items-center">
              <p
                className={
                  step.current < 4 ? "text-transparent" : animatedColorClass
                }
              >
                Ullamco est sit aliqua dolor
              </p>
              <div
                className="relative w-[1px] bg-[#d7d7d7]"
                style={{
                  height: `${step.current < 4 ? "0" : "50"}px`,
                  transition: `${step.current < 4 ? "" : animatedVertDivider}`,
                }}
              >
                <AnimatedCircle
                  step={step}
                  minIndex={4}
                  animation={animatedCircle}
                />
              </div>
            </div>
            <text
              className={`text-xl font-bold ${
                step.current < 5 ? "text-transparent" : animatedYearColor
              }`}
            >
              2020-2023
            </text>
          </div>
           Divider div
          <div className="relative">
            <div
              className="relative h-[1px] mt-[15px] bg-main-color"
              style={{
                width: `${width}px`,
                transition: "width 1s ease-in-out",
              }}
            ></div>
            <div className="absolute inseyt-0 w-full flex flex-row justify-evenly items-center text-sm right-0 top-0">
              {[...Array(5)].map((_, index) => (
                <div key={index} className="w-[20px]">
                  <img
                    key={index}
                    className="h-[30px] w-4 my-0 mx-[!important] object-contain"
                    alt=""
                    src="/radio.svg"
                    style={{
                      width: `${step.current < index + 1 ? "0" : "20"}px`,
                      transition: `${step.current < 1 ? "" : animatedRadioImg}`,
                    }}
                  />
                </div>
              ))}
            </div>
          </div>
           Line Bottom
          <div className="flex flex-row px-[65px] mt-3 justify-between items-start">
            <div className="ml-5 flex flex-col w-[120px] text-sm text-center items-center">
              <div
                className="relative w-[1px] bg-[#d7d7d7]"
                style={{
                  height: `${step.current < 1 ? "0" : "50"}px`,
                  transition: `${step.current < 1 ? "" : animatedVertDivider}`,
                }}
              >
                <AnimatedCircle
                  step={step}
                  minIndex={1}
                  animation={animatedCircle}
                  rotated={true}
                />
              </div>
              <p
                className={
                  step.current < 1 ? "text-transparent" : animatedColorClass
                }
              >
                Sunt nostrud amet sint do
              </p>
            </div>
            <text
              className={`text-xl font-bold ${
                step.current < 2 ? "text-transparent" : animatedYearColor
              }`}
            >
              1999 - 2010
            </text>
            <div className="ml-1 flex flex-col w-[120px] text-sm text-center items-center">
              <div
                className="relative w-[1px] bg-[#d7d7d7]"
                style={{
                  height: `${step.current < 3 ? "0" : "50"}px`,
                  transition: `${step.current < 3 ? "" : animatedVertDivider}`,
                }}
              >
                <AnimatedCircle
                  step={step}
                  minIndex={3}
                  animation={animatedCircle}
                  rotated={true}
                />
              </div>
              <p
                className={
                  step.current < 3 ? "text-transparent" : animatedColorClass
                }
              >
                Velit officia consequat duis
              </p>
            </div>
            <text
              className={`text-xl font-bold ${
                step.current < 4 ? "text-transparent" : animatedYearColor
              }`}
            >
              2015 - 2020
            </text>
            <div className="mr-4 flex flex-col w-[120px] text-sm text-center items-center">
              <div
                className="relative w-[1px] bg-[#d7d7d7]"
                style={{
                  height: `${step.current < 5 ? "0" : "50"}px`,
                  transition: `${step.current < 5 ? "" : animatedVertDivider}`,
                }}
              >
                <AnimatedCircle
                  step={step}
                  minIndex={5}
                  animation={animatedCircle}
                  rotated={true}
                />
              </div>
              <p
                className={
                  step.current < 5 ? "text-transparent" : animatedColorClass
                }
              >
                Amet minim mollit non deserunt
              </p>
            </div>
          </div>
        </div>
         {step.current}
      </div>*/}
    </div>
  );
};

export default Timeline;
