import { useNavigate } from "react-router-dom";

const ProductFrame = () => {
  const navigate = useNavigate();

  const data = [
    { icon: "/new/sector-1.svg", name: "STRACA- SA transport et logistique" },
    { icon: "/new/sector-2.svg", name: "STRACA OIL distribution" },
    { icon: "/new/sector-3.svg", name: "AWENI BTP construction" },
    { icon: "/new/sector-4.svg", name: "STRACA TRANSIT" },
  ];

  return (
    <section className="w-full max-w-full bg-seashell-200 font-urbanist">
      <div className="flex flex-col md:flex-row p-7 md:p-28 items-center">
        <div className="md:w-3/5 flex flex-col">
          <h1 className="m-0 text-4xl font-semibold">
            Nos secteurs d’activités
          </h1>
          <div className="mt-8 flex flex-col max-w-md">
            <p className="text-base">
              {
                "Nous proposons une gamme complète de services qui font de nous un partenaire de choix, capable de vous accompagner dans toutes les étapes de vos opérations."
              }
            </p>
            <ul className="m-0 marker:text-[10px]">
              {data.map((dt, index) => (
                <li key={`title-${index}`}>
                  <span className="ml-2">{dt.name}</span>
                </li>
              ))}
            </ul>
          </div>
          <button
            onClick={() => {
              navigate("/expeertise");
            }}
            className="mt-16 md:ml-12 w-fit text-base text-white bg-main-orange py-3 px-8 rounded-md cursor-pointer"
          >
            En savoir plus
          </button>
        </div>
        <div className="mt-16 md:mt-0 md:w-2/5 flex flex-col self-center">
          <div className="grid grid-cols-2 justify-items-center gap-5 max-w-sm">
            {data.map((dt, index) => (
              <div
                key={`icon-${index}`}
                className="bg-white rounded-full p-7 w-fit shadow-xl"
              >
                <img
                  className="h-12 w-12 md:h-20 md:w-20"
                  loading="eager"
                  alt=""
                  src={dt.icon}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default ProductFrame;
