import React from "react";

const AnimatedCircle = ({ step, minIndex, animation, rotated = false }) => {
  return (
    <div
      className={`absolute ${
        rotated ? "bottom-[-5px]" : "top-[-5px]"
      } left-[-2px] bg-[#d7d7d7] rounded-full`}
      style={{
        height: `${step.current < minIndex ? "0" : "5px"}`,
        width: `${step.current < minIndex ? "0" : "5px"}`,
        transform: `${step.current < minIndex ? "" : "scale(1)"}`, // Initial scale
        transition: `${step.current < minIndex ? "" : animation}`,
      }}
    ></div>
  );
};

export default AnimatedCircle;

export const CircleAnimated = ({
  step,
  minIndex,
  animation,
  rotated = false,
}) => {
  return (
    <div
      className={`absolute ${
        rotated ? "bottom-[-5px]" : "top-[-5px]"
      } left-[-2px] bg-[#d7d7d7] rounded-full`}
      style={{
        height: `${step < minIndex ? "0" : "5px"}`,
        width: `${step < minIndex ? "0" : "5px"}`,
        transform: `${step < minIndex ? "" : "scale(1)"}`, // Initial scale
        transition: `${step < minIndex ? "" : animation}`,
      }}
    ></div>
  );
};
