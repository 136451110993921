import React from "react";
import { Link, useLocation } from "react-router-dom";
import clsx from "clsx";

export const CultureTabsComponent = () => {
  const location = useLocation();
  const { pathname } = location;

  const tabs = [
    { label: "Actualité", route: "/culture/actualite" },
    { label: "Savoir-faire", route: "/culture/savoir-faire" },
    {
      label: "Certifications, honneur",
      route: "/culture/certification",
    },
    { label: "Engagement", route: "/culture/engagement" },
  ];

  return (
    <div className="my-20 mx-5 md:mx-0 w-[90vw] md:w-full  flex flex-row md:items-center md:justify-center font-poppins font-medium text-xl overflow-x-scroll ">
      {tabs.map((tab, index) => (
        <Link
          key={`tab-link-${index}`}
          to={tab.route}
          className="flex flex-col items-center gap-2 group text-nowrap "
        >
          <span
            className={clsx(
              index === 0
                ? "pr-2 md:pr-5"
                : index + 1 === tabs.length
                  ? "pl-2 md:pl-5"
                  : "px-2 md:px-5",
              "text-xs md:text-base ",
            )}
          >
            {tab.label}
          </span>
          <div
            className={clsx(
              tab.route === pathname ? "bg-main-orange" : "bg-gray-300",
              "h-0.5 w-full  group-hover:bg-main-orange",
            )}
          ></div>
        </Link>
      ))}
    </div>
  );
};
