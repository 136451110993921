import React from "react";

import "../SlidingBackground.css";
import Footer from "../components/Footer";
import HeroSection from "../components/HeroSection";
import PromisesComponent from "../components/stracaPage/PromisesComponent";
import Timeline from "../components/stracaPage/Timeline";
import VisionComponent from "../components/stracaPage/VisionComponent";
import TeamComponent from "../components/stracaPage/TeamComponent";

const Straca = () => {
  return (
    <div className="w-screen  bg-white  flex flex-col overflow-hidden">
      <HeroSection url={"/new/hero-img-1.png"}>
        <div className="rounded-xl bg-main-orange bg-opacity-65 mt-[25vh] md:mt-[50vh] mb-36 ml-10 md:ml-40 pl-10 py-10  pr-10 md:pr-40 w-fit  flex flex-row items-start justify-start text-left  text-white font-urbanist">
          <h1 className="relative  font-light  leading-snug text-2xl md:text-4xl ">
            <span className="font-medium">Ensemble</span>, <br />
            sur la route de nos <br />
            <span className="font-medium">accomplissements</span>
          </h1>
        </div>
      </HeroSection>
      <PromisesComponent />
      <Timeline />
      <VisionComponent />
      <TeamComponent />
      {/*      <section className="self-stretch flex flex-row items-start justify-start py-0 pr-0 pl-px box-border min-h-[1911px] max-w-full text-left text-21xl text-black font-urbanist">
        <div className="flex-1 flex flex-col items-center justify-start gap-[184px] max-w-full mq800:gap-[92px] mq450:gap-[46px]">
          <div className="flex-1 self-stretch bg-ghostwhite-200 flex flex-row items-start justify-start pt-[83px] pb-[58px] pr-[121px] pl-[219px] box-border gap-[82px] max-w-full z-[1] mq800:gap-[41px] mq800:pt-[54px] mq800:pb-[38px] mq800:pr-[60px] mq800:pl-[109px] mq800:box-border mq1325:flex-wrap mq450:gap-[20px] mq450:pl-5 mq450:pr-5 mq450:box-border">
             <div className="h-[536px] w-[1512px] relative bg-ghostwhite-200 hidden max-w-full" />
            <div className="flex flex-col items-start justify-start pt-[21px] px-0 pb-0 box-border mq800:min-w-full mq1325:flex-1">
              <div className="self-stretch flex flex-col items-start justify-start gap-[33px] mq450:gap-[16px]">
                <h1 className="m-0 h-12 relative text-inherit font-medium font-inherit inline-block z-[1] mq800:text-13xl mq450:text-5xl">
                  Notre vision
                </h1>
                <div className="self-stretch h-[230px] relative text-base flex items-center shrink-0 z-[1]">
                  <span>
                    <p className="m-0">{`Lörem ipsum mädos krohet. Popp jasminrevolution eller jeröt kovis vikol stenovöktig inte änegen. `}</p>
                    <p className="m-0">{`Åd intragisk. Gära nyv ålig trinat des divöde diling plus fanade. `}</p>
                    <p className="m-0">{`Aktiga diagt diligt eller ögärortad. Renade treskap reskap till egont. Du kan vara drabbad. `}</p>
                    <p className="m-0">{`Astror megangen dir hövis makrovis. Hösesamma intrar och stereosiv. `}</p>
                    <p className="m-0">{`Såde nynde suheng, ogt anaspende pånde spårtjuv. Decipor skynka för att readeplarar homorat: monoligt spev dining sedan paraspehörade att preling. `}</p>
                  </span>
                </div>
              </div>
            </div>
             This is second div
            <div className="h-1/2 w-screen flex-2 justify-center items-center rounded-lg">
              <div className="flex flex-row justify-start items-center gap-4 duration-5000">
                <img
                  src={visionImages[0]}
                  alt="first"
                  className={`${
                    hoveredImageIndex === 1 ? "w-1/3" : "w-1/6"
                  } object-fill h-[300px] rounded-lg transition-all duration-5000 ease-in-out`}
                  onMouseEnter={() => handleImageMouseEnter(0)}
                  onMouseLeave={handleImageMouseLeave}
                />
                <img
                  src={visionImages[0]}
                  alt="second"
                  className={`
              w-1/6
              ${hoveredImageIndex === 2 ? "hover:w-1/3" : ""}
              object-fill h-[300px] rounded-lg transition-all duration-5000 ease-in-out
            `}
                  onMouseEnter={() => handleImageMouseEnter(1)}
                  onMouseLeave={handleImageMouseLeave}
                />
                <img
                  src={visionImages[0]}
                  alt="third"
                  className={`
              w-1/6
              ${hoveredImageIndex === 3 ? "hover:w-1/3" : ""}
              object-fill h-[300px] rounded-lg transition-all duration-5000 ease-in-out
            `}
                  onMouseEnter={() => handleImageMouseEnter(2)}
                  onMouseLeave={handleImageMouseLeave}
                />
                 {hoveredImageIndex}
              </div>
            </div>
          </div>
          <div className="w-[1111px] h-[1028px] relative overflow-x-auto shrink-0 max-w-full text-center text-xl text-text-color font-montserrat">
            <div className="w-[309px] overflow-hidden flex flex-col items-start justify-start py-0 pr-[23px] pl-0 box-border text-left text-21xl">
              <div className="self-stretch overflow-hidden flex flex-col items-center justify-start gap-[10px]">
                <h1 className="m-0 relative text-inherit tracking-[0.2px] leading-[50px] font-bold font-inherit mq800:text-13xl mq800:leading-[40px] mq450:text-5xl mq450:leading-[30px]">
                  Notre équipe
                </h1>
                <div className="relative text-sm tracking-[0.2px] leading-[20px] text-grey-grey-45 text-center">{`Rencontrer notre équipe `}</div>
              </div>
            </div>
            <div className="absolute top-[128px] left-[20px] w-[238px] h-[423px] flex flex-col items-center justify-start text-left">
              <div className="self-stretch flex-1 rounded-8xs overflow-hidden flex flex-col items-start justify-start">
                <img
                  className="self-stretch flex-1 relative max-w-full overflow-hidden max-h-full object-cover"
                  loading="eager"
                  alt=""
                  src="/media@2x.png"
                />
                <div className="self-stretch overflow-hidden flex flex-col items-start justify-start py-[15px] px-0 gap-[10px]">
                  <div className="relative tracking-[0.2px] leading-[30px] mq450:text-base mq450:leading-[24px]">
                    Jerome Bell
                  </div>
                  <div className="relative text-sm tracking-[0.2px] leading-[20px] text-grey-grey-45">
                    IBM
                  </div>
                </div>
              </div>
            </div>
            <div className="absolute top-[128px] left-[296px] w-[241px] h-[426px] flex flex-col items-center justify-start">
              <div className="self-stretch flex-1 rounded-8xs overflow-hidden flex flex-col items-center justify-start">
                <img
                  className="self-stretch flex-1 relative max-w-full overflow-hidden max-h-full object-cover"
                  alt=""
                  src="/media-1@2x.png"
                />
                <div className="self-stretch overflow-hidden flex flex-col items-start justify-center py-[15px] pl-0.5 gap-[10px]">
                  <div className="relative tracking-[0.2px] leading-[30px] font-semibold mq450:text-base mq450:leading-[24px]">
                    Brooklyn Simmons
                  </div>
                  <div className="relative text-lg font-mulish text-black">
                    Sécrétaire générale
                  </div>
                </div>
              </div>
            </div>
            <div className="absolute top-[128px] left-[575px] w-60 h-[426px] flex flex-col items-center justify-start">
              <div className="self-stretch flex-1 rounded-8xs overflow-hidden flex flex-col items-center justify-start">
                <img
                  className="self-stretch flex-1 relative max-w-full overflow-hidden max-h-full object-cover"
                  alt=""
                  src="/media-2@2x.png"
                />
                <div className="self-stretch overflow-hidden flex flex-col items-start justify-start py-[15px] pr-[51px] pl-px gap-[10px]">
                  <div className="relative tracking-[0.2px] leading-[30px] font-semibold mq450:text-base mq450:leading-[24px]">
                    Ronald Richards
                  </div>
                  <div className="relative text-lg font-mulish text-black">
                    Directeur générale
                  </div>
                </div>
              </div>
            </div>
            <div className="absolute top-[128px] left-[853px] w-60 h-[423px] flex flex-col items-center justify-start">
              <div className="self-stretch flex-1 rounded-8xs overflow-hidden flex flex-col items-center justify-start">
                <img
                  className="self-stretch flex-1 relative max-w-full overflow-hidden max-h-full object-cover"
                  alt=""
                  src="/media-3@2x.png"
                />
                <div className="self-stretch overflow-hidden flex flex-col items-start justify-start py-[15px] pr-[51px] pl-px gap-[10px]">
                  <div className="relative tracking-[0.2px] leading-[30px] font-semibold mq450:text-base mq450:leading-[24px]">
                    Tikri Richard
                  </div>
                  <div className="relative text-base tracking-[0.2px] leading-[20px] font-mulish text-black">{`Directeur administratif `}</div>
                </div>
              </div>
            </div>
            <div className="absolute top-[602px] left-[20px] w-[238px] h-[423px] flex flex-col items-center justify-start text-left">
              <div className="self-stretch flex-1 rounded-8xs overflow-hidden flex flex-col items-start justify-start">
                <img
                  className="self-stretch flex-1 relative max-w-full overflow-hidden max-h-full object-cover"
                  alt=""
                  src="/media-4@2x.png"
                />
                <div className="self-stretch overflow-hidden flex flex-col items-start justify-start py-[15px] px-0 gap-[10px]">
                  <div className="relative tracking-[0.2px] leading-[30px] mq450:text-base mq450:leading-[24px]">
                    Jane Cooper
                  </div>
                  <div className="relative text-sm tracking-[0.2px] leading-[20px] text-grey-grey-45">
                    Mitsubishi
                  </div>
                </div>
              </div>
            </div>
            <div className="absolute top-[602px] left-[296px] w-[241px] h-[426px] flex flex-col items-center justify-start">
              <div className="self-stretch flex-1 rounded-8xs overflow-hidden flex flex-col items-center justify-start">
                <img
                  className="self-stretch flex-1 relative max-w-full overflow-hidden max-h-full object-cover"
                  alt=""
                  src="/media-5@2x.png"
                />
                <div className="self-stretch overflow-hidden flex flex-col items-start justify-start py-[15px] pr-[51px] pl-px gap-[10px]">
                  <div className="relative tracking-[0.2px] leading-[30px] mq450:text-base mq450:leading-[24px]">
                    Robert Fox
                  </div>
                  <div className="relative text-lg font-mulish text-black">
                    Directrice commerciale
                  </div>
                </div>
              </div>
            </div>
            <div className="absolute top-[602px] left-[575px] w-60 h-[426px] flex flex-col items-center justify-start">
              <div className="self-stretch flex-1 rounded-8xs overflow-hidden flex flex-col items-center justify-start">
                <img
                  className="self-stretch flex-1 relative max-w-full overflow-hidden max-h-full object-cover"
                  alt=""
                  src="/media-6@2x.png"
                />
                <div className="self-stretch overflow-hidden flex flex-col items-start justify-start py-[15px] pr-[51px] pl-px gap-[10px]">
                  <div className="relative tracking-[0.2px] leading-[30px] mq450:text-base mq450:leading-[24px]">
                    Leslie Alexander
                  </div>
                  <div className="relative text-lg font-mulish text-black">
                    Meilleur camionneur
                  </div>
                </div>
              </div>
            </div>
            <div className="absolute top-[602px] left-[853px] w-60 h-[423px] flex flex-col items-center justify-start">
              <div className="self-stretch flex-1 rounded-8xs overflow-hidden flex flex-col items-center justify-start">
                <img
                  className="self-stretch flex-1 relative max-w-full overflow-hidden max-h-full object-cover"
                  alt=""
                  src="/media-7@2x.png"
                />
                <div className="self-stretch overflow-hidden flex flex-col items-start justify-start py-[15px] pr-[51px] pl-px gap-[10px]">
                  <div className="relative tracking-[0.2px] leading-[30px] mq450:text-base mq450:leading-[24px]">
                    Jacob Jones
                  </div>
                  <div className="relative text-sm tracking-[0.2px] leading-[20px] text-grey-grey-45">
                    Starbucks
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>*/}
      <Footer />
    </div>
  );
};

export default Straca;
