import Footer from "../components/Footer";
import { Header } from "../components/Header";
import clsx from "clsx";
import {
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
} from "@headlessui/react";
import { useState } from "react";
import { FaCheck } from "react-icons/fa6";
import { TiArrowSortedDown } from "react-icons/ti";
import { FiPhoneCall } from "react-icons/fi";
import { LiaFaxSolid } from "react-icons/lia";
import { BsEnvelopePaper } from "react-icons/bs";

const PARTENAIRES = () => {
  const data = [
    {
      name: "Hafa",
      description:
        "Développement de maison et de locaux professionel de types entrprises",
      logo: "/new/partner-1.png",
      color: "bg-[#A2270B]",
    },
    {
      name: "CCE",
      description:
        "Développement de maison et de locaux professionel de types entrprises",
      logo: "/new/partner-7.png",
      color: "bg-main-color",
    },
    {
      name: "Sogea Satom",
      description:
        "Développement de maison et de locaux professionel de types entrprises",
      logo: "/new/partner-3.png",
      color: "bg-main-color",
    },
    {
      name: "Colas",
      description:
        "Développement de maison et de locaux professionel de types entrprises",
      logo: "/new/partner-4.png",
      color: "bg-main-color",
    },
  ];

  const secondData = [
    {
      name: "Aeolus",
      description:
        "Développement de maison et de locaux professionel de types entrprises",
      logo: "/new/partner-2.png",
      color: "bg-main-text-color",
    },
    {
      name: "Batimat",
      description:
        "Développement de maison et de locaux professionel de types entrprises",
      logo: "/new/partner-6.png",
      color: "bg-[#A2270B]",
    },
    {
      name: "Cim Benin",
      description:
        "Développement de maison et de locaux professionel de types entrprises",
      logo: "/new/partner-5.png",
      color: "bg-main-color",
    },
    {
      name: "Orca Deco",
      description:
        "Développement de maison et de locaux professionel de types entrprises",
      logo: "/new/partner-8.png",
      color: "bg-main-color",
    },
  ];

  const people = [
    { id: 1, name: "Ressources Humaines" },
    { id: 2, name: "Service client" },
    { id: 3, name: "Ressources Humaines" },
    { id: 4, name: "Ressources Humaines" },
    { id: 5, name: "Ressources Humaines" },
  ];

  const [selected, setSelected] = useState("");

  return (
    <section className="w-screen  bg-white  flex flex-col overflow-hidden">
      <div className="px-20 py-5">
        <Header
          logo="/straca_benin/straca_benin.svg"
          textColor="text-black"
          buttonColor="bg-main-color"
          btnTextColor="text-white"
          decorationColor="decoration-black"
        />
      </div>
      <h3 className="my-10 text-4xl self-center">Nos partenaires</h3>
      <div className="flex flex-col md:flex-row gap-10 self-center mt-10 mb-48">
        <div className="flex flex-col gap-5">
          {data.map((partner, index) => (
            <div
              key={`first-partner-${index}`}
              className="h-[60vh] w-[80vw] md:h-[50vh] md:w-[25vw] shadow-2xl relative flex flex-col"
            >
              <div className="h-[70%] bg-white flex flex-col items-center font-inter px-5 gap-5">
                <h3 className="mt-14 uppercase text-xl text-main-orange underline underline-offset-8 decoration-black">
                  {partner.name}
                </h3>
                <h3 className="uppercase text-4xl text-black font-medium ">
                  {partner.name}
                </h3>
                <p className="text-base text-main-color text-center">
                  {partner.description}
                </p>
              </div>
              <div className={clsx(partner.color, "h-[30%]")} />
              <div className="absolute bottom-[8vh] md:bottom-[1vh] left-[27vw] md:left-[7vw] bg-[#EEF2FA] rounded-full p-7 w-fit shadow-xl border-white border-4">
                <img
                  className="h-12 w-12 md:h-24 md:w-24"
                  loading="eager"
                  alt=""
                  src={partner.logo}
                />
              </div>
            </div>
          ))}
        </div>

        <div className="flex flex-col gap-5 translate-y-48">
          {secondData.map((partner, index) => (
            <div
              key={`second-partner-${index}`}
              className="h-[60vh] w-[80vw] md:h-[50vh] md:w-[25vw] shadow-2xl relative flex flex-col"
            >
              <div className="h-[70%] bg-white flex flex-col items-center font-inter px-5 gap-5">
                <h3 className="mt-14 uppercase text-xl text-main-orange underline underline-offset-8 decoration-black">
                  {partner.name}
                </h3>
                <h3 className="uppercase text-4xl text-black font-medium ">
                  {partner.name}
                </h3>
                <p className="text-base text-main-color text-center">
                  {partner.description}
                </p>
              </div>
              <div className={clsx(partner.color, "h-[30%]")} />
              <div className="absolute bottom-24 md:bottom-2.5 left-[27vw] md:left-[7vw] bg-[#EEF2FA] rounded-full p-7 w-fit shadow-xl border-white border-4">
                <img
                  className="h-12 w-12 md:h-24 md:w-24"
                  loading="eager"
                  alt=""
                  src={partner.logo}
                />
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="my-28 px-8 md:px-36 flex flex-col md:flex-row gap-16 items-center justify-center font-montserrat">
        <div className="w-full md:w-1/2 flex flex-col items-start gap-8">
          <h3 className="text-5xl font-semibold">Contactez-nous</h3>
          <p className="font-semibold text-base">
            {
              "Enim tempor eget pharetra facilisis sed maecenas adipiscing. Eu leo molestie vel, ornare non id blandit netus."
            }
          </p>
          <div className="flex flex-col w-full gap-5">
            <div className="flex rounded-xl shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 ">
              <label
                htmlFor="contact-user-name"
                className="flex select-none items-center pl-3 text-gray-500 sm:text-sm"
              >
                Nom <span className="ml-1 text-red-500">*</span>
              </label>
              <input
                id="contact-user-name"
                name="contact-user-name"
                type="text"
                placeholder=""
                className="block flex-1 border-0 bg-transparent py-2.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 text-sm leading-6"
              />
            </div>
            <div className="flex rounded-xl shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 ">
              <label
                htmlFor="contact-user-email"
                className="flex select-none items-center pl-3 text-gray-500 sm:text-sm"
              >
                Email <span className="ml-1 text-red-500">*</span>
              </label>
              <input
                id="contact-user-email"
                name="contact-user-email"
                type="email"
                placeholder=""
                className="block flex-1 border-0 bg-transparent py-2.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 text-sm leading-6"
              />
            </div>
            <div className="flex rounded-xl shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 ">
              <label
                htmlFor="contact-user-number"
                className="flex select-none items-center pl-3 text-gray-500 sm:text-sm"
              >
                Numéro <span className="ml-1 text-red-500">*</span>
              </label>
              <input
                id="contact-user-number"
                name="contact-user-number"
                type="text"
                placeholder=""
                className="block flex-1 border-0 bg-transparent py-2.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 text-sm leading-6"
              />
            </div>
            <Listbox value={selected} onChange={setSelected}>
              <div className="relative">
                <ListboxButton className="relative w-full cursor-default rounded-xl bg-white py-2.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6">
                  <span className="block truncate">
                    {selected.length > 0
                      ? selected.name
                      : "Veuillez sélectionner une catégorie"}
                  </span>
                  <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                    <TiArrowSortedDown
                      aria-hidden="true"
                      className="h-5 w-5 text-black"
                    />
                  </span>
                </ListboxButton>

                <ListboxOptions
                  transition
                  className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none data-[closed]:data-[leave]:opacity-0 data-[leave]:transition data-[leave]:duration-100 data-[leave]:ease-in sm:text-sm"
                >
                  {people.map((person) => (
                    <ListboxOption
                      key={person.id}
                      value={person}
                      className="group relative cursor-default select-none py-2 pl-3 pr-9 text-gray-900 data-[focus]:bg-indigo-600 data-[focus]:text-white"
                    >
                      <span className="block truncate font-normal group-data-[selected]:font-semibold">
                        {person.name}
                      </span>

                      <span className="absolute inset-y-0 right-0 flex items-center pr-4 text-indigo-600 group-data-[focus]:text-white [.group:not([data-selected])_&]:hidden">
                        <FaCheck aria-hidden="true" className="h-5 w-5" />
                      </span>
                    </ListboxOption>
                  ))}
                </ListboxOptions>
              </div>
            </Listbox>
            <button
              type="submit"
              className="bg-real-main-color text-white flex justify-center p-2.5 rounded-xl"
            >
              Envoyer
            </button>
          </div>
          <div className="flex flex-col md:flex-row font-montserrat gap-10">
            <div className="flex flex-row items-center gap-3 ">
              <FiPhoneCall className="h-7 w-7" />
              <div className="flex flex-col text-sm items-start">
                <span className="uppercase font-semibold">Téléphone</span>
                <span className="text-gray-400">229 00 00 00 00</span>
              </div>
            </div>
            <div className="flex flex-row items-center gap-3 ">
              <LiaFaxSolid className="h-7 w-7" />
              <div className="flex flex-col text-sm items-start">
                <span className="uppercase font-semibold">Fax</span>
                <span className="text-gray-400">00 00 00 00 00</span>
              </div>
            </div>
            <div className="flex flex-row items-center gap-3 ">
              <BsEnvelopePaper className="h-7 w-7" />
              <div className="flex flex-col text-sm items-start">
                <span className="uppercase font-semibold">Email</span>
                <span className="text-gray-400">info@email.com</span>
              </div>
            </div>
          </div>
        </div>
        <div className="w-full md:w-1/2 flex flex-col items-start self-center">
          <textarea
            id="comment"
            name="comment"
            rows={8}
            className="block w-full rounded-xl border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            placeholder={"Veuillez entrer votre message"}
            defaultValue={""}
          />
        </div>
      </div>

      <Footer />
    </section>
  );
};

export default PARTENAIRES;
