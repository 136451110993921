import React, { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { useLocation } from "react-router-dom";
import { BiHistory, BiLogOut, BiX } from "react-icons/bi";
import { FaBars, FaRegComments } from "react-icons/fa6";
import clsx from "clsx";
import { IoChevronForward } from "react-icons/io5";
import { useAuth } from "../context/AuthContext";

const navigation = [
  {
    name: "Historique des commandes",
    href: "/client/dashboard/orders",
    icon: BiHistory,
    current: true,
  },
  {
    name: "Service commercial",
    href: "/client/dashboard/sales",
    icon: FaRegComments,
    current: false,
  },
];

const Sidebar = () => {
  let { pathname: pathName } = useLocation();
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const { logout } = useAuth();

  return (
    <div className="font-poppins">
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-50 lg:hidden"
          onClose={setSidebarOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-900/80" />
          </Transition.Child>

          <div className="fixed inset-0 flex">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute left-full top-0 flex w-fit justify-center pt-5">
                    <button
                      type="button"
                      className="-m-2.5 p-2.5"
                      onClick={() => setSidebarOpen(false)}
                    >
                      <span className="sr-only">Close sidebar</span>
                      <BiX className="h-6 w-6 text-white" aria-hidden="true" />
                    </button>
                  </div>
                </Transition.Child>
                <div className="h-full flex grow flex-col gap-y-5 overflow-y-auto bg-[#FAFBFF]  pb-4">
                  <span className="flex items-center justify-center my-10 text-2xl font-medium">
                    Espace client
                  </span>
                  <nav className="flex flex-1 w-full flex-col">
                    <ul className="flex flex-1 h-full w-full flex-col  px-6 gap-y-5 ">
                      {navigation.map((item) => (
                        <li key={item.name}>
                          <div className="-mx-2 space-y-1">
                            <div key={item.name}>
                              <a
                                href={item.href}
                                className={clsx(
                                  item.href.endsWith(pathName)
                                    ? "bg-real-main-color text-white"
                                    : "text-gray-500 hover:text-white hover:bg-real-main-color",
                                  "group   rounded-md p-2 text-sm leading-6 font-semibold flex justify-between items-center",
                                )}
                              >
                                <div className="flex flex-row gap-4 items-center break-keep text-nowrap">
                                  <item.icon
                                    className={clsx(
                                      item.href.endsWith(pathName)
                                        ? "text-white"
                                        : "text-gray-500 group-hover:text-white",
                                      "h-6 w-6 shrink-0",
                                    )}
                                    aria-hidden="true"
                                  />
                                  <span>{item.name}</span>
                                </div>
                                <IoChevronForward
                                  className={clsx(
                                    item.href.endsWith(pathName)
                                      ? "text-white"
                                      : "text-gray-500 group-hover:text-white",
                                    "h-5 w-5 shrink-0",
                                  )}
                                />
                              </a>
                            </div>
                          </div>
                        </li>
                      ))}

                      <li className="w-full mt-auto mb-10 ">
                        <button
                          onClick={() => {
                            logout();
                          }}
                          className={clsx(
                            "text-black hover:text-white hover:bg-real-main-color",
                            "m-auto w-full group   rounded-md p-2 text-sm leading-6 font-semibold flex justify-between items-center",
                          )}
                        >
                          <div className="flex flex-row gap-4 items-center break-keep text-nowrap">
                            <BiLogOut
                              className={clsx(
                                "text-black group-hover:text-white",
                                "h-6 w-6 shrink-0",
                              )}
                              aria-hidden="true"
                            />
                            <span>{"Déconnexion"}</span>
                          </div>
                        </button>
                      </li>
                    </ul>
                  </nav>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>

      <div className="flex flex-row justify-between p-3 lg:hidden ">
        <button
          type="button"
          className="text-gray-700 "
          onClick={() => setSidebarOpen(true)}
        >
          <span className="sr-only">Open sidebar</span>
          <FaBars className="h-6 w-6" aria-hidden="true" />
        </button>
      </div>

      {/* Static sidebar for desktop */}
      <div className="hidden h-screen lg:inset-y-0 lg:z-50 lg:flex lg:w-fit lg:flex-col overflow-auto">
        <div className="h-full flex grow flex-col gap-y-5 overflow-y-auto bg-[#FAFBFF]  pb-4">
          <span className=" flex items-center justify-center my-10 text-2xl font-medium">
            Espace client
          </span>

          <nav className="flex flex-1 flex-col w-full">
            <ul className="flex flex-col px-5 gap-y-5 w-full h-full">
              {navigation.map((item) => (
                <li key={item.name} className="">
                  <div className="space-y-1 w-full">
                    <div key={item.name}>
                      <a
                        href={item.href}
                        className={clsx(
                          item.href.endsWith(pathName)
                            ? "bg-real-main-color text-white"
                            : "text-gray-500 hover:text-white hover:bg-real-main-color",
                          "flex flex-row items-center group justify-between rounded-md px-2 py-3 text-sm leading-6 font-medium",
                        )}
                      >
                        <div className="flex flex-row gap-4 items-center break-keep text-nowrap">
                          <item.icon
                            className={clsx(
                              item.href.endsWith(pathName)
                                ? "text-white"
                                : "text-gray-500 group-hover:text-white",
                              "h-6 w-6 shrink-0",
                            )}
                            aria-hidden="true"
                          />
                          <span>{item.name}</span>
                        </div>
                        <IoChevronForward
                          className={clsx(
                            item.href.endsWith(pathName)
                              ? "text-white"
                              : "text-gray-500 group-hover:text-white",
                            "h-5 w-5 shrink-0",
                          )}
                        />
                      </a>
                    </div>
                  </div>
                </li>
              ))}
              <li className="w-full mt-auto mb-10 ">
                <button
                  onClick={() => {
                    logout();
                  }}
                  className={clsx(
                    "text-black hover:text-white hover:bg-real-main-color",
                    "m-auto w-full group   rounded-md p-2 text-sm leading-6 font-semibold flex justify-between items-center",
                  )}
                >
                  <div className="flex flex-row gap-4 items-center break-keep text-nowrap">
                    <BiLogOut
                      className={clsx(
                        "text-black group-hover:text-white",
                        "h-6 w-6 shrink-0",
                      )}
                      aria-hidden="true"
                    />
                    <span>{"Déconnexion"}</span>
                  </div>
                </button>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
