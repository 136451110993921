import Footer from "../components/Footer";
import { Header } from "../components/Header";
import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { getBlogBySlug } from "../api";
import React, { useEffect, useState } from "react";

import "quill/dist/quill.core.css";
import "../quillContent.css";
import { format } from "date-fns";
import { fr } from "date-fns/locale";
import { FaRegCircleUser } from "react-icons/fa6";

const Blog = () => {
  let { slug } = useParams();

  const { isPending, error, data, isSuccess } = useQuery({
    queryKey: ["getBlogBySlug"],
    queryFn: () => getBlogBySlug(slug),
  });

  const [blog, setBlog] = useState(null);

  useEffect(() => {
    if (isSuccess) {
      //console.log(data);
      setBlog(data.blog);
    }
  }, [data, isSuccess]);

  return (
    <div className="w-screen  bg-white  flex flex-col overflow-hidden font-urbanist">
      <div className="px-20 py-5">
        <Header
          logo="/straca_benin/straca_benin.svg"
          textColor="text-black"
          buttonColor="bg-main-color"
          btnTextColor="text-white"
          decorationColor="decoration-black"
        />
      </div>
      <div className="w-full flex flex-col">
        {isPending || error !== null || blog === null ? (
          <div className="h-[50vh] w-full bg-white" />
        ) : (
          <div className="min-h-[50vh] w-full bg-white px-7 md:px-48 my-32 flex flex-col items-center gap-20">
            <div className="flex flex-col items-center">
              <span className="text-4xl font-medium">
                {blog.category.label}
              </span>
              <span className="text-lg font-light">
                {blog.category.description}
              </span>
            </div>
            <span className="font-patrick-hand text-xl text-black text-opacity-60">
              {format(blog.updatedAt, "dd MMMM yyyy", {
                locale: fr,
              }).toUpperCase()}
            </span>
            <span className="text-4xl text-main-orange font-medium font-inter text-center max-w-3xl">
              {blog?.title ?? ""}
            </span>
            <div className="flex flex-row items-center gap-3 font-roboto">
              <FaRegCircleUser className="h-12 w-12" />
              <div className="flex flex-col">
                <span className="text-base font-medium">{`${
                  blog?.createdBy?.firstName ?? "-"
                } ${blog?.createdBy?.lastName ?? "-"}`}</span>
                <span className="text-sm text-black text-opacity-60">
                  Direction commerciale
                </span>
              </div>
            </div>
            <img
              className=" h-[70vh] w-screen rounded-md overflow-hidden  object-cover"
              loading="eager"
              alt=""
              src={blog.image}
            />

            <div className="ql-container ql-snow">
              <div
                className="ql-editor"
                dangerouslySetInnerHTML={{ __html: blog?.blogBody ?? "" }}
              />
            </div>
          </div>
        )}
      </div>
      <Footer />
    </div>
  );
};

export default Blog;
