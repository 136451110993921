import React from 'react';

const MyTextField = ({ title, hintText, value, onChange }) => {
    return (
        <div className="w-screen rounded-smi box-border flex flex-row items-center justify-start pt-4 px-3.5 pb-[13px] relative max-w-full z-[1] border-[1px] border-solid border-dimgray-100">
            <div className="h-[53px] w-screen relative rounded-smi box-border hidden max-w-full z-[0] border-[1px] border-solid border-dimgray-100" />
            <input
                className="w-[182px] [border:none] [outline:none] font-light font-poppins text-base bg-[transparent] h-[22px] relative leading-[22px] text-dimgray-400 text-left flex items-center z-[2]"
                placeholder={hintText}
                type="text"
                value={value}
                onChange={(val) => {onChange(val.target.value)}}
                
            />
            <div className="bg-white px-3 py-2 absolute rounded-lg my-0 mx-[!important] top-[-20px] left-[11px] text-base leading-[22px] font-medium font-poppins text-dimgray-100 text-left flex items-center [text-shadow:8px_0_0_#fff,_0_8px_0_#fff,_-8px_0_0_#fff,_0_-8px_0_#fff] z-[3]">
                {title}
            </div>
        </div>
    );
};

export default MyTextField;
