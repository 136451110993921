import { useCallback } from "react";
import { useNavigate } from "react-router-dom";

const EspaceClient1 = () => {
  const navigate = useNavigate();

  const onListMenuContainerClick = useCallback(() => {
    navigate("/espace-client3");
  }, [navigate]);

  const onIcroundArrowBackIcon1Click = useCallback(() => {
    navigate("/espace-client2");
  }, [navigate]);

  return (
    <div className="w-full relative bg-white overflow-hidden flex flex-row items-start justify-start gap-[31px] tracking-[normal] text-left text-7xl text-black font-poppins mq800:gap-[15px] mq1300:flex-wrap">
      <div className="h-[1198px] w-[329px] bg-ghostwhite-100 flex flex-col items-center justify-start pt-9 pb-[106px] pr-[51px] pl-7 box-border gap-[36px] max-w-full mq450:gap-[18px] mq450:pr-5 mq450:box-border mq800:pt-5 mq800:pb-[45px] mq800:box-border mq1125:pt-[23px] mq1125:pb-[69px] mq1125:box-border">
        <div className="w-[329px] h-[1198px] relative bg-ghostwhite-100 hidden max-w-full" />
        <div className="h-[67px] flex flex-row items-start justify-start py-0 pr-5 pl-[38px] box-border">
          <h2 className="m-0 h-[39px] relative text-inherit tracking-[0.01em] font-semibold font-inherit inline-block z-[1] mq450:text-2xl">
            Espace client
          </h2>
        </div>
        <div className="self-stretch rounded-lg bg-main-color flex flex-row items-start justify-start py-[11px] px-2.5 gap-[14px] z-[1]">
          <div className="h-[46px] w-[250px] relative rounded-lg bg-main-color hidden" />
          <img
            className="h-6 w-6 relative overflow-hidden shrink-0 min-h-[24px] z-[1]"
            alt=""
            src="/icon--24--outline--keysquare.svg"
          />
          <input
            className="w-[calc(100%_-_44px)] [border:none] [outline:none] font-medium font-poppins text-sm bg-[transparent] h-[21px] flex-1 relative tracking-[-0.01em] text-white text-left inline-block min-w-[115px] z-[1]"
            placeholder="Historique des commandes"
            type="text"
          />
        </div>
        <div className="self-stretch flex-1 flex flex-col items-start justify-start py-0 pr-[9px] pl-2 gap-[26px] text-sm text-lightslategray-200">
          <div
            role={"presentation"}
            className="flex flex-row items-end justify-start py-0 px-0 gap-[14px] cursor-pointer z-[1]"
            onClick={onListMenuContainerClick}
          >
            <img
              className="h-6 w-6 relative overflow-hidden shrink-0 min-h-[24px]"
              loading="eager"
              alt=""
              src="/walletmoney-2.svg"
            />
            <div className="flex flex-row items-center justify-start gap-[29px]">
              <div className="h-[21px] relative tracking-[-0.01em] font-medium inline-block">
                Sercice commerciale
              </div>
              <img
                className="h-4 w-4 relative overflow-hidden shrink-0"
                loading="eager"
                alt=""
                src="/chevronright-2.svg"
              />
            </div>
          </div>
          <div className="self-stretch flex flex-row items-end justify-start gap-[14px]">
            <img
              className="h-6 w-6 relative overflow-hidden shrink-0 z-[1]"
              loading="eager"
              alt=""
              src="/usersquare-1.svg"
            />
            <div className="flex-1 flex flex-col items-start justify-start">
              <div className="relative tracking-[-0.01em] font-medium z-[1]">
                Paramètre
              </div>
            </div>
            <div className="flex flex-col items-start justify-start pt-0 pb-1 pr-px pl-0">
              <div className="w-[17px] h-[30px] relative">
                <img
                  className="absolute top-[14px] left-[-0.2px] w-4 h-4 overflow-hidden z-[1]"
                  alt=""
                  src="/chevronright-2-1.svg"
                />
                <input
                  className="m-0 absolute top-[0px] left-[1px] w-4 h-4 z-[2]"
                  type="checkbox"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="self-stretch flex flex-row items-start justify-start py-0 px-2.5 text-base text-main-text-color font-inter">
          <div className="flex flex-row items-center justify-start gap-[16px]">
            <img
              className="h-6 w-6 relative overflow-hidden shrink-0 z-[1]"
              loading="eager"
              alt=""
              src="/iconslogout.svg"
            />
            <div className="relative font-medium z-[1]">Déconnexion</div>
          </div>
        </div>
      </div>
      <div className="h-12 w-1 relative bg-gainsboro-100 hidden" />
      <section className="h-[1057px] w-[1003px] flex flex-col items-start justify-start pt-[87px] px-0 pb-0 box-border max-w-full mq800:pt-[37px] mq800:box-border mq1125:pt-[57px] mq1125:box-border">
        <div className="self-stretch flex-1 flex flex-col items-center justify-end pt-[55px] pb-0 pr-0 pl-[17px] box-border relative max-w-full mq800:pt-[23px] mq800:box-border mq1125:pt-9 mq1125:box-border">
          <div className="self-stretch flex-1 rounded-21xl flex flex-col items-center justify-start py-[74px] px-5 box-border relative bg-[url('/public/rectangle-1@2x.png')] bg-cover bg-no-repeat bg-[top] max-w-full mq800:pt-12 mq800:pb-12 mq800:box-border">
            <img
              className="w-[986px] relative rounded-21xl max-h-full object-cover hidden max-w-full z-[0]"
              alt=""
              src="/rectangle-1@2x.png"
            />
            <img
              className="w-[86.5px] h-[88px] absolute my-0 mx-[!important] bottom-[379.5px] left-[322.7px] object-contain z-[1]"
              loading="eager"
              alt=""
              src="/group-22@2x.png"
            />
            <img
              className="w-6 h-6 absolute my-0 mx-[!important] bottom-[120px] left-[441px] overflow-hidden shrink-0 z-[1]"
              loading="eager"
              alt=""
              src="/icroundarrowback1.svg"
            />
            <img
              className="w-[355px] h-[355px] relative max-w-full z-[2]"
              loading="eager"
              alt=""
              src="/group-37196.svg"
            />
          </div>
          <img
            role={"presentation"}
            className="w-[34px] h-[34px] absolute my-0 mx-[!important] top-[0px] left-[0px] overflow-hidden shrink-0 cursor-pointer"
            loading="eager"
            alt=""
            src="/icroundarrowback.svg"
            onClick={onIcroundArrowBackIcon1Click}
          />
        </div>
      </section>
    </div>
  );
};

export default EspaceClient1;
