import axios from "axios";

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_SERVER_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

axiosInstance.interceptors.request.use(
  (config) => {
    config.headers.Authorization = `Bearer ${process.env.REACT_APP_PUBLIC_TOKEN}`;

    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

export async function getBlogBySlug(slug) {
  const response = await axiosInstance.get(`/blogs/public/${slug}`);

  return response.data;
}

export async function getBlogs({
  page = 1,
  limit = 10,
  category = "",
  excludeCategories = [],
}) {
  let params = { page, limit };
  if (category.length > 0) params["category"] = category;
  if (excludeCategories.length > 0)
    params["excludeCategories"] = excludeCategories;
  const response = await axiosInstance.get(`/blogs/public`, { params: params });

  return response.data;
}

export async function getJobsFilters() {
  const response = await axiosInstance.get(`/jobs/filters/public`);

  return response.data;
}

export async function getJobs({
  page = 1,
  limit = 10,
  search = "",
  proximity = [],
  contractTypes = [],
  jobTitles = [],
  startDate = "",
  endDate = "",
}) {
  let params = { page, limit };
  if (search.length > 0) params["search"] = search;
  if (startDate.length > 0) params["startDate"] = startDate;
  if (endDate.length > 0) params["endDate"] = endDate;
  if (proximity.length > 0) params["proximity"] = proximity;
  if (contractTypes.length > 0) params["contractTypes"] = contractTypes;
  if (jobTitles.length > 0) params["jobTitles"] = jobTitles;

  const response = await axiosInstance.get(`/jobs/public`, { params: params });

  return response.data;
}

export async function registerClient({ payload = {} }) {
  const response = await axiosInstance.post(`/auth/public/register`, payload);

  return response.data;
}

export async function loginClient({ payload = {} }) {
  const response = await axiosInstance.post(`/auth/public/login`, payload);

  return response.data;
}
