import {
  differenceInDays,
  differenceInMonths,
  differenceInWeeks,
  differenceInYears,
} from "date-fns";

export function getTimeDifference(date) {
  const now = new Date();
  const days = differenceInDays(now, date);
  const weeks = differenceInWeeks(now, date);
  const months = differenceInMonths(now, date);
  const years = differenceInYears(now, date);

  if (days < 7) {
    return `il y a ${days} jour${days !== 1 ? "s" : ""}`;
  } else if (weeks < 4) {
    return `il y a ${weeks} semaine${weeks !== 1 ? "s" : ""}`;
  } else if (months < 12) {
    return `il y a ${months} mois`;
  } else {
    return `il y a ${years} an${years !== 1 ? "s" : ""}`;
  }
}

export function isLessThanTwoWeeks(date) {
  const now = new Date();

  const weeks = differenceInWeeks(now, date);

  return weeks <= 2;
}

export function getTimeFilters() {
  return [
    {
      id: "date-0",
      label: "Tout",
      value: new Date(Date.now()).toISOString(),
    },
    {
      id: "date-1",
      label: "Moins de 24 heures",
      value: new Date(Date.now() - 24 * 60 * 60 * 1000).toISOString(),
    },
    {
      id: "date-2",
      label: "Moins d'une semaine",
      value: new Date(Date.now() - 7 * 24 * 60 * 60 * 1000).toISOString(),
    },
    {
      id: "date-3",
      label: "Moins de 1 mois",
      value: new Date(Date.now() - 30 * 24 * 60 * 60 * 1000).toISOString(),
    },
    {
      id: "date-4",
      label: "Moins d'un an",
      value: new Date(Date.now() - 365 * 24 * 60 * 60 * 1000).toISOString(),
    },
  ];
}
