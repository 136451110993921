import ProductFrame from "../components/home/ProductFrame";
import FrameComponent from "../components/home/FrameComponent";
import AchievementContent from "../components/home/AchievementContent";
import PartnersComponent from "../components/home/PartnersComponent";
import ConstructionContent from "../components/home/ConstructionContent";
import Footer from "../components/Footer";
import SliderComponent from "../components/home/SliderComponent";

const HomePage = () => {
  return (
    <div className="w-screen  bg-white  flex flex-col overflow-hidden">
      <SliderComponent />
      <ProductFrame />
      <FrameComponent />
      <AchievementContent />
      <PartnersComponent />
      <ConstructionContent />
      <Footer />
    </div>
  );
};

export default HomePage;
