import { Fragment } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { IoIosArrowForward } from "react-icons/io";
import clsx from "clsx";
import { Popover, Transition } from "@headlessui/react";
import { AiOutlineMenu } from "react-icons/ai";

const links = [
  {
    name: "Straca",
    href: "/straca",
    icon: null,
    subLinks: [],
  },
  {
    name: "Expertises & Services",
    href: "/expeertise",
    icon: <IoIosArrowForward className="text-white h-5 w-5" />,
    subLinks: [],
  },
  {
    name: "Cultures",
    href: "/culture/actualite",
    icon: null,
    subLinks: [
      "/culture/savoir-faire",
      "/culture/certification",
      "/culture/engagement",
    ],
  },
  {
    name: "Partenaires",
    href: "/partenaires",
    icon: null,
    subLinks: [],
  },
];

function DesktopNavigation({
  textColor,
  buttonColor,
  btnTextColor,
  decorationColor,
}) {
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;

  return (
    <div className="hidden md:flex flex-row items-center space-x-32 py-5 px-0 box-border max-w-full">
      <div
        className={`flex flex-row gap-5 items-start justify-start max-w-full`}
      >
        {links.map((link, index) => {
          return (
            <Link
              key={index}
              className={clsx(
                link.href === pathname || link.subLinks.includes(pathname)
                  ? "underline"
                  : "no-underline",
                decorationColor,
                `flex flex-row items-center justify-center  hover:underline underline-offset-8 gap-2`,
              )}
              to={link.href}
            >
              <span className={clsx(textColor, `relative font-bold`)}>
                {link.name}
              </span>
              {link.icon != null && link.icon}
            </Link>
          );
        })}
      </div>

      <button
        className={clsx(
          buttonColor,
          btnTextColor,
          `w-36 p-2 hover:bg-opacity-90 rounded-md cursor-pointer `,
        )}
        onClick={() => {
          navigate("/client/login");
        }}
      >
        <span>Nous contacter</span>
      </button>
    </div>
  );
}

function MobileNavigation({ textColor, buttonColor, btnTextColor }) {
  const navigate = useNavigate();

  return (
    <Popover className="flex md:hidden">
      <Popover.Button
        className="relative flex h-8 w-8 items-center justify-center ui-not-focus-visible:outline-none outline-0"
        aria-label="Toggle Navigation"
      >
        <AiOutlineMenu className={clsx(textColor, ` w-8 h-8`)} />
      </Popover.Button>
      <Transition.Root>
        <Transition.Child
          as={Fragment}
          enter="duration-150 ease-out"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="duration-150 ease-in"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Popover.Overlay className="fixed inset-0 bg-slate-300/50" />
        </Transition.Child>
        <Transition.Child
          as={Fragment}
          enter="duration-150 ease-out"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="duration-100 ease-in"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <Popover.Panel
            as="div"
            className="z-[1] absolute inset-x-0 left-[20%] right-10 top-14 flex origin-top flex-col items-start gap-2 rounded-2xl bg-white p-4 text-lg tracking-tight text-slate-900 shadow-xl ring-1 ring-slate-900/5"
          >
            {links.map((link) => {
              return (
                <Link
                  key={link.name}
                  className="text-black hover:text-main-text-color text-sm"
                  to={link.href}
                >
                  {link.name}
                </Link>
              );
            })}
            <button
              className={clsx(
                buttonColor,
                btnTextColor,
                `shadow-md text-sm w-36 p-2  hover:bg-opacity-90  rounded-md cursor-pointer`,
              )}
              onClick={() => {
                navigate("/espace-client");
              }}
            >
              <span>Nous contacter</span>
            </button>
          </Popover.Panel>
        </Transition.Child>
      </Transition.Root>
    </Popover>
  );
}

export const Header = ({
  textColor = "text-white",
  buttonColor = "bg-white",
  btnTextColor = "text-main-color",
  decorationColor = "decoration-white",
  logo = "",
}) => {
  return (
    <div className="flex flex-row items-center justify-between w-full">
      <Link to={"/"} className="flex flex-row items-center">
        <img
          className="h-auto w-40 md:w-64 relative"
          loading="eager"
          alt="logo-img"
          src={logo !== "" ? logo : "/straca_benin/straca_benin2.svg"}
        />
      </Link>

      <DesktopNavigation
        textColor={textColor}
        buttonColor={buttonColor}
        btnTextColor={btnTextColor}
        decorationColor={decorationColor}
      />

      <MobileNavigation
        textColor={textColor}
        buttonColor={buttonColor}
        btnTextColor={btnTextColor}
      />
    </div>
  );
};
