import React from "react";
import BoxesLocalIcon from "../icons/Boxes";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";
import { FiSettings } from "react-icons/fi";

const PromisesComponent = () => {
  return (
    <section className="flex flex-col font-urbanist p-16">
      <div className="flex flex-col items-center text-center gap-5  ">
        <h3 className="text-4xl font-medium">Promesses et mission</h3>
        <p className="text-base max-w-5xl">
          <span className="font-semibold">
            {"Concevoir, construire, entretenir et fournir "}{" "}
          </span>
          {
            "des équipements de haute qualité au service du transport routier, ainsi que des ressources minières et pétrolières au Bénin sont les objectifs majeurs de la "
          }
          <span className="font-semibold">{"STRACA BENIN SA."}</span>
        </p>
      </div>
      <div className="mt-16 flex flex-col md:flex-row w-fit self-center bg-white shadow-lg rounded-3xl py-8 px-14 gap-20">
        <div className="flex flex-row items-center gap-4">
          <div className="bg-main-orange p-2 rounded-full">
            <BoxesLocalIcon className="h-8 w-8" />
          </div>
          <span className="text-main-orange text-3xl">Innovation</span>
        </div>
        <div className="flex flex-row items-center gap-4">
          <div className="bg-main-orange p-2 rounded-full">
            <IoMdCheckmarkCircleOutline className="h-8 w-8 text-white" />
          </div>
          <span className="text-main-orange text-3xl">Intégrité</span>
        </div>
        <div className="flex flex-row items-center gap-4">
          <div className="bg-main-orange p-2 rounded-full">
            <FiSettings className="h-8 w-8 text-white" />
          </div>
          <span className="text-main-orange text-3xl">Performance</span>
        </div>
      </div>
    </section>
  );
};

export default PromisesComponent;
