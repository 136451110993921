import React from "react";
import MySlick from "../MySlick";

const TransportComponent = () => {
  return (
    <section className="flex flex-col py-10 md:py-20 font-urbanist">
      <div className="flex flex-col px-14 md:px-28 items-start max-w-2xl">
        <span className="text-4xl font-medium">Transport & Logistique</span>
        <p className="mt-14">
          {
            "STRACA Bénin SA offre à ses clients le service de transport de biens et de personnes. A cet effet STRACA Bénin qui opère principalement dans l’industrie du BTP transporte pour ses clients"
          }
        </p>
      </div>
      <div className=" w-full mt-10">
        <MySlick />
      </div>
      <div className="bg-ghostwhite-200">
        <div className="flex flex-row my-16 px-8 items-center justify-center gap-32">
          <p className="max-w-md">
            {
              "Lörem ipsum jovis dåvur autojovis för att er men antir tills famibelt nygisk utan pomens. Soren dyskemi trevis triponyment av faling trevis. Lörem ipsum jovis dåvur autojovis för att er men antir tills famibelt nygisk utan pomens. S"
            }
          </p>
          <p className="max-w-md">
            {
              "Lörem ipsum jovis dåvur autojovis för att er men antir tills famibelt nygisk utan pomens. Soren dyskemi trevis triponyment av faling trevis. Lörem ipsum jovis dåvur autojovis för att er men antir tills famibelt nygisk utan pomens. S"
            }
          </p>
        </div>
        <div className="flex justify-center my-20">
          <button className="py-3 px-7 bg-main-color text-white rounded-xl">
            En savoir plus
          </button>
        </div>
      </div>
    </section>
  );
};

export default TransportComponent;
