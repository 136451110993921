import React, { useCallback, useEffect } from "react";
import { MapContainer, Marker, Popup } from "react-leaflet";
import { useLeafletContext } from "@react-leaflet/core";
import { divIcon } from "leaflet";

import "leaflet/dist/leaflet.css";
import { MaptilerLayer } from "@maptiler/leaflet-maptilersdk";
import { renderToStaticMarkup } from "react-dom/server";
import { Link } from "react-router-dom";

function Tile() {
  const context = useLeafletContext();

  useEffect(() => {
    const mtLayer = new MaptilerLayer({
      // Get your free API key at https://cloud.maptiler.com
      apiKey: "lIcWXxpXPocVC7LPGev8",
      style: "30b3fbd3-12b2-45c8-a56a-04a1da0723ea",
    });

    const container = context.layerContainer || context.map;
    container.addLayer(mtLayer);

    return () => {
      container.removeLayer(mtLayer);
    };
  }, [context.layerContainer, context.map]);

  return null;
}

const TestMap = ({ center = [0, 20], zoom = 3.9 }) => {
  const data = [
    {
      title: "Bénin",
      description: "Ici vos avez la description du chantier en question etc...",
      to: "/project/benin",
      icon: "/component-15@2x.png",
      position: [11.5, 2.3],
    },
    {
      title: "Togo",
      description: "Ici vos avez la description du chantier en question etc...",
      to: "/project/togo",
      icon: "/component-15@2x.png",
      position: [14, -1.2],
    },
    {
      title: "Burkina - Faso",
      description: "Ici vos avez la description du chantier en question etc...",
      to: "/project/burkina-faso",
      icon: "/component-15@2x.png",
      position: [8.5, -5.5],
    },
  ];

  const generateIcon = useCallback((icon) => {
    const iconMarkup = renderToStaticMarkup(
      <img
        className="h-6 w-6 md:h-7 md:w-7 hover:scale-125 -translate-x-[3px] -translate-y-[3.5px] md:-translate-x-1 md:-translate-y-1"
        loading="eager"
        alt=""
        src={icon}
      />,
    );
    return divIcon({
      html: iconMarkup,
    });
  }, []);

  return (
    <MapContainer
      key="Map"
      className="h-full w-full absolute"
      center={center}
      zoom={zoom}
      doubleClickZoom={false}
      closePopupOnClick={true}
      dragging={false}
      zoomSnap={false}
      zoomDelta={false}
      trackResize={false}
      touchZoom={false}
      scrollWheelZoom={false}
      zoomControl={false}
      boxZoom={false}
    >
      <Tile />
      {/*        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />*/}

      {data.map((country) => (
        <Marker
          key={country.title}
          icon={generateIcon(country.icon)}
          position={country.position}
        >
          <Popup maxWidth={1000} className={"z-[4] w-56 md:w-72"}>
            <div className="flex flex-col items-center text-center">
              <h3 className="text-main-color text-lg md:text-2xl">
                {country.title}
              </h3>
              <p className=" text-xs md:text-base ">{country.description}</p>
              <Link
                to={country.to}
                className=" text-main-color text-sm md:text-lg self-end underline underline-offset-8"
              >
                Voir plus
              </Link>
            </div>
          </Popup>
        </Marker>
      ))}
    </MapContainer>
  );
};

export default TestMap;
