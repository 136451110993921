import Footer from "../components/Footer";
import { Header } from "../components/Header";
import clsx from "clsx";
import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { getBlogBySlug } from "../api";
import { useEffect, useState } from "react";

import "quill/dist/quill.core.css";
import "../quillContent.css";

const Projets = () => {
  let { slug } = useParams();

  const { isPending, error, data, isSuccess } = useQuery({
    queryKey: ["getCountryBlog"],
    queryFn: () => getBlogBySlug(slug),
  });

  const [blog, setBlog] = useState(null);

  useEffect(() => {
    if (isSuccess) {
      //console.log(data);
      setBlog(data.blog);
    }
  }, [data, isSuccess]);

  return (
    <div className="w-screen  bg-white  flex flex-col overflow-hidden font-urbanist">
      <div className="w-full flex flex-col">
        <div className="relative h-[70vh] w-full bg-[#59AAD6] flex flex-col px-7 md:px-20 py-5">
          <Header />

          <div className="absolute inset-0 top-44 mx-auto grid grid-cols-1 md:grid-cols-2 gap-5 h-[35vh] md:h-[70vh] w-[80vw] ">
            <div className={clsx("h-full w-full", "", "")}>
              <img
                className={clsx("h-full w-full", "my-auto")}
                loading="eager"
                alt=""
                src="/bridge-1.png"
              />
            </div>
            <div className="grid grid-cols-2  gap-5 ">
              <div className={clsx("h-[17.5vh] md:h-[35vh] w-full")}>
                <img
                  className={clsx("h-full w-full", "my-auto")}
                  loading="eager"
                  alt=""
                  src="/bridge-2.png"
                />
              </div>
              <div className={clsx("h-[17.5vh] md:h-[35vh] w-full")}>
                <img
                  className={clsx("h-full w-full", "my-auto")}
                  loading="eager"
                  alt=""
                  src="/bridge-3.png"
                />
              </div>
              <div className={clsx("h-[17.5vh] md:h-[35vh] w-full")}>
                <img
                  className={clsx(" h-full w-full object-cover", "my-auto")}
                  loading="eager"
                  alt=""
                  src="/bridge-4.png"
                />
              </div>
              <div className={clsx("h-[17.5vh] md:h-[35vh] w-full")}>
                <img
                  className={clsx("h-full w-full", "my-auto")}
                  loading="eager"
                  alt=""
                  src="/bridge-5.png"
                />
              </div>
            </div>
          </div>
        </div>
        {isPending || error !== null || blog === null ? (
          <div className="h-[50vh] w-full bg-white" />
        ) : (
          <div className="min-h-[50vh] w-full bg-white px-7 md:px-48 mt-72 mb-32 flex flex-col gap-20">
            <h3 className="text-4xl font-medium">{blog?.title ?? "-"}</h3>

            <div className="ql-container ql-snow">
              <div
                className="ql-editor"
                dangerouslySetInnerHTML={{ __html: blog?.blogBody ?? "" }}
              />
            </div>
          </div>
        )}
      </div>
      <Footer />
    </div>

    /*    <div className="w-full relative bg-white overflow-hidden flex flex-col items-center justify-start pt-0 px-0 pb-px box-border gap-[139px] tracking-[normal] mq750:gap-[69px] mq450:gap-[35px]">
      <section className="self-stretch flex flex-row items-start justify-start pt-0 px-0 pb-[177px] box-border max-w-full mq450:pb-[75px] mq450:box-border mq1050:pb-[115px] mq1050:box-border">
        <div className="flex-1 flex flex-row items-start justify-start relative max-w-full">
          <div className="w-[1344px] my-0 mx-[!important] absolute bottom-[-234px] left-[calc(50%_-_672px)] flex flex-col items-start justify-start max-w-full z-[1]">
            <div className="self-stretch flex flex-row flex-wrap items-start justify-start gap-[20px] max-w-full">
              <div className="flex-1 overflow-hidden flex flex-row items-start justify-start bg-[url('/public/column@3x.png')] bg-cover bg-no-repeat bg-[top] min-w-[430px] max-w-full mq750:min-w-full">
                <div className="h-[662px] flex-1 overflow-hidden flex flex-col items-start justify-start bg-[url('/public/bridge-1.png')] bg-cover bg-no-repeat bg-[top] max-w-full">
                  <div className="self-stretch flex-1 overflow-hidden flex flex-col items-start justify-start">
                    <img
                      className="self-stretch flex-1 max-w-full overflow-hidden max-h-full object-contain"
                      loading="eager"
                      alt=""
                      src="/aspect-ratio-keeper--additionally-45-rotated-auto-layout@2x.png"
                    />
                  </div>
                </div>
              </div>
              <div className="flex-1 overflow-hidden flex flex-col items-start justify-start gap-[20px] min-w-[430px] max-w-full mq750:min-w-full">
                <div className="self-stretch flex flex-row items-start justify-start gap-[20px] max-w-full mq750:flex-wrap">
                  <div className="flex-1 overflow-hidden flex flex-col items-start justify-start bg-[url('/public/bridge-2.png')] bg-cover bg-no-repeat bg-[top] min-w-[209px] max-w-full">
                    <div className="self-stretch h-[321px] overflow-hidden shrink-0 flex flex-col items-start justify-start">
                      <img
                        className="self-stretch flex-1 max-w-full overflow-hidden max-h-full object-contain"
                        alt=""
                        src="/aspect-ratio-keeper--additionally-45-rotated-auto-layout-1@2x.png"
                      />
                    </div>
                  </div>
                  <div className="flex-1 overflow-hidden flex flex-col items-start justify-start bg-[url('/public/bridge-3.png')] bg-cover bg-no-repeat bg-[top] min-w-[209px] max-w-full">
                    <div className="self-stretch h-[321px] overflow-hidden shrink-0 flex flex-col items-start justify-start">
                      <img
                        className="self-stretch flex-1 max-w-full overflow-hidden max-h-full object-contain"
                        alt=""
                        src="/aspect-ratio-keeper--additionally-45-rotated-auto-layout-1@2x.png"
                      />
                    </div>
                  </div>
                </div>
                <div className="self-stretch flex flex-row items-start justify-start gap-[20px] max-w-full mq750:flex-wrap">
                  <div className="flex-1 overflow-hidden flex flex-col items-start justify-start bg-[url('/public/bridge-4.png')] bg-cover bg-no-repeat bg-[top] min-w-[209px] max-w-full">
                    <div className="self-stretch h-[321px] overflow-hidden shrink-0 flex flex-col items-start justify-start">
                      <img
                        className="self-stretch flex-1 max-w-full overflow-hidden max-h-full object-contain"
                        alt=""
                        src="/aspect-ratio-keeper--additionally-45-rotated-auto-layout-1@2x.png"
                      />
                    </div>
                  </div>
                  <div className="flex-1 overflow-hidden flex flex-col items-start justify-start bg-[url('/public/bridge-5.png')] bg-cover bg-no-repeat bg-[top] min-w-[209px] max-w-full">
                    <div className="self-stretch h-[321px] overflow-hidden shrink-0 flex flex-col items-start justify-start">
                      <img
                        className="self-stretch flex-1 max-w-full overflow-hidden max-h-full object-contain"
                        alt=""
                        src="/aspect-ratio-keeper--additionally-45-rotated-auto-layout-1@2x.png"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex-1 bg-third-blue flex flex-row items-start justify-center pt-0 px-0 pb-[523px] box-border max-w-full mq750:pb-[340px] mq750:box-border">
            <div className="h-[608px] w-[1512px] relative bg-third-blue hidden max-w-full" />
            <header className="flex-1 bg-third-blue overflow-hidden flex flex-row items-center justify-center py-[15px] px-5 box-border gap-[178px] top-[0] z-[99] sticky max-w-full text-left text-base text-white font-urbanist lg:gap-[89px] mq750:gap-[44px] mq450:gap-[22px]">
              <div className="h-[49px] w-[264px] flex flex-col items-start justify-start">
                <img
                  className="w-[122px] flex-1 relative max-h-full"
                  loading="eager"
                  alt=""
                  src="/logo2.svg"
                />
              </div>
              <div className="flex flex-row items-start justify-start gap-[24px] max-w-full mq1050:hidden">
                <div className="relative font-medium">Straca</div>
                <div className="flex flex-row items-start justify-start gap-[10px]">
                  <div className="relative font-medium whitespace-nowrap">
                    Nos services
                  </div>
                  <img
                    className="h-[18px] w-[18px] relative"
                    loading="eager"
                    alt=""
                    src="/iconly2.svg"
                  />
                </div>
                <div className="relative font-medium">Expertises</div>
                <div className="relative font-medium">Culture</div>
                <div className="relative font-medium">Partenaires</div>
              </div>
              <div className="w-[228px] flex flex-row items-center justify-start gap-[28px]">
                <button className="cursor-pointer [border:none] p-2.5 bg-main-color flex-1 rounded-md flex flex-row items-center justify-center hover:bg-steelblue-100">
                  <div className="flex-1 relative text-base font-medium font-urbanist text-white text-center">
                    Collaborons
                  </div>
                </button>
                <img
                  className="h-[55px] w-[55px] relative"
                  loading="eager"
                  alt=""
                  src="/group-3247.svg"
                />
              </div>
            </header>
          </div>
        </div>
      </section>
      <section className="w-[1120px] flex flex-row items-start justify-start py-0 px-5 box-border max-w-full shrink-0 text-left text-21xl text-black font-urbanist">
        <div className="w-[1020px] flex flex-col items-start justify-start gap-[33px] max-w-full mq750:gap-[16px]">
          <h1 className="m-0 relative text-inherit font-medium font-inherit inline-block max-w-full mq450:text-5xl mq1050:text-13xl">
            Constuctions d’un pont
          </h1>
          <div className="self-stretch h-[196px] relative text-base flex items-center shrink-0">
            <span>
              <p className="m-0">{`Lörem ipsum mädos krohet. Popp jasminrevolution eller jeröt kovis vikol stenovöktig inte änegen. `}</p>
              <p className="m-0">{`Åd intragisk. Gära nyv ålig trinat des divöde diling plus fanade. `}</p>
              <p className="m-0">{`Aktiga diagt diligt eller ögärortad. Renade treskap reskap till egont. Du kan vara drabbad. `}</p>
              <p className="m-0">{`Astror megangen dir hövis makrovis. Hösesamma intrar och stereosiv. `}</p>
              <p className="m-0">{`Såde nynde suheng, ogt anaspende pånde spårtjuv. Decipor skynka för att readeplarar homorat: monoligt spev dining sedan paraspehörade att preling. `}</p>
              <p className="m-0">{`Rogär treras gäkev jed trepans vinera utom navis plande. Pongen sorovis ambitotal kontrajende. Du kan vara drabbad. `}</p>
            </span>
          </div>
        </div>
      </section>
      <Footer />
    </div>*/
  );
};

export default Projets;
