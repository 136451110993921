export default function CityLocalIcon({ props, className, color = "white" }) {
  return (
    <svg
      width="58"
      height="46"
      viewBox="0 0 58 46"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      className={className}
    >
      <path
        d="M2.8335 45H55.2144"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M33.2142 45V2.91304C33.2142 2.40567 33.0092 1.91908 32.6443 1.56032C32.2795 1.20155 31.7846 1 31.2686 1H7.92165C7.40565 1 6.91079 1.20155 6.54592 1.56032C6.18105 1.91908 5.97607 2.40567 5.97607 2.91304V45"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M33.2144 13.5713H49.0715C50.7283 13.5713 52.0715 14.9144 52.0715 16.5713V43.9522H33.2144V13.5713Z"
        fill="#59AAD6"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.3569 10.4287H21.6903"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.78564 40.8096L1.78564 26.1429"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M56.2617 30.333L56.2617 15.6663"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M36.3569 9.38086L43.6903 9.38086"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.5 26.1426H24.8333"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.3569 35.5713H21.6903"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M40.5474 35.5713H44.7378"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M40.5474 26.1426H44.7378"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
