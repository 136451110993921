import { PiSealCheckFill } from "react-icons/pi";

const Engagement = () => {
  const data = [
    {
      title: "Utilisation de véhicules éco-responsables",
      elems: [
        "Nous investissons dans une flotte de véhicules plus propres, tels que des véhicules hybrides, électriques ou à faibles émissions.",
        "Nous adoptons des pratiques de conduite écoénergétiques pour optimiser la consommation de carburant.",
      ],
    },
    {
      title: "Optimisation des itinéraires et de la planification",
      elems: [
        "Utiliser des systèmes de gestion de flotte et de routage pour minimiser les distances parcourues, réduire les émissions et optimiser l'utilisation des véhicules.",
      ],
    },
    {
      title: "Consolidation des cargaisons",
      elems: [
        "Favoriser le regroupement des marchandises pour réduire le nombre de trajets nécessaires et maximiser la capacité des véhicules, ce qui réduit les émissions de gaz à effet de serre par unité de marchandise transportée.",
      ],
    },
    {
      title: "Gestion responsable des déchets",
      elems: [
        "Mettre en place des programmes de recyclage des déchets dans les installations de l'entreprise.",
        "Réduire les déchets générés en encourageant la réutilisation des matériaux d'emballage et en minimisant l'utilisation de plastique à usage unique.",
      ],
    },
  ];

  return (
    <div className="w-full flex flex-col font-urbanist">
      <h3 className="font-semibold text-4xl flex px-8 md:px-32 ">Engagement</h3>

      <div className="flex flex-col md:flex-row gap-10 md:gap-0 items-center md: justify-between px-8 md:px-32 py-28">
        <div className="flex flex-col gap-5 max-w-md">
          <h3 className="text-3xl">Compensation Carbone</h3>
          <p className="text-lg">
            {
              "Pour les émissions de CO2 que nous ne pouvons pas encore éliminer complètement, nous compensons notre empreinte carbone en investissant dans des projets de compensation carbone certifiés. Ces projets soutiennent des initiatives telles que la reforestation, les énergies renouvelables et l'efficacité énergétique, contribuant ainsi à la neutralité carbone globale."
            }
          </p>
        </div>

        <div className="relative h-[50vh] w-[50vh] md:h-[60vh] md:w-[60vh] ">
          <img
            className="absolute m-auto left-0 right-0 top-0 bottom-0 rounded-xl h-[30vh] w-[30vh] md:w-[40vh] md:h-[40vh] object-cover"
            alt=""
            src="/ellipse@2x.png"
          />
          <img
            className="absolute  rounded-xl w-[15vh] h-[15vh] md:w-[25vh] md:h-[25vh] object-cover z-[1]"
            alt=""
            src="/frame@2x.png"
          />
          <img
            className="absolute bottom-0 right-0 rounded-xl w-[15vh] h-[15vh] md:w-[25vh] md:h-[25vh] object-cover z-[1]"
            loading="eager"
            alt=""
            src="/rectangle-18136@2x.png"
          />
        </div>
      </div>

      <div className="bg-gray-100 flex flex-col md:flex-row gap-10 md:gap-0 items-center md: justify-between px-8 md:px-32 py-24">
        <div className="relative h-[50vh] w-[50vh] md:h-[60vh] md:w-[60vh]  ">
          <img
            className="absolute m-auto left-0 right-0 top-0 bottom-0 rounded-xl h-[30vh] w-[30vh] md:w-[40vh] md:h-[40vh] object-cover"
            alt=""
            src="/rectangle-18134-1@2x.png"
          />
          <img
            className="absolute  rounded-xl w-[15vh] h-[15vh] md:w-[25vh] md:h-[25vh] object-cover z-[1]"
            alt=""
            src="/rectangle-18137-1@2x.png"
          />
          <img
            className="absolute bottom-0 right-0 rounded-xl w-[15vh] h-[15vh] md:w-[25vh] md:h-[25vh] object-cover z-[1]"
            loading="eager"
            alt=""
            src="/rectangle-18136-1@2x.png"
          />
        </div>

        <div className="flex flex-col gap-5 max-w-md">
          <h3 className="text-3xl">Compensation Carbone</h3>
          <p className="text-lg">
            {
              "Pour les émissions de CO2 que nous ne pouvons pas encore éliminer complètement, nous compensons notre empreinte carbone en investissant dans des projets de compensation carbone certifiés. Ces projets soutiennent des initiatives telles que la reforestation, les énergies renouvelables et l'efficacité énergétique, contribuant ainsi à la neutralité carbone globale."
            }
          </p>
        </div>
      </div>

      <div className="flex flex-col items-center justify-center px-5 md:px-0 py-24">
        {data.map((elem, index) => (
          <div
            key={`eco-verified-${index}`}
            className="flex flex-col md:flex-row items-center gap-5 shadow-xl shadow-[#E6883C]/15 p-5"
          >
            <PiSealCheckFill className="text-light-primary h-7 w-7" />
            <div className="flex flex-col">
              <h3 className="text-base md:text-lg font-semibold">
                {elem.title}
              </h3>
              <ul className="ml-3 list-disc list-inside text-sm md:text-base max-w-3xl">
                {elem.elems.map((subList, indexSubList) => (
                  <li key={`eco-verified-sublist-${indexSubList}`}>
                    {subList}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Engagement;
