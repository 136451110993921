import React from "react";
import { useNavigate } from "react-router-dom";
import { IoIosArrowForward } from "react-icons/io";

const NosChantiersFrame = () => {
  const navigate = useNavigate();

  return (
    <>
      <div className=" mt-10 mb-24 w-full flex flex-row items-start justify-start text-left text-2xl text-white font-urbanist">
        <div className="w-full flex flex-col items-start justify-start max-w-xl md:p-10">
          <h1 className="relative  font-semibold  leading-snug ">{`Straca Bénin, partenaire de 1er choix en transport & logistiques`}</h1>
          <div className="mt-10 relative text-sm">
            {
              "STRACA Bénin, c’est avant tout l’ambition panafricaine de devenir un leader de la fourniture d'agrégats, des équipements de BTP et du transport routiers. Forte d'une expérience solide et d'une équipe dévouée, nous nous engageons à offrir des services de qualité supérieure tout en respectant nos valeurs fondamentales."
            }
          </div>
          <div className="my-10 flex flex-row gap-5 items-start justify-start  w-full">
            <button
              className="cursor-pointer p-3 w-1/2 md:w-48  gap-2 bg-main-orange flex flex-row items-center justify-center rounded-xl"
              onClick={() => {
                navigate("/expeertise");
              }}
            >
              <div className="relative text-sm md:text-base font-medium font-inter text-white text-left">
                Nos services
              </div>
              <IoIosArrowForward className="text-white h-5 w-5" />
            </button>

            <button
              className="cursor-pointer p-3 w-1/2 md:w-48  gap-5 bg-white flex flex-row items-center justify-center rounded-xl"
              onClick={() => {
                navigate("/culture/actualite");
              }}
            >
              <div className="relative text-sm md:text-base font-medium font-inter text-main-color text-left">
                En savoir plus
              </div>
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default NosChantiersFrame;
