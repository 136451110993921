export default function TruckLocalIcon({ props, className, color = "white" }) {
  return (
    <svg
      width="63"
      height="62"
      viewBox="0 0 63 62"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      className={className}
    >
      <path
        d="M42.5713 28.7856V18.8213H48.1681C51.0026 18.8213 53.5574 20.5307 54.6387 23.1509L56.6791 28.0948C56.8149 28.424 56.573 28.7856 56.2169 28.7856H42.5713Z"
        fill="#59AAD6"
        stroke={color}
        strokeWidth="2"
      />
      <path
        d="M4.92871 35.4287H42.5716"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M45.8929 53.1431C49.5616 53.1431 52.5357 50.169 52.5357 46.5003C52.5357 42.8315 49.5616 39.8574 45.8929 39.8574C42.2241 39.8574 39.25 42.8315 39.25 46.5003C39.25 50.169 42.2241 53.1431 45.8929 53.1431Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.1072 53.1431C20.776 53.1431 23.7501 50.169 23.7501 46.5003C23.7501 42.8315 20.776 39.8574 17.1072 39.8574C13.4385 39.8574 10.4644 42.8315 10.4644 46.5003C10.4644 50.169 13.4385 53.1431 17.1072 53.1431Z"
        fill="#59AAD6"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M39.25 45.3926H23.75"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.4091 45.3926H6.81085C6.31168 45.3926 5.83295 45.1884 5.47998 44.8251C5.12701 44.4617 4.92871 43.9689 4.92871 43.4551V16.3301C4.92871 15.8162 5.12701 15.3234 5.47998 14.9601C5.83295 14.5967 6.31168 14.3926 6.81085 14.3926H42.5716V40.3551"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M42.5713 28.7861H58.0713V43.548C58.0713 44.0374 57.8672 44.5068 57.5038 44.8528C57.1405 45.1989 56.6476 45.3933 56.1338 45.3933H52.3972"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.35693 28.0945L9.35693 18.8213"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M29.2859 31L38.5591 31"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M29.2855 49.7148L32.6069 49.7148"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
