import { Header } from "../Header";
import NosChantiersFrame from "../NosChantiersFrame";
import { DotsIndicator } from "../DotsIndicator";
import PartnersFrameQuotidien from "./PartnersFrameQuotidien";
import { useCallback, useEffect, useState } from "react";

const SliderComponent = () => {
  const slides = [
    "/component-28@2x.png",
    "/rectangle-6@2x.png",
    "/rectangle-18160@2x.png",
    "/rectangle-1@2x.png",
  ];
  const [currentIndex, setCurrentIndex] = useState(0);

  const nextSlide = useCallback(() => {
    const isLastSlide = currentIndex === slides.length - 1;
    const newIndex = isLastSlide ? 0 : currentIndex + 1;
    setCurrentIndex(newIndex);
  }, [currentIndex, slides.length]);

  // Disable scrolling while popup is shown
  useEffect(() => {
    // Set an interval to call autoChangeSlide every 3 seconds
    const intervalId = setInterval(nextSlide, 3000);

    // Clean up the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, [currentIndex, nextSlide]);

  return (
    <section className="w-full max-w-full flex flex-col relative mb-[120vh] md:mb-56">
      <div
        className="relative w-full bg-cover duration-1000"
        style={{
          backgroundImage: `url(${slides[currentIndex]})`,
        }}
      >
        <div className="flex flex-col px-5 md:px-24 py-5 md:py-10">
          <Header />

          <NosChantiersFrame />
          <div className="absolute p-5 bottom-4 right-0">
            <DotsIndicator slides={slides} currentIndex={currentIndex} />
          </div>
        </div>
      </div>
      <PartnersFrameQuotidien />
    </section>
  );
};

export default SliderComponent;
